import React from 'react'
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai'
import { BiDollar } from 'react-icons/bi'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import {
  Container,
  IconArea,
  IconTitle,
  Info,
  Item,
  Left,
  Photo,
  Right,
  SubmitButton,
  SubTitle,
  Title
} from './style'

interface DataProps {
  entradas: number
  id: number
  img: string
  nome: string
  saidas: number
  tipo_img: string
}

export const CustomersWithReminderItem: React.FC<DataProps> = (user) => {
  const history = useHistory()
  const {
    setAdmInUserImg,
    setAdmInUserImgType,
    setAdmInUserName,
    setAdmInUser,
    setAdmInUserId
  } = useUser()

  return (
    <Container>
      <Left>
        <Photo src={`data:image/${user?.tipo_img};base64,${user?.img}`} alt="logo" />
        <Info>
          <Title>{user.nome}</Title>
          {/* <SubTitle>3 lembretes</SubTitle> */}
        </Info>
      </Left>
      <Right>
        {user.entradas > 0 && (
          <Item>
            <IconArea>
              <div>
                <BiDollar className="entry-icon" size={26} />
              </div>
              <div>
                <AiFillPlusCircle className="entry-icon" size={16} />
              </div>
            </IconArea>
            <IconTitle color="var(--primary)">{user.entradas} entradas</IconTitle>
          </Item>
        )}
        {user.saidas > 0 && (
          <Item>
            <IconArea>
              <div>
                <BiDollar className="out-icon" size={26} />
              </div>
              <div>
                <AiFillMinusCircle className="out-icon" size={16} />
              </div>
            </IconArea>
            <IconTitle color="#d7001a">{user.saidas} saídas</IconTitle>
          </Item>
        )}
        <SubmitButton>
          <MdOutlineKeyboardArrowRight
            onClick={() => {
              setAdmInUser(true)
              setAdmInUserId(String(user.id))
              setAdmInUserImg(user.img)
              setAdmInUserImgType(user.tipo_img)
              setAdmInUserName(user.nome)
              history.push('/adm-in-user-dashboard')
            }}
            size={26}
            style={{ fill: '#b5cbc6' }}
          />
        </SubmitButton>
      </Right>
    </Container>
  )
}

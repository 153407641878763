import styled from 'styled-components'

export const AlertOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AlertContainer = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  padding: 16px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  p {
    font-weight: bold;
    font-size: 18px;
  }
`

export const Button = styled.button`
  margin: 0 8px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  margin-top: 22px;
  border-radius: 6px;
  width: 100px;
  height: 32px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const ButtonYes = styled(Button)`
  background: var(--primary);
`

export const ButtonNo = styled(Button)`
  background: red;
`

import {
  Box,
  BoxTop,
  CloseButton,
  Container,
  Form,
  Grid,
  Input,
  InputItem,
  InputTitle,
  InputTitleArea,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  SubmitButtonArea,
  Title,
  Tooltip,
  Wrapper
} from './style'

import ReactTooltip from 'react-tooltip'

import { AiOutlineQuestionCircle } from 'react-icons/ai'

import { FormEvent, useState } from 'react'
import { useAlert } from 'react-alert'
import CurrencyFormat from 'react-currency-format'
import { MdClose } from 'react-icons/md'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'
import AdmInUserApi from '../../services/AdmInUserApi'

export const ModalProduct: React.FC = () => {
  const { closeProductModal, updatingList } = useModal()
  const { key, admInUser, admInUserId } = useUser()
  const alert = useAlert()

  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [sku, setSku] = useState('')
  const [obs, setObs] = useState('')

  const clean = () => {
    setName('')
    setPrice('')
    setSku('')
    setObs('')
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const data: App.NewProduct = {
      key,
      name,
      price,
      sku,
      obs
    }

    if (name !== '') {
      if (!admInUser) {
        IndividualApi.new_product(data)
          .then((res) => {
            alert.show(res.data.text)
            clean()
            closeProductModal()
            updatingList()
          })
          .catch((error) => alert.show(error.response?.data.error))
      } else {
        AdmInUserApi.new_product(data, admInUserId)
          .then((res) => {
            alert.show(res.data.text)
            clean()
            closeProductModal()
            updatingList()
          })
          .catch((error) => alert.show(error.response?.data.error))
      }
    } else {
      alert.show('Campos marcados com * são obrigatórios')
    }
  }

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeProductModal()
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ left: -530 }} animate={{ left: 0 }} exit={{ left: -530 }}>
        <BoxTop>
          <Title>
            Cadastre um <span>novo centro de custo</span>
          </Title>
          <CloseButton onClick={closeProductModal} type="button">
            <MdClose size={26} />
            Fechar
          </CloseButton>
        </BoxTop>
        <Wrapper>
          {/* <InputTitleArea>
            <InputTitle>Tipo do lançamento</InputTitle>
            <Tooltip data-tip="Tipo do lançamento">
              <AiOutlineQuestionCircle className="icon" size={16} />
              <ReactTooltip
                place="bottom"
                backgroundColor="#2D3B36"
                effect="solid"
              />
            </Tooltip>
          </InputTitleArea> */}
          <Form onSubmit={handleSubmit}>
            <Grid>
              <InputItem
                style={{
                  borderBottomColor: name.length > 0 ? 'var(--primary)' : '#DF3347'
                }}
              >
                <InputTitleArea>
                  <InputTitle>Nome do Centro de custo</InputTitle>
                  <span>*</span>
                </InputTitleArea>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Digitar..."
                />
              </InputItem>
              {/* <InputItem>
                <InputTitleArea>
                  <InputTitle>Preço</InputTitle>
                </InputTitleArea>
                <div>
                  <CurrencyFormat
                    thousandSeparator={true}
                    prefix={'R$ '}
                    onChange={(e: any) => setPrice(e.target.value)}
                    value={price}
                    placeholder="Digitar..."
                  />
                </div>
              </InputItem> */}
              {/* <InputItem>
                <InputTitleArea>
                  <InputTitle>SKU</InputTitle>
                </InputTitleArea>
                <div>
                  <Input
                    onChange={(e) => setSku(e.target.value)}
                    value={sku}
                    placeholder="Digitar..."
                  />
                </div>
              </InputItem> */}
              <InputItem>
                <InputTitleArea>
                  <InputTitle>Observações</InputTitle>
                </InputTitleArea>
                <div>
                  <Input
                    onChange={(e) => setObs(e.target.value)}
                    value={obs}
                    placeholder="Digitar..."
                  />
                </div>
              </InputItem>
            </Grid>
            <SubmitButtonArea>
              <SubmitButton type="submit">Finalizar Cadastro</SubmitButton>
            </SubmitButtonArea>
          </Form>
        </Wrapper>
      </Box>
    </Container>
  )
}

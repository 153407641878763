import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { Link, useHistory } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import AdmApi from '../../services/AdmApi'
import {
  Banner,
  Container,
  Info,
  InputItem,
  Left,
  Right,
  SubmitButton,
  SubTitle,
  Title,
  Wrapper
} from './style'

export const ADMSupport: React.FC = () => {
  const { key } = useUser()
  const alert = useAlert()
  const history = useHistory()

  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [texto, setTexto] = useState('')

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (nome !== '' && email !== '' && texto !== '') {
      const data: App.SendSupport = {
        key,
        nome,
        email,
        texto
      }

      AdmApi.send_support(data)
        .then((res) => {
          alert.show(res.data.text)
          history.push('/adm-dashboard')
        })
        .catch((e) => {
          alert.show('Ocorreu algum erro')
        })
    } else {
      alert.show('Preencha todos os campos')
    }
  }

  return (
    <Container>
      <Wrapper onSubmit={handleSubmit}>
        <Left>
          <Title>Suporte</Title>
          <SubTitle>Tem alguma dúvida que não foi responsida? Fale conosco</SubTitle>
          <InputItem>
            <p>Insira seu nome completo</p>
            <input
              onChange={(e) => setNome(e.target.value)}
              placeholder="Insira seu nome"
            />
          </InputItem>
          <InputItem>
            <p>E-mail</p>
            <input
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Insira seu email"
            />
          </InputItem>
          <InputItem>
            <p>Dúvida</p>
            <textarea
              onChange={(e) => setTexto(e.target.value)}
              rows={8}
              placeholder="Insira sua dúvida"
            />
          </InputItem>
          <SubmitButton type="submit">ENVIAR MENSAGEM</SubmitButton>
        </Left>
        <Right>
          <Banner src="./assets/adm-support/banner.svg" alt="banner" />
          <Info>
            Sua dúvida pode já ter sido <span>RESPONDIDA!</span>
          </Info>
          <SubTitle>Confira nossa seção de Tutoriais e Dúvidas frequentes</SubTitle>
          <Link to="/adm-tutorial">CONFERIR</Link>
        </Right>
      </Wrapper>
    </Container>
  )
}

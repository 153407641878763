import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CustomersWithReminderItem } from '../../components/CustomersWithReminderItem'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmApi from '../../services/AdmApi'
import {
  ChooseButton,
  Container,
  CustomersWithReminders,
  CustomersWithRemindersHeader,
  CustomersWithRemindersItemArea,
  FirstLine,
  ListButton,
  RegisterButton,
  RegisterCustomer,
  RegisterCustomerSubmitArea,
  RegisterCustomerTitle,
  Reminders,
  SecondLine,
  Title,
  TotalCustomers
} from './style'

interface DataProps {
  entradas: number
  id: number
  img: string
  nome: string
  saidas: number
  tipo_img: string
}

export const ADMDashboard: React.FC = () => {
  const [allIsActive, setAllIsActive] = useState(true)
  const [entryIsActive, setEntryIsActive] = useState(false)
  const [outIsActive, setOutIsActive] = useState(false)
  const { openCreateClientModal } = useModal()
  const { key } = useUser()

  const [data, setData] = useState<DataProps[]>([])

  const activeAll = () => {
    setAllIsActive(true)
    setEntryIsActive(false)
    setOutIsActive(false)
  }
  const activeEntry = () => {
    setAllIsActive(false)
    setEntryIsActive(true)
    setOutIsActive(false)
  }
  const activeOut = () => {
    setAllIsActive(false)
    setEntryIsActive(false)
    setOutIsActive(true)
  }

  useEffect(() => {
    AdmApi.dash1(key).then((res) => setData(res.data.clientes))
  }, [])

  return (
    <Container>
      <FirstLine>
        <CustomersWithReminders>
          <CustomersWithRemindersHeader>
            <Title>Clientes com lembretes no dia</Title>
            <div>
              <ChooseButton onClick={activeAll} isActive={allIsActive}>
                Todos
              </ChooseButton>
              <ChooseButton onClick={activeEntry} isActive={entryIsActive}>
                Entradas
              </ChooseButton>
              <ChooseButton onClick={activeOut} isActive={outIsActive}>
                Saídas
              </ChooseButton>
            </div>
          </CustomersWithRemindersHeader>
          <CustomersWithRemindersItemArea>
            {data.map((user, index) => {
              return (
                <CustomersWithReminderItem
                  entradas={user.entradas}
                  id={user.id}
                  img={user.img}
                  nome={user.nome}
                  saidas={user.saidas}
                  tipo_img={user.tipo_img}
                  key={index}
                />
              )
            })}
          </CustomersWithRemindersItemArea>
        </CustomersWithReminders>
        <RegisterCustomer>
          <RegisterCustomerTitle>
            <span>Cadastre um </span>novo cliente
          </RegisterCustomerTitle>
          <img src="../assets/adm-dashboard/customer-banner.png" alt="Banner" />
          <RegisterCustomerSubmitArea>
            <ListButton>
              <Link to="adm-clients">Ver listagem</Link>
            </ListButton>
            <RegisterButton onClick={openCreateClientModal}>
              Cadastrar
            </RegisterButton>
          </RegisterCustomerSubmitArea>
        </RegisterCustomer>
      </FirstLine>
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  width: 30%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(213, 213, 213, 0.35);
  padding: 22px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1200px) {
    width: 100%;
  }
`

export const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  .icon {
    color: #c4d3ce;
  }

  &:hover {
    .icon {
      color: #48bdd7;
    }
  }
`

export const GraphicBoxTitle = styled.p`
  font-weight: bold;
  font-size: 13px;
  color: #343333;

  display: flex;
  align-items: center;
  gap: 2px;
`
export const GraphicBoxSubTitle = styled.p`
  font-weight: 500;
  color: rgba(52, 51, 51, 1);
  font-weight: 200;
  font-size: 11px;
`

export const GraphicBoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 22px;
    color: #343333;
  }
`

export const GraphicArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 22px;
  margin-bottom: 42px;
`

export const Graphic = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 12px solid #f2f2f2;
  justify-self: center;
  color: #d7d7d7;
  font-size: 22px;
  font-weight: bold;
`

export const GraphicLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  p {
    font-weight: bold;
  }
`

interface BallProps {
  background: string
}

export const Ball = styled.div<BallProps>`
  background: ${(props) => props.background};
  width: 15px;
  height: 15px;
  border-radius: 50%;
`

export const GraphicData = styled.div`
  padding: 12px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .graphic {
    max-height: 500px !important;
  }
`

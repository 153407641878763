import { useModal } from '../../contexts/ModalContext'
import { Box, Container, OverlayBackdrop, OverlayBody, Title, Button } from './style'

export const ModalVerifyPayment: React.FC = () => {
  const { paymentLink } = useModal()

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody />
      <Box initial={{ right: -500 }} animate={{ right: 0 }} exit={{ right: -500 }}>
        <Title>Seu plano expirou :(</Title>
        <Title>Clique no botão abaixo para renová-lo.</Title>
        <Button target="_blank" href={paymentLink}>
          Assinar
        </Button>
      </Box>
    </Container>
  )
}

import React, { useEffect, useState } from 'react'
import { ButtonDelete, Container, Content, Icon, Line, Title } from './style'
import { AiOutlineClose } from 'react-icons/ai'
import { useUser } from '../../contexts/UserContext'
import AdmApi from '../../services/AdmApi'
import { useModal } from '../../contexts/ModalContext'
import IndividualApi from '../../services/IndividualApi'
import { IoMdNotificationsOutline } from 'react-icons/io'

interface NotificationProps {
  title: string
  text: string
  id: string
  criado: string
}

export const Notification: React.FC<NotificationProps> = ({
  title,
  text,
  id,
  criado
}) => {
  const { isAdministrative, key } = useUser()
  const { updatingNotification2 } = useModal()

  const deleteNotification = () => {
    if (isAdministrative) {
      AdmApi.delete_notify(key, id).then(() => updatingNotification2())
    } else {
      IndividualApi.delete_notify(key, id).then(() => updatingNotification2())
    }
  }

  return (
    <Container>
      <Line>
        <Icon>
          <IoMdNotificationsOutline color="#ccc" size={22} />
        </Icon>
      </Line>
      <Title>
        {title} <span>{criado}</span>
      </Title>
      {text.split('-').map((s: any, index: number) => {
        return <Content key={index}>{s}</Content>
      })}
      <ButtonDelete onClick={deleteNotification}>
        <AiOutlineClose color="red" size={20} />
      </ButtonDelete>
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  background: #f6f8fa;
  padding: 42px 52px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 640px) {
    padding: 42px 12px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 52px;
  border-bottom: 1px solid rgba(55, 55, 57, 0.1);

  @media (max-width: 550px) {
    flex-direction: column;
    text-align: center;
    gap: 22px;
  }
`

export const Title = styled.h1`
  color: #343333;
  font-size: 20px;
`
export const SubTitle = styled.h1`
  color: #7f7f7f;
  font-size: 14px;
`

export const SessionTitle = styled.p`
  color: var(--primary);
  span {
    font-weight: bold;
  }
  padding: 22px 52px;

  @media (max-width: 1100px) {
    text-align: center;
  }
`

export const Search = styled.div`
  background-color: rgba(224, 236, 232, 0.51);
  border-radius: 4px;

  display: flex;
  align-items: center;
  height: 40px;
  width: 360px;

  input {
    padding: 0 12px;
    color: var(--primary);
    width: 90%;
    height: 100%;

    &::placeholder {
      color: var(--primary);
    }
  }

  @media (max-width: 395px) {
    width: 100%;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: auto;
  justify-content: space-between;
  row-gap: 32px;
  margin-bottom: 22px;
  padding: 22px 52px;

  @media (max-width: 1100px) {
    grid-template-columns: auto;
    justify-content: center;
  }

  @media (max-width: 460px) {
    width: 100%;
    padding: 22px 0;
  }
`

export const CommonQuestions = styled.div`
  margin-top: 42px;
  margin-bottom: 22px;

  display: flex;
  padding: 22px 52px;

  @media (max-width: 685px) {
    flex-direction: column;
    align-items: center;
    padding: 22px 0;
  }
`

export const CommonQuestionsSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 685px) {
    width: 100%;
    align-items: center;
  }
`

export const QuestionTitle = styled.h1`
  font-size: 22px;
  margin-bottom: 12px;
  color: #585a6a;

  @media (max-width: 685px) {
    text-align: center;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 52px;

  a {
    color: var(--primary);
    font-size: 20px;
    font-weight: bold;
  }

  @media (max-width: 430px) {
    padding: 0 12px;
    align-items: center;
    width: 100%;
    text-align: center;
  }
`

import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import Switch from 'react-switch'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmInUserApi from '../../services/AdmInUserApi'
import IndividualApi from '../../services/IndividualApi'
import {
  Box,
  Container,
  Grid,
  Header,
  Item,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  SubTitle,
  Title
} from './style'

export const ModalColumns: React.FC = () => {
  const alert = useAlert()
  const { closeColumnsModal } = useModal()
  const { key, admInUser, admInUserId } = useUser()

  const [tipo, setTipo] = useState(false)
  const [dataPrev, setDataPrev] = useState(false)
  const [dataPag, setDataPag] = useState(false)
  const [dataRegistro, setDataRegistro] = useState(false)
  const [valor, setValor] = useState(false)
  const [tipoPag, setTipoPag] = useState(false)
  const [respPag, setRespPag] = useState(false)
  const [respNome, setRespNome] = useState(false)
  const [projeto, setProjeto] = useState(false)
  const [historico, setHistorico] = useState(false)
  const [categoria, setCategoria] = useState(false)
  const [status, setStatus] = useState(false)
  const [conta, setConta] = useState(false)

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeColumnsModal()
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (!admInUser) {
      IndividualApi.update_columns({
        key,
        tipo,
        data_prev: dataPrev,
        data_pag: dataPag,
        data_registro: dataRegistro,
        valor,
        tipo_pag: tipoPag,
        resp_pag: respPag,
        resp_nome: respNome,
        projeto,
        historico,
        categoria,
        status,
        conta
      })
        .then(() => {
          closeColumnsModal()
        })
        .catch((e: AxiosError) => alert.show(e.response?.data.error))
    } else {
      AdmInUserApi.update_columns(
        {
          key,
          tipo,
          data_prev: dataPrev,
          data_pag: dataPag,
          data_registro: dataRegistro,
          valor,
          tipo_pag: tipoPag,
          resp_pag: respPag,
          resp_nome: respNome,
          projeto,
          historico,
          categoria,
          status,
          conta
        },
        admInUserId
      )
        .then(() => {
          closeColumnsModal()
        })
        .catch((e: AxiosError) => alert.show(e.response?.data.error))
    }
  }

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.list_columns(key).then((res) => {
        setTipo(res.data.cols.tipo !== 0)
        setDataPrev(res.data.cols.data_prev !== 0)
        setDataPag(res.data.cols.data_pag !== 0)
        setDataRegistro(res.data.cols.data_registro !== 0)
        setValor(res.data.cols.valor !== 0)
        setTipoPag(res.data.cols.tipo_pag !== 0)
        setRespPag(res.data.cols.resp_pag !== 0)
        setRespNome(res.data.cols.resp_nome !== 0)
        setProjeto(res.data.cols.projeto !== 0)
        setHistorico(res.data.cols.historico !== 0)
        setCategoria(res.data.cols.categoria !== 0)
        setStatus(res.data.cols.status !== 0)
        setConta(res.data.cols.conta !== 0)
      })
    } else {
      AdmInUserApi.list_columns(key, admInUserId).then((res) => {
        setTipo(res.data.cols.tipo !== 0)
        setDataPrev(res.data.cols.data_prev !== 0)
        setDataPag(res.data.cols.data_pag !== 0)
        setDataRegistro(res.data.cols.data_registro !== 0)
        setValor(res.data.cols.valor !== 0)
        setTipoPag(res.data.cols.tipo_pag !== 0)
        setRespPag(res.data.cols.resp_pag !== 0)
        setRespNome(res.data.cols.resp_nome !== 0)
        setProjeto(res.data.cols.projeto !== 0)
        setHistorico(res.data.cols.historico !== 0)
        setCategoria(res.data.cols.categoria !== 0)
        setStatus(res.data.cols.status !== 0)
        setConta(res.data.cols.conta !== 0)
      })
    }
  }, [])

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
        <Header>
          <Title>Filtrar colunas que deseja exibir</Title>
        </Header>
        <SubTitle>Escolha as colunas que deseja na listagem de lançamentos</SubTitle>
        <Grid>
          <Item>
            <Switch
              className="switch"
              onChange={() => setTipo(!tipo)}
              checked={tipo}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Tipo</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setDataPrev(!dataPrev)}
              checked={dataPrev}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Data prevista</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setDataPag(!dataPag)}
              checked={dataPag}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Data do pagamento</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setDataRegistro(!dataRegistro)}
              checked={dataRegistro}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Data registro</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setValor(!valor)}
              checked={valor}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Valor</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setTipoPag(!tipoPag)}
              checked={tipoPag}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Tipo pagamento</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setRespPag(!respPag)}
              checked={respPag}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Receber de / Pagar a</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setRespNome(!respNome)}
              checked={respNome}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Resp nome</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setProjeto(!projeto)}
              checked={projeto}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Projeto</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setHistorico(!historico)}
              checked={historico}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Histórico</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setCategoria(!categoria)}
              checked={categoria}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Categoria</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setStatus(!status)}
              checked={status}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Status</p>
          </Item>
          <Item>
            <Switch
              className="switch"
              onChange={() => setConta(!conta)}
              checked={conta}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34BFA3"
              height={20}
              width={40}
              offColor="#999"
              offHandleColor="#fff"
            />
            <p>Conta</p>
          </Item>
        </Grid>
        <SubmitButton onClick={handleSubmit}>Concluir</SubmitButton>
      </Box>
    </Container>
  )
}

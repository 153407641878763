import api from './api'

interface UpdateClientProps {
  key?: string
  id?: string
  seg: string
  email: string
  tel: string
  doc: string
  razao: string
  nome: string
}

interface UpdateMyDataProps {
  key: string
  nome: string
  doc: string
  email: string
  base64?: string
  tipo?: string
  tel: string
  pwd?: string
}

interface UpgradePlanProps {
  key: string
  nome: string
  email: string
  tel: string
  texto: string
}

class AdmInUserApi {
  public async header_info(key: string) {
    return api.post('/clientes/header.php', { key }).then((res) => {
      return res
    })
  }

  public async new_entry(data: App.Payment, id: string) {
    const formData = new FormData()

    formData.append('key', data.key)
    formData.append('tipo', data.tipo)
    formData.append('id', id)
    formData.append('data_prev', data.data_prev)
    formData.append('data_pag', data.data_pag)
    formData.append('data_registro', data.data_registro)
    formData.append('cadastro', data.cadastro.toLowerCase())
    formData.append('valor', data.valor)
    formData.append('tipo_pag', data.tipo_pag)
    formData.append('resp_pag', data.resp_pag)
    formData.append('resp_nome', data.resp_nome)
    formData.append('projeto', data.projeto)
    formData.append('conta', data.conta)
    formData.append('historico', data.historico)
    formData.append('categoria', data.categoria)
    formData.append('status', data.status)
    formData.append('arquivo', data.arquivo)

    return api.post('/clientes/add/new_entry.php', formData).then((res) => res)
  }

  public async new_rec(data: App.Recurrence, id: string) {
    return api.post('/clientes/add/new_rec.php', { ...data, id }).then((res) => res)
  }

  public async new_par(data: App.Installments, id: string) {
    return api.post('/clientes/add/new_par.php', { ...data, id }).then((res) => res)
  }

  public async new_product(data: App.NewProduct, id: string) {
    return api
      .post('/clientes/add/new_product.php', { ...data, id })
      .then((res) => res)
  }

  public async new_provider(data: App.NewPerson, id: string) {
    return api.post('/clientes/add/new_forn.php', { ...data, id }).then((res) => res)
  }

  public async new_client(data: App.NewPerson, id: string) {
    return api
      .post('/clientes/add/new_client.php', { ...data, id })
      .then((res) => res)
  }

  public async new_category(data: App.NewCategory, id: string) {
    return api
      .post('/clientes/add/new_category.php', { ...data, id })
      .then((res) => res)
  }

  public async new_center(data: App.NewCostCenter, id: string) {
    return api
      .post('/clientes/add/new_center.php', { ...data, id })
      .then((res) => res)
  }

  public async new_bank_account(data: App.NewBankAccount, id: string) {
    return api
      .post('/clientes/add/new_account.php', { ...data, id })
      .then((res) => res)
  }

  // LISTAGEM

  public async list_clients(key: string, id: string) {
    return api.post('/clientes/listagem/clients.php', { key, id }).then((res) => res)
  }

  public async list_categories(key: string, id: string) {
    return api
      .post('/clientes/listagem/category.php', { key, id })
      .then((res) => res)
  }

  public async list_products(key: string, id: string) {
    return api.post('/clientes/listagem/product.php', { key, id }).then((res) => res)
  }

  public async list_forn(key: string, id: string) {
    return api.post('/clientes/listagem/forn.php', { key, id }).then((res) => res)
  }

  public async list_accounts(key: string, id: string) {
    return api
      .post('/clientes/listagem/accounts.php', { key, id })
      .then((res) => res)
  }

  public async list_recs(key: string, id: string) {
    return api.post('/clientes/listagem/recs.php', { key, id }).then((res) => res)
  }

  // DELETAR

  public async delete_client(key: string, id: string, id_client: string) {
    return api
      .post('/clientes/delete/delete_client.php', { key, id, id_client })
      .then((res) => res)
  }

  public async delete_center(key: string, id: string, id_client: string) {
    return api
      .post('/clientes/delete/delete_center.php', { key, id, id_client })
      .then((res) => res)
  }

  public async delete_category(key: string, id: string, id_client: string) {
    return api
      .post('/clientes/delete/delete_category.php', { key, id, id_client })
      .then((res) => res)
  }

  public async delete_product(key: string, id: string, id_client: string) {
    return api
      .post('/clientes/delete/delete_product.php', { key, id, id_client })
      .then((res) => res)
  }

  public async delete_forn(key: string, id: string, id_client: string) {
    return api
      .post('/clientes/delete/delete_forn.php', { key, id, id_client })
      .then((res) => res)
  }

  public async delete_account(key: string, id: string, id_client: string) {
    return api
      .post('/clientes/delete/delete_account.php', { key, id, id_client })
      .then((res) => res)
  }

  public async delete_rec(key: string, id: string, id_client: string) {
    return api
      .post('/clientes/delete/delete_rec.php', { key, id, id_client })
      .then((res) => res)
  }

  // ATUALIZAR

  public async update_client(
    key: string,
    id: string,
    nome: string,
    tel: string,
    email: string,
    desc: string,
    id_client: string
  ) {
    return api
      .post('/clientes/update/update_client.php', {
        key,
        id,
        nome,
        tel,
        email,
        desc,
        id_client
      })
      .then((res) => res)
  }

  public async update_center(
    key: string,
    id: string,
    nome: string,
    desc: string,
    id_client: string
  ) {
    return api
      .post('/clientes/update/update_center.php', { key, id, nome, desc, id_client })
      .then((res) => res)
  }

  public async update_category(
    key: string,
    id: string,
    nome: string,
    desc: string,
    id_client: string
  ) {
    return api
      .post('/clientes/update/update_category.php', {
        key,
        id,
        nome,
        desc,
        id_client
      })
      .then((res) => res)
  }

  public async update_recurrence(
    {
      cadastro,
      conta,
      categoria,
      data_registro,
      dia_prev,
      historico,
      key,
      projeto,
      resp_nome,
      resp_pag,
      tipo,
      tipo_pag,
      valor,
      id
    }: App.Recurrence,
    id_client: string
  ) {
    return api
      .post('/clientes/update/update_rec.php', {
        cadastro,
        conta,
        categoria,
        data_registro,
        dia_prev,
        historico,
        key,
        projeto,
        resp_nome,
        resp_pag,
        tipo,
        tipo_pag,
        valor,
        id,
        id_client
      })
      .then((res) => res)
  }

  public async update_product(
    key: string,
    id: string,
    nome: string,
    categoria: string,
    preco: string,
    sku: string,
    obs: string,
    id_client: string
  ) {
    return api
      .post('/clientes/update/update_product.php', {
        key,
        id,
        nome,
        categoria,
        preco,
        sku,
        obs,
        id_client
      })
      .then((res) => res)
  }

  public async update_forn(
    key: string,
    id: string,
    nome: string,
    tel: string,
    email: string,
    desc: string,
    id_client: string
  ) {
    return api
      .post('/clientes/update/update_forn.php', {
        key,
        id,
        nome,
        tel,
        email,
        desc,
        id_client
      })
      .then((res) => res)
  }

  public async update_account(
    key: string,
    id: string,
    nome: string,
    banco: string,
    agencia: string,
    conta: string,
    tipo: string,
    desc: string,
    id_client: string
  ) {
    return api
      .post('/clientes/update/update_account.php', {
        key,
        id,
        nome,
        banco,
        agencia,
        conta,
        tipo,
        desc,
        id_client
      })
      .then((res) => res)
  }
  // COLUMNS

  public async list_columns(key: string, id_client: string) {
    return api
      .post('/clientes/lancamentos/coluns.php', { key, id_client })
      .then((res) => res)
  }

  public async update_columns(data: App.Columns, id_client: string) {
    return api
      .post('/clientes/lancamentos/update_coluns.php', { ...data, id_client })
      .then((res) => res)
  }

  // LANÇAMENTOS

  public async list_lancamentos(
    id_client: string,
    key: string,
    inicio?: string,
    final?: string
  ) {
    if (inicio !== '' && final !== '') {
      return api
        .post('/clientes/lancamentos/lancamentos.php', {
          key,
          inicio,
          final,
          id_client
        })
        .then((res) => res)
    } else {
      return api
        .post('/clientes/lancamentos/lancamentos.php', { key, id_client })
        .then((res) => res)
    }
  }

  public async lancamento(key: string, id: string, id_client: string) {
    return api
      .post('/clientes/lancamentos/get_lancamento.php', { key, id, id_client })
      .then((res) => res)
  }

  public async del_lancamento(key: string, id: string, id_client: string) {
    return api
      .post('/clientes/lancamentos/del_lancamento.php', { key, id, id_client })
      .then((res) => res)
  }

  public async update_lancamento(data: App.Payment, id: string, id_client: string) {
    const formData = new FormData()

    formData.append('id', id)
    formData.append('id_client', id_client)
    formData.append('key', data.key)
    formData.append('tipo', data.tipo)
    formData.append('data_prev', data.data_prev)
    formData.append('data_pag', data.data_pag)
    formData.append('data_registro', data.data_registro)
    formData.append('cadastro', data.cadastro.toLowerCase())
    formData.append('valor', data.valor)
    formData.append('tipo_pag', data.tipo_pag)
    formData.append('resp_pag', data.resp_pag)
    formData.append('resp_nome', data.resp_nome)
    formData.append('projeto', data.projeto)
    formData.append('conta', data.conta)
    formData.append('historico', data.historico)
    formData.append('categoria', data.categoria)
    formData.append('status', data.status)
    formData.append('arquivo', data.arquivo)

    return api
      .post('/clientes/lancamentos/update_lancamento.php', formData)
      .then((res) => res)
  }

  // DASHBOARD

  public async dash1(key: string, dias: number, id: string) {
    return api
      .post('/clientes/dashboard/dash1.php', {
        key,
        dias,
        id
      })
      .then((res) => res)
  }

  public async previsao(key: string, dias: string, id: string) {
    return api
      .post('/clientes/dashboard/previsao.php', {
        key,
        dias,
        id
      })
      .then((res) => res)
  }

  public async categorias(key: string, dias: string, id: string) {
    return api
      .post('/clientes/dashboard/categoria.php', {
        key,
        dias,
        id
      })
      .then((res) => res)
  }

  public async lucro(key: string, meses: number, id: string) {
    return api
      .post('/clientes/dashboard/lucro.php', {
        key,
        meses,
        id
      })
      .then((res) => res)
  }

  // SUPPORT

  public async send_support(data: App.SendSupport, id: string) {
    return api.post('/clientes/send_suport.php', data).then((res) => res)
  }

  // MY DATA

  public async my_data(key: string) {
    return api.post('/clientes/meusdados/get_infos.php', { key }).then((res) => res)
  }

  public async update_my_data(data: UpdateMyDataProps, id: string) {
    return api
      .post('/clientes/meusdados/update_infos.php', { ...data, id })
      .then((res) => res)
  }

  public async upgrade_plan(data: UpgradePlanProps, id: string) {
    return api
      .post('/clientes/meusdados/upgrade_plan.php', { ...data, id })
      .then((res) => res)
  }

  // REPORT

  public async generate_report(data: App.GenerateReport, id: string) {
    return api
      .post('/clientes/relatorio/generate.php', { ...data, id })
      .then((res) => res)
  }

  public async list_models(key: string, id: string) {
    return api
      .post('/clientes/relatorio/get_models.php', { key, id })
      .then((res) => res)
  }

  public async delete_model(key: string, id: number, id_client: string) {
    return api
      .post('/clientes/relatorio/del_model.php', { key, id, id_client })
      .then((res) => res)
  }

  public async atividades(key: string, id: string) {
    return api
      .post('/clientes/dashboard/atividades.php', { key, id })
      .then((res) => res)
  }

  public async notificacoes(key: string, id: string) {
    return api.post('/clientes/notificacoes.php', { key, id }).then((res) => res)
  }
}

export default new AdmInUserApi()

import React from 'react'
import { IndividualContainer } from '../../components/IndividualContainer'
import { Container } from './style'

export const CashFlow: React.FC = () => {
  return (
    <IndividualContainer>
      <Container>Fluxo de Caixa</Container>
    </IndividualContainer>
  )
}

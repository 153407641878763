import { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { AdministrativeInUserLayout } from './components/AdministrativeInUserLayout'
import { AdministrativeInUserRoutes } from './components/AdministrativeInUserRoutes'
import { AdministrativeLayout } from './components/AdministrativeLayout'
import { AdministrativeRoutes } from './components/AdministrativeRoutes'
import { AuthPrivateRoute } from './components/AuthPrivateRoute copy'
import { IndividualLayout } from './components/IndividualLayout'
import { IndividualRoutes } from './components/IndividualRoutes'
import { Modals } from './components/Modals'
import { useModal } from './contexts/ModalContext'
import { useUser } from './contexts/UserContext'
import { Login } from './pages/Login'
import { ResetPassword } from './pages/ResetPassword'
import AdmApi from './services/AdmApi'
import IndividualApi from './services/IndividualApi'
import { Register } from './pages/Register'
import { RegisterCode } from './pages/RegisterCode'
import Joyride from 'react-joyride'
import Tour from 'reactour'

export const App: React.FC = () => {
  const {
    hasUser,
    setIndividualHeaderInfo,
    setHasUser,
    setKey,
    setPlan,
    setHaveClients,
    setAdmHeaderInfo,
    user,
    isAdministrative,
    updateProfileImage,
    admInUser
  } = useUser()
  const { openVerifyPayment, openPaymentModal } = useModal()

  const [isOpen, setIsOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const user = !!localStorage.getItem('@seven_key')
    const userKey = localStorage.getItem('@seven_key')
    const plan = localStorage.getItem('@seven_plan')
    const clients = localStorage.getItem('@seven_haveClients')
    if (user) {
      setHasUser(true)
      setKey(userKey!)
      setPlan(plan!)
      if (clients === 'true') {
        setHaveClients(true)
      } else {
        setHaveClients(false)
      }
    } else {
      setHasUser(false)
    }

    if (plan === 'administrativo') {
      AdmApi.header_info(userKey!)
        .then((res) => {
          if (res.data.dias <= 0) {
            openVerifyPayment(res.data.link)
          }
          setAdmHeaderInfo(res.data.header)
          if (res.data.header.num_clientes > 0) {
            setHaveClients(true)
          }
        })
        .catch((e) => console.log(e))
        .finally(() => setIsLoading(false))
    } else {
      IndividualApi.header_info(userKey!)
        .then((res) => {
          if (res.data.dias <= 0) {
            openVerifyPayment(res.data.link)
          }
          setIndividualHeaderInfo(res.data)
        })
        .catch((e) => console.log(e))
        .finally(() => setIsLoading(false))
    }
  }, [hasUser, updateProfileImage])

  if (isLoading) {
    return <>loading</>
  }

  if (!user) {
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <AuthPrivateRoute path="/login" component={Login} />
        <AuthPrivateRoute path="/reset-password" component={ResetPassword} />
        <AuthPrivateRoute path="/register" component={RegisterCode} />
      </Switch>
    )
  }

  if (isAdministrative && admInUser) {
    return (
      <AdministrativeInUserLayout>
        <AdministrativeInUserRoutes />
        <Modals />
      </AdministrativeInUserLayout>
    )
  }

  if (isAdministrative && !admInUser) {
    return (
      <AdministrativeLayout>
        <AdministrativeRoutes />
        <Modals />
      </AdministrativeLayout>
    )
  }

  const steps = [
    {
      target: '#first-step',
      content: 'First step',
      disableBeacon: true
    },
    {
      target: '#second-step',
      content: 'Second step'
    }
  ]

  if (!isAdministrative) {
    return (
      <IndividualLayout>
        {/* <Joyride
          showProgress
          showSkipButton
          disableCloseOnEsc
          disableOverlayClose
          callback={(e) => {
            if (e.step.content === 'First step') {
              openPaymentModal('Entrada')
            }
          }}
          continuous
          locale={{
            next: 'Próximo',
            back: 'Voltar',
            last: 'Finalizar',
            close: 'Fechar',
            open: 'Abrir',
            skip: 'Pular'
          }}
          steps={steps}
        > */}
        <IndividualRoutes />
        <Modals />
        {/* </Joyride> */}
      </IndividualLayout>
    )
  }

  return <h1>error</h1>
}

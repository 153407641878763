import React, { useEffect, useState } from 'react'
import {
  Container,
  EntryOutProjectionBox,
  Header,
  LeftBox,
  RightBox,
  Route,
  Wrapper
} from './style'

import Select from 'react-select'

import { ArcElement, Chart } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import { Footer } from '../../components/Footer'
import { IndividualContainer } from '../../components/IndividualContainer'
import { AverageProfit } from '../../components/layout/Dashboard/AverageProfit'
import { EntryProjection } from '../../components/layout/Dashboard/EntryProjection'
import { OutProjection } from '../../components/layout/Dashboard/OutProjection'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'
import { ClientInfos } from '../../components/layout/Dashboard/ClientInfos'
import { EntryOutGraphic } from '../../components/layout/Dashboard/EntryOutGraphic'
import { EntriesByCategory } from '../../components/layout/Dashboard/EntriesByCategory'
import { OutByCategory } from '../../components/layout/Dashboard/OutByCategory'
import { Activities } from '../../components/layout/Dashboard/Activities'
import Joyride from 'react-joyride'

export const Dashboard: React.FC = () => {
  return (
    <IndividualContainer>
      <Container>
        <Header></Header>
        <Wrapper>
          <LeftBox>
            <ClientInfos />
          </LeftBox>
          <EntryOutGraphic />
        </Wrapper>
        <Wrapper>
          <EntryOutProjectionBox>
            <EntryProjection />
            <OutProjection />
          </EntryOutProjectionBox>
          <RightBox>
            <AverageProfit />
          </RightBox>
        </Wrapper>
        <Wrapper>
          <LeftBox>
            <EntriesByCategory />
            <OutByCategory />
          </LeftBox>
          <RightBox>
            <Activities />
          </RightBox>
        </Wrapper>
        <Footer />
      </Container>
    </IndividualContainer>
  )
}

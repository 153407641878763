import React from 'react'
import { AiOutlineUser } from 'react-icons/ai'
import { BsChatSquareText } from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import {
  Banner,
  Container,
  Info,
  Item,
  Menu,
  MenuItem,
  Title,
  Top,
  TopLeft,
  TopRight,
  Wrapper
} from './style'

export const ADMHeader: React.FC = () => {
  const { admHeaderInfo, key } = useUser()
  const location = useLocation()

  return (
    <Container isHome={location.pathname === '/home'}>
      <Banner src="./assets/adm-dashboard/banner.png" />
      <Wrapper>
        <Top>
          <TopLeft>
            <Title>
              Seja bem vindo(a){' '}
              {admHeaderInfo?.nome.substring(0, admHeaderInfo?.nome.indexOf(' '))}
            </Title>
            <Info>{admHeaderInfo?.email}</Info>
            <Info>{admHeaderInfo?.tel}</Info>
            <Info>
              Seu plano renova em: <span>{admHeaderInfo?.dias} dias</span>
            </Info>
          </TopLeft>
          <TopRight>
            <Item>
              <Link to="/adm-clients">
                <AiOutlineUser size={32} color="var(--primary)" />
              </Link>
              <p style={{ color: 'var(--primary)' }}>
                <span>{admHeaderInfo?.num_clientes} </span>
                {admHeaderInfo?.num_clientes === 1 ? 'cliente' : 'clientes'}
              </p>
            </Item>
            <Item>
              <div>
                <BsChatSquareText size={28} color="#e0a01d" />
              </div>
              <p style={{ color: '#e0a01d' }}>
                <span>{admHeaderInfo?.num_lembrete} </span>
                {admHeaderInfo?.num_lembrete === 1 ? 'lembrete' : 'lembretes'}
              </p>
            </Item>
          </TopRight>
        </Top>
        <Menu>
          <MenuItem isActive={location.pathname === '/adm-dashboard'}>
            <Link to="/adm-dashboard">Dashboard</Link>
          </MenuItem>
          <MenuItem isActive={location.pathname === '/adm-clients'}>
            <Link to="/adm-clients">Clientes</Link>
          </MenuItem>
          <MenuItem isActive={location.pathname === '/adm-tutorial'}>
            <Link to="/adm-tutorial">Tutoriais</Link>
          </MenuItem>
        </Menu>
      </Wrapper>
    </Container>
  )
}

import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.div`
  width: 145px;
  height: 100%;
  position: absolute;
  left: 0;
  background: #343333;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 0;

  p {
    color: #999999;
  }
`

export const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 22px 0;
`

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`

export const Button = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
  width: 120px;
  height: 60px;

  .icon {
    fill: #a1a1a1;
    transition: fill 0.2s;
  }

  div:nth-child(1) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  div:nth-child(2) {
    position: absolute;
    top: 15px;
    right: 35%;
  }

  p {
    font-size: 10px;
    color: #a1a1a1;
    width: 145px;

    transition: color 0.2s;
  }

  &:hover {
    p {
      color: var(--primary);
    }
    .icon {
      fill: var(--primary);
    }
  }
`

export const SubMenu = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
  background: #3d3c3c;
  position: absolute;
  left: 145px;
  bottom: 0;
  z-index: 2;
  width: 145px;
  height: calc(100% - 130px);

  p:nth-child(1) {
    color: #999999;
    font-size: 12px;
  }
`

export const SubMenuButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 26px;
  gap: 22px;

  p {
    width: 145px;
  }
`

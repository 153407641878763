import styled from 'styled-components'

export const Container = styled.div`
  background: #f6f8fa;
  padding: 42px 52px;

  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const Title = styled.h1`
  color: #343333;
  font-size: 20px;

  @media (max-width: 800px) {
    margin-bottom: 42px;
    text-align: center;
  }
`

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 42px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const Search = styled.div`
  background-color: rgba(224, 236, 232, 0.51);
  border-radius: 4px;

  display: flex;
  align-items: center;
  height: 40px;
  width: 360px;

  input {
    padding: 0 12px;
    width: 90%;
    height: 100%;
  }

  @media (max-width: 385px) {
    width: 280px;
  }
`

export const NewClientButton = styled.button`
  background: var(--primary);
  width: 157px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 4px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: auto;
  gap: 32px;
  justify-content: space-between;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 570px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

import React from 'react'
import { BiCube } from 'react-icons/bi'
import { useModal } from '../../../../contexts/ModalContext'
import { useUser } from '../../../../contexts/UserContext'
import { DoubleIcon } from '../../../DoubleIcon'
import {
  Button,
  ButtonArea,
  Container,
  FirstBoxDescription,
  FirstBoxLeftContent,
  FirstBoxRightContent,
  Icon
} from './style'

export const ClientInfos: React.FC = () => {
  const { user } = useUser()
  const { openPaymentModal, openProductModal } = useModal()

  return (
    <Container>
      <FirstBoxLeftContent>
        <p>Bem vindo(a), {user?.nome}</p>
        <h1>
          Para obter os relatórios, basta
          <span> cadastrar suas entradas e saídas do mês</span>
        </h1>
        <FirstBoxDescription>
          Aqui você terá clareza e controle dos números. Este é o primeiro passo da
          gestão financeira, insira suas informações e cadastre os lançamentos e
          acesse relatórios, gráficos personalizados e tenha uma visão estratégica do
          seu dinheiro.
        </FirstBoxDescription>
        <ButtonArea>
          <Button onClick={() => openPaymentModal('Entrada')}>
            <DoubleIcon isPlus />
            <p>Nova entrada</p>
          </Button>
          <Button onClick={() => openPaymentModal('Saida')}>
            <DoubleIcon isPlus={false} />
            <p>Nova saída</p>
          </Button>
          <Button onClick={() => openProductModal()}>
            <Icon>
              <BiCube size={32} fill="var(--primary)" />
            </Icon>
            <p>Criar Centro de custo</p>
          </Button>
        </ButtonArea>
      </FirstBoxLeftContent>
      <FirstBoxRightContent>
        <img src="./assets/dashboard/banner.svg" alt="banner" />
      </FirstBoxRightContent>
    </Container>
  )
}

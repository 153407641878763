import React from 'react'

import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import { Container, Item } from './style'

export const DropdownList: React.FC = () => {
  const { admInUser } = useUser()
  const location = useLocation()

  return (
    <Container initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
      {/* <Link to="/list-products">
        <Item>
          <MdOutlineKeyboardArrowRight className="icon" size={18} />
          <span>Produtos cadastrados</span>
        </Item>
      </Link> */}
      <Link to={admInUser ? '/adm-in-user-list-providers' : '/list-providers'}>
        <Item
          isActive={
            admInUser
              ? location.pathname === '/adm-in-user-list-providers'
              : location.pathname === '/list-providers'
          }
        >
          <MdOutlineKeyboardArrowRight className="icon" size={18} />
          <span>Fornecedores</span>
        </Item>
      </Link>
      <Link to={admInUser ? '/adm-in-user-list-clients' : '/list-clients'}>
        <Item
          isActive={
            admInUser
              ? location.pathname === '/adm-in-user-list-clients'
              : location.pathname === '/list-clients'
          }
        >
          <MdOutlineKeyboardArrowRight className="icon" size={18} />
          <span>Pagadores</span>
        </Item>
      </Link>
      <Link to={admInUser ? '/adm-in-user-list-categories' : '/list-categories'}>
        <Item
          isActive={
            admInUser
              ? location.pathname === '/adm-in-user-list-categories'
              : location.pathname === '/list-categories'
          }
        >
          <MdOutlineKeyboardArrowRight className="icon" size={18} />
          <span>Categorias</span>
        </Item>
      </Link>
      <Link
        to={admInUser ? '/adm-in-user-list-bank-accounts' : '/list-bank-accounts'}
      >
        <Item
          isActive={
            admInUser
              ? location.pathname === '/adm-in-user-list-bank-accounts'
              : location.pathname === '/list-bank-accounts'
          }
        >
          <MdOutlineKeyboardArrowRight className="icon" size={18} />
          <span>Contas Bancárias</span>
        </Item>
      </Link>
      <Link to={admInUser ? '/adm-in-user-list-products' : '/list-products'}>
        <Item
          isActive={
            admInUser
              ? location.pathname === '/adm-in-user-list-products'
              : location.pathname === '/list-products'
          }
        >
          <MdOutlineKeyboardArrowRight className="icon" size={18} />
          <span>Centro de custos</span>
        </Item>
      </Link>
      <Link to={admInUser ? '/adm-in-user-list-recurrences' : '/list-recurrences'}>
        <Item
          isActive={
            admInUser
              ? location.pathname === '/adm-in-user-list-recurrences'
              : location.pathname === '/list-recurrences'
          }
        >
          <MdOutlineKeyboardArrowRight className="icon" size={18} />
          <span>Recorrências</span>
        </Item>
      </Link>
    </Container>
  )
}

import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 22px;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`

export const InputItem = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 2px solid #d7d7d7;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .typeNumber {
    -webkit-appearance: none;
  }

  input {
    height: 100%;
    font-size: 16px;
  }
`

export const DateLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Input = styled.input`
  padding: 6px 0;
  width: 90%;
  margin-right: 8px;
  height: 100%;
  font-size: 16px;
`

export const InputImageArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 22px;
`

export const InputImageLabel = styled.label`
  width: 100%;
  border: 2px dotted var(--primary);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding: 14px;
  border-radius: 4px;
`

export const InputImage = styled.input`
  display: none;
`

export const SubmitButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
`

export const SubmitButton = styled.button`
  color: #fff;
  background: var(--primary);
  width: 241px;
  height: 45px;
  border-radius: 100px;
  font-weight: 500;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

import React, { useEffect, useState } from 'react'
import { BsPlus, BsSearch } from 'react-icons/bs'
import { IndividualContainer } from '../../components/IndividualContainer'
import { ListCard } from '../../components/ListCard'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmInUserApi from '../../services/AdmInUserApi'
import IndividualApi from '../../services/IndividualApi'
import {
  Container,
  Header,
  NewButton,
  RightContent,
  Search,
  Title,
  Wrapper
} from './style'

interface ClientsProps {
  id: number
  nome: string
  tel: string
  email: string
  desc: string
}

export const ListClients: React.FC = () => {
  const { openPersonModal, updateList } = useModal()
  const { key, admInUser, admInUserId } = useUser()

  const [clients, setClients] = useState<ClientsProps[]>()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.list_clients(key).then((res) => {
        setClients(res.data.clientes)
      })
    } else {
      AdmInUserApi.list_clients(key, admInUserId).then((res) => {
        setClients(res.data.clientes)
      })
    }
  }, [updateList])

  return (
    <IndividualContainer>
      <Container>
        <Header>
          <Title>Pagadores cadastrados ({clients?.length || 0})</Title>
          <RightContent>
            <Search>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Pesquisar"
              />
              <BsSearch size={22} fill="var(--primary)" />
            </Search>
            <NewButton onClick={() => openPersonModal('client')}>
              <BsPlus size={22} fill="#fff" />
              Novo pagador
            </NewButton>
          </RightContent>
        </Header>
        <Wrapper>
          {clients
            // eslint-disable-next-line array-callback-return
            ?.filter((item) => {
              if (search === '') {
                return item
              } else if (item.nome.toLowerCase().includes(search.toLowerCase())) {
                return item
              }
            })
            .map((res) => {
              return (
                <ListCard
                  key={res.id}
                  type="client"
                  id={String(res.id)}
                  personData={{
                    nome: res.nome,
                    email: res.email,
                    tel: res.tel,
                    desc: res.desc
                  }}
                />
              )
            })}
        </Wrapper>
      </Container>
    </IndividualContainer>
  )
}

import React, { useEffect, useState } from 'react'
import { BsPlus, BsSearch } from 'react-icons/bs'
import { IndividualContainer } from '../../components/IndividualContainer'
import { ListCard } from '../../components/ListCard'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmInUserApi from '../../services/AdmInUserApi'
import IndividualApi from '../../services/IndividualApi'
import {
  Container,
  Header,
  NewButton,
  RightContent,
  Search,
  Title,
  Wrapper
} from './style'

export const ListRecurrence: React.FC = () => {
  const { openPersonModal, updateList, openPaymentModal } = useModal()
  const { key, admInUser, admInUserId } = useUser()

  const [recurrence, setRecurrence] = useState<App.Recurrence[]>()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.list_recs(key).then((res) => {
        setRecurrence(res.data.recs)
      })
    } else {
      AdmInUserApi.list_recs(key, admInUserId).then((res) => {
        setRecurrence(res.data.recs)
      })
    }
  }, [updateList])

  return (
    <IndividualContainer>
      <Container>
        <Header>
          <Title>Recorrências cadastradas ({recurrence?.length || 0})</Title>
          <RightContent>
            <Search>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Pesquisar"
              />
              <BsSearch size={22} fill="var(--primary)" />
            </Search>
            <NewButton onClick={() => openPaymentModal('Entrada')}>
              <BsPlus size={22} fill="#fff" />
              Nova recorrência
            </NewButton>
          </RightContent>
        </Header>
        <Wrapper>
          {recurrence
            // eslint-disable-next-line array-callback-return
            ?.filter((item) => {
              if (search === '') {
                return item
              } else if (
                item.resp_nome.toLowerCase().includes(search.toLowerCase())
              ) {
                return item
              }
            })
            .map((rec, index) => {
              return (
                <ListCard
                  key={index}
                  type="recurrence"
                  id={String(rec.id)}
                  recurrenceData={rec}
                />
              )
            })}
        </Wrapper>
      </Container>
    </IndividualContainer>
  )
}

import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { useModal } from '../../contexts/ModalContext'
import { ModalCategory } from '../ModalCategory'
import { ModalColumns } from '../ModalColumns'
import { ModalCostCenter } from '../ModalCostCenter'
import { ModalCreateClient } from '../ModalCreateClient'
import { ModalEditPayment } from '../ModalEditPayment'
import { ModalNewBankAccount } from '../ModalNewBankAccount'
import { ModalNotification } from '../ModalNotification'
import { ModalPayment } from '../ModalPayment'
import { ModalPerson } from '../ModalPerson'
import { ModalProduct } from '../ModalProduct'
import { ModalSuport } from '../ModalSuport'
import { ModalUpgrade } from '../ModalUpgrade'
import { ModalVerifyPayment } from '../ModalVerifyPayment'

export const Modals: React.FC = () => {
  const {
    paymentModalIsOpen,
    editPaymentModalIsOpen,
    productModalIsOpen,
    personModalIsOpen,
    personModalType,
    notificationModalIsOpen,
    verifyPaymentIsOpen,
    supportModalIsOpen,
    newBankAccountModalIsOpen,
    createClientModalIsOpen,
    categoryModalIsOpen,
    costCenterModalIsOpen,
    upgradeModalIsOpen,
    paymentModalType,
    columnsModalIsOpen,
    editPaymentModalId,
    editPaymentModalCadastro
  } = useModal()

  return (
    <AnimatePresence>
      {paymentModalIsOpen && <ModalPayment type={paymentModalType!} />}
      {editPaymentModalIsOpen && (
        <ModalEditPayment
          id={editPaymentModalId}
          cadastro={editPaymentModalCadastro}
          type={paymentModalType!}
        />
      )}
      {productModalIsOpen && <ModalProduct />}
      {personModalIsOpen && <ModalPerson type={personModalType!} />}
      {notificationModalIsOpen && <ModalNotification />}
      {verifyPaymentIsOpen && <ModalVerifyPayment />}
      {supportModalIsOpen && <ModalSuport />}
      {newBankAccountModalIsOpen && <ModalNewBankAccount />}
      {categoryModalIsOpen && <ModalCategory />}
      {costCenterModalIsOpen && <ModalCostCenter />}
      {createClientModalIsOpen && <ModalCreateClient />}
      {upgradeModalIsOpen && <ModalUpgrade />}
      {columnsModalIsOpen && <ModalColumns />}
    </AnimatePresence>
  )
}

import styled from 'styled-components'

export const Calendar = styled.div`
  z-index: 0;
  .calendar-input {
    color: #333;
    background: #e4eeec;
    font-size: 14px;
    border: 0;
    border-radius: 20px;
    height: 36px;
    text-align: center;
    margin: 0 auto;
    width: 200px;
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  gap: 8px;
`

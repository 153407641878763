import styled from 'styled-components'

import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OverlayBackdrop = styled(Overlay)`
  background: rgba(132, 142, 142, 0.1);
  z-index: 15;
`

export const OverlayBody = styled(Overlay)`
  overflow-y: auto;
  z-index: 30;
`

export const Box = styled(motion.div)`
  width: 454px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 24px;
  position: relative;
  z-index: 35;
  overflow-y: auto;
  border-radius: 10px;
`

export const Title = styled.p`
  color: #453e3e;
  text-align: center;
  margin: 12px 0;
  font-size: 24px;
  font-weight: 500;
`

export const InputArea = styled.form`
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  gap: 22px;
`

export const ImageArea = styled.div`
  margin-right: 18px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  input {
    display: none;
  }

  label {
    font-size: 12px;
    border: 1px solid var(--primary);
    padding: 4px;
    border-radius: 4px;
    background: var(--primary);
    cursor: pointer;
    color: #fff;
    font-weight: 500;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const InputItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const InputTitle = styled.p``

export const Input = styled.input`
  background: rgba(180, 175, 178, 0.3);
  border-radius: 4px;
  padding: 12px;
  width: 100%;
`

export const SubmitButton = styled.button`
  background: var(--primary);
  width: 157px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  border-radius: 4px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

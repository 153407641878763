import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'
import {
  Box,
  Container,
  Input,
  InputArea,
  InputItem,
  InputTitle,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  Title
} from './style'
import AdmInUserApi from '../../services/AdmInUserApi'

export const ModalEditProduct: React.FC = () => {
  const { closeEditProductModal, editId, editData, updatingList } = useModal()
  const { key, admInUser, admInUserId } = useUser()

  const alert = useAlert()

  const [nome, setNome] = useState(editData.nome)
  const [categoria, setCategoria] = useState(editData.categoria)
  const [preco, setPreco] = useState(editData.preco)
  const [sku, setSku] = useState(editData.sku)
  const [obs, setObs] = useState(editData.obs)

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeEditProductModal()
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (!admInUser) {
      IndividualApi.update_product(
        key,
        String(editId),
        nome,
        categoria,
        preco,
        sku,
        obs
      ).then((res) => {
        alert.show(res.data.text)
        updatingList()
        closeEditProductModal()
      })
    } else {
      AdmInUserApi.update_product(
        key,
        String(editId),
        nome,
        categoria,
        preco,
        sku,
        obs,
        admInUserId
      ).then((res) => {
        alert.show(res.data.text)
        updatingList()
        closeEditProductModal()
      })
    }
  }
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
        <Title>Editar</Title>
        <InputArea onSubmit={handleSubmit}>
          <InputItem>
            <InputTitle>Nome</InputTitle>
            <Input value={nome} onChange={(e) => setNome(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Preço</InputTitle>
            <Input value={preco} onChange={(e) => setPreco(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Sku</InputTitle>
            <Input value={sku} onChange={(e) => setSku(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Observação</InputTitle>
            <Input value={obs} onChange={(e) => setObs(e.target.value)} />
          </InputItem>
          <SubmitButton type="submit">Editar</SubmitButton>
        </InputArea>
      </Box>
    </Container>
  )
}

import api from './api'

class AuthApi {
  public async createUser(
    nome: string,
    email: string,
    doc: string,
    tel: string,
    tipo_doc: string,
    pwd: string,
    plano: string
  ) {
    return api
      .post('/action/create_user.php', {
        nome,
        email,
        doc,
        tipo_doc,
        tel,
        pwd,
        plano
      })
      .then((res) => res)
  }

  public async login(email: string, pwd: string) {
    return api.post('/action/login.php', { email, pwd }).then((res) => res)
  }

  public async resetPassword(password: string, id: string, hash: string) {
    return api
      .post('/action/reset_pwd', { pwd: password, id, code: hash })
      .then((res) => res)
  }

  public async validateHash(hash: string, id: string) {
    return api.post('/action/check_code', { code: hash, id }).then((res) => res)
  }

  public async registerCode(code: string) {
    return api.post('/action/register_code', { code }).then((res) => res)
  }

  public async sendEmail(email: string) {
    return api.post('/action/send_mail', { email }).then((res) => res)
  }

  public async register({
    code,
    nome,
    doc,
    tipo_doc,
    tel,
    // email,
    pwd
  }: {
    code: string
    nome: string
    doc: string
    tipo_doc: string
    tel: string
    // email: string
    pwd: string
  }) {
    return api
      .post('/action/register', {
        code,
        nome,
        doc,
        tipo_doc,
        tel,
        // email,
        pwd
      })
      .then((res) => res)
  }
}

export default new AuthApi()

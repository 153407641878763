import { AxiosError } from 'axios'
import md5 from 'md5'
import React, { useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import { BiKey } from 'react-icons/bi'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { useHistory, useLocation } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import AuthApi from '../../services/AuthApi'
import {
  Banner,
  ButtonArea,
  Container,
  Content,
  ForgotPasswordButton,
  Form,
  IconArea,
  Input,
  Item,
  Line,
  LinkButton,
  Logo,
  PasswordButton,
  SubmitButton,
  Title
} from './style'

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const ResetPassword: React.FC = () => {
  const {
    setStatus,
    setPlan,
    setKey,
    setHasUser,
    setHaveClients,
    setAdmHeaderInfo,
    setIndividualHeaderInfo
  } = useUser()
  const alert = useAlert()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [passwordIsHide, setPasswordIsHide] = useState(true)
  const [confirmPasswordIsHide, setConfirmPasswordIsHide] = useState(true)

  const [hash, setHash] = useState(query.get('hash'))
  const [id, setId] = useState(query.get('id'))

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (confirmPassword !== password) {
      return alert.show('As senhas devem ser iguais')
    }

    if (password.length < 6) {
      return alert.show('A senha deve conter pelo menos 6 dígitos')
    }

    AuthApi.resetPassword(md5(password), id!, hash!).then(() => {
      alert.show('Senha alterada com sucesso')
      history.push('/login')
    })
  }

  useEffect(() => {
    if (hash && id) {
      AuthApi.validateHash(hash, id).catch(() => {
        history.push('/login')
      })
    } else {
      history.push('/login')
    }
  }, [])

  return (
    <Container>
      <Banner />
      <Content>
        <Logo src="./assets/logo.png" />
        <Title>Recupe sua conta</Title>
        <Form onSubmit={handleSubmit}>
          <Item>
            <div>
              <IconArea>
                <BiKey size={22} fill="#7d91a4" />
              </IconArea>
              <Input
                type={passwordIsHide ? 'password' : ''}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Nova senha"
              />
            </div>
            <PasswordButton
              type="button"
              onClick={() => setPasswordIsHide(!passwordIsHide)}
            >
              {passwordIsHide ? (
                <BsFillEyeFill size={22} fill="#aaa" />
              ) : (
                <BsFillEyeSlashFill size={22} fill="#aaa" />
              )}
            </PasswordButton>
          </Item>
          <Item>
            <div>
              <IconArea>
                <BiKey size={22} fill="#7d91a4" />
              </IconArea>
              <Input
                type={confirmPasswordIsHide ? 'password' : ''}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirmar nova senha"
                value={confirmPassword}
              />
            </div>
            <PasswordButton
              type="button"
              onClick={() => setConfirmPasswordIsHide(!confirmPasswordIsHide)}
            >
              {passwordIsHide ? (
                <BsFillEyeFill size={22} fill="#aaa" />
              ) : (
                <BsFillEyeSlashFill size={22} fill="#aaa" />
              )}
            </PasswordButton>
          </Item>

          <ButtonArea>
            <ForgotPasswordButton
              onClick={() => history.push('/login')}
              type="button"
            >
              Voltar ao login
            </ForgotPasswordButton>
            <SubmitButton type="submit">ENVIAR</SubmitButton>
          </ButtonArea>
        </Form>
        <Line />
      </Content>
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 520px;
  flex: 1;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(213, 213, 213, 0.35);
  padding: 22px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Title = styled.h1`
  font-weight: 700;
  color: #343333;
  font-size: 15px;
`

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  margin-top: 32px;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span:nth-child(1) {
    font-weight: bold;
    width: 90px;
  }

  span:nth-child(2) {
  }
`

import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  min-height: 80px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  position: absolute;
  min-width: 240px;
  top: 35px;
  left: -120px;
  background: #fff;
  border-radius: 8px;
  padding: 22px;
  display: flex;
  flex-direction: column;
  z-index: 2;

  a {
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    width: 100%;
  }
`

interface ItemProps {
  isActive: boolean
}

export const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 4px;
  width: 100%;

  position: relative;

  span {
    transition: color 0.2s;
    font-size: 14px;
    position: absolute;
    left: 40px;
    color: ${(props) => (props.isActive ? 'var(--primary)' : '#aaa')};
  }

  .icon {
    transition: fill 0.2s;
    fill: #aaa;
  }

  &:hover {
    .icon {
      fill: var(--primary);
    }
    span {
      color: var(--primary);
    }
  }
`

export function formatBRL(valor: number) {
  return valor
    .toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    .replace('R$', '')

  // const valorFormatado = valor
  //   ?.toLocaleString('pt-BR', {
  //     style: 'currency',
  //     currency: 'BRL',
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2
  //   })
  //   .replace('R$', '')

  // return valorFormatado
}

import styled from 'styled-components'

import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OverlayBackdrop = styled(Overlay)`
  background: rgba(132, 142, 142, 0.1);
  z-index: 15;
`

export const OverlayBody = styled(Overlay)`
  overflow-y: auto;
  z-index: 30;
`

export const Box = styled(motion.div)`
  width: 700px;
  display: flex;
  flex-direction: column;
  background: #fff;

  position: relative;
  z-index: 35;
  overflow-y: auto;
  border-radius: 10px;
`

export const Header = styled.div`
  border-bottom: 1px solid rgba(150, 150, 150, 0.3);
  padding: 24px;
`

export const Title = styled.p`
  color: #453e3e;
  font-size: 24px;
  font-weight: 500;
`

export const SubTitle = styled.p`
  font-weight: 200;
  color: #10232e;
  text-align: center;
  line-height: normal;
  margin: 22px 0;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 22px;
`

export const Item = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(116, 49, 126, 0.1);
  background-color: rgba(116, 49, 126, 0.03);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.35);
  /* background: black; */

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 12px;

  .switch {
    margin-bottom: 6px;
  }
`

export const SubmitButton = styled.button`
  background: var(--primary);
  width: 231px;
  height: 47px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: #fff;
  border-radius: 100px;
  margin-bottom: 22px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

import moment from 'moment'
import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'
import { FormRecurrence } from '../layout/ModalPayment/FormRecurrence'
import { Box, Container, OverlayBackdrop, OverlayBody, Title } from './style'

export const ModalEditRecurrence: React.FC = () => {
  const { key } = useUser()
  const { closeEditRecurrenceModal, editId, editData } = useModal()

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeEditRecurrenceModal()
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
        <Title>Editar</Title>
        <FormRecurrence
          isEdit={true}
          editId={String(editId)}
          type={editData.cadastro === 'entrada' ? 'Entrada' : 'Saida'}
          editData={{
            id: String(editId!),
            key,
            cadastro: editData.cadastro,
            categoria: editData.categoria,
            conta: editData.conta,
            dia_prev: editData.dia_prev,
            historico: editData.historico,
            projeto: editData.projeto,
            resp_nome: editData.resp_nome,
            resp_pag: editData.resp_pag,
            tipo: editData.tipo,
            valor: editData.valor,
            tipo_pag: editData.tipo_pag,
            data_registro: moment().format('YYYY-MM-DD')
          }}
        />
      </Box>
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  height: 446px;
  flex: 1;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(213, 213, 213, 0.35);
  padding: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  .icon {
    color: #c4d3ce;
  }

  &:hover {
    .icon {
      color: #48bdd7;
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.p`
  color: #343333;
  font-size: 14px;

  display: flex;
  align-items: center;
  gap: 2px;
`

export const Value = styled.p`
  color: #d7001a;
  font-size: 18px;
  font-weight: bold;
`

export const ItemArea = styled.div`
  width: 100%;
  margin-top: 18px;
  overflow-y: auto;
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 18px;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const Date = styled.div`
  border: 1px solid #d7001a;
  padding: 12px;
  border-radius: 4px;
  width: 44px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  color: #d7001a;
`

export const ItemTitle = styled.p`
  color: #555555;
  width: 100px;
  font-size: 14px;
  width: 120px;
`

export const ItemValue = styled.p`
  color: #555555;
  font-weight: bold;
`

import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useUser } from '../../../../contexts/UserContext'
import AdmInUserApi from '../../../../services/AdmInUserApi'
import IndividualApi from '../../../../services/IndividualApi'
import {
  Container,
  Date,
  Header,
  HeaderInfo,
  Item,
  ItemArea,
  ItemInfo,
  ItemTitle,
  ItemValue,
  Title,
  Tooltip,
  Value
} from './style'
import ReactTooltip from 'react-tooltip'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { formatBRL } from '../../../../utils/formatBRL'

interface PrevisaoProps {
  items_entradas: [
    { tipo: string; valor: string; dia: string; resp: string; projeto: string }
  ]
  entradas: number
  saidas: number
  status: number
}

export const EntryProjection: React.FC = () => {
  const { key, updateGraphics, admInUser, admInUserId } = useUser()

  const [days, setDays] = useState(7)

  const [previsao, setPrevisao] = useState<PrevisaoProps>()

  const options = [
    { value: 7, label: '7 dias' },
    { value: 15, label: '15 dias' },
    { value: 30, label: '30 dias' },
    { value: 60, label: '60 dias' },
    { value: 90, label: '90 dias' },
    { value: 120, label: '120 dias' }
  ]

  const customSelectStyles = {
    container: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#e4eeec',
      color: '#768882'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    control: (provided: any) => ({
      ...provided,
      background: '#e4eeec',
      border: 'none',
      borderRadius: '20px',
      width: '120px',
      color: '#768882'
    })
  }

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.previsao(key, String(days)).then((res) => setPrevisao(res.data))
    } else {
      AdmInUserApi.previsao(key, String(days), admInUserId).then((res) =>
        setPrevisao(res.data)
      )
    }
  }, [days, updateGraphics])

  return (
    <Container>
      <Header>
        <HeaderInfo>
          <Title>
            Projeção de Entradas{' '}
            <Tooltip
              data-for="projecaoentradas"
              data-tip="Valores que estão previstos para receber
              no período selecionado."
            >
              <AiOutlineQuestionCircle className="icon" size={20} />
            </Tooltip>
            <ReactTooltip
              id="projecaoentradas"
              place="bottom"
              backgroundColor="#2D3B36"
              effect="solid"
            />
          </Title>
          {previsao && <Value>+ R$ {formatBRL(previsao.entradas)}</Value>}
        </HeaderInfo>
        <Select
          placeholder="7 dias"
          onChange={(e) => {
            setDays(e!.value)
          }}
          options={options}
          styles={customSelectStyles}
        />
      </Header>
      <ItemArea>
        {previsao?.items_entradas.map((entrada, index) => {
          return (
            <Item key={index}>
              <ItemInfo>
                <Date>
                  {entrada.dia.substring(5).replace('-', '/').split('/')[1]}/
                  {entrada.dia.substring(5).replace('-', '/').split('/')[0]}
                </Date>
                <ItemTitle>{entrada.projeto}</ItemTitle>
              </ItemInfo>
              <ItemValue>R$ {formatBRL(Number(entrada.valor))}</ItemValue>
            </Item>
          )
        })}
      </ItemArea>
    </Container>
  )
}

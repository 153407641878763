import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 446px;
  flex: 1;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(213, 213, 213, 0.35);
  padding: 22px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`

export const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  .icon {
    color: #c4d3ce;
  }

  &:hover {
    .icon {
      color: #48bdd7;
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Title = styled.h1`
  font-weight: 700;
  color: #343333;
  font-size: 14px;

  display: flex;
  align-items: center;
  gap: 2px;
`

export const Profit = styled.span`
  font-weight: 500;
  color: #4088b4;
  font-size: 22px;
`

export const ItemArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 22px;
  overflow-y: auto;
  padding-right: 12px;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`

export const ItemDesc = styled.span`
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 300;
`

export const Bar = styled.div`
  background: #4e90b9;
  height: 21px;
  width: 100%;
  border-radius: 33px;
`

export const ItemValue = styled.span`
  font-size: 16px;
  color: #4088b4;
  font-weight: bold;
  /* width: 120px; */
`

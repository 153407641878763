import { FormEvent, useState } from 'react'
import { useAlert } from 'react-alert'
import { MdClose } from 'react-icons/md'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmInUserApi from '../../services/AdmInUserApi'
import IndividualApi from '../../services/IndividualApi'
import {
  Box,
  BoxTop,
  CloseButton,
  Container,
  Form,
  Grid,
  Input,
  InputItem,
  InputTitle,
  InputTitleArea,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  SubmitButtonArea,
  Title,
  Wrapper
} from './style'

export const ModalCategory: React.FC = () => {
  const { closeCategoryModal, updatingList } = useModal()
  const { key, reloadGraphics, admInUser, admInUserId } = useUser()
  const alert = useAlert()

  const [name, setName] = useState('')
  const [observations, setObservations] = useState('')

  const clean = () => {
    setName('')
    setObservations('')
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const data: App.NewCategory = {
      key,
      nome: name,
      desc: observations
    }

    if (name !== '') {
      if (!admInUser) {
        IndividualApi.new_category(data)
          .then((res) => {
            alert.show(res.data.text)
            clean()
            closeCategoryModal()
            updatingList()
          })
          .catch((e) => console.log(e))
          .finally(() => reloadGraphics())
      } else {
        AdmInUserApi.new_category(data, admInUserId)
          .then((res) => {
            alert.show(res.data.text)
            clean()
            closeCategoryModal()
            updatingList()
          })
          .catch((e) => console.log(e))
          .finally(() => reloadGraphics())
      }
    } else {
      alert.show('Os campos marcados com * são obrigatórios')
    }
  }

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeCategoryModal()
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ left: -530 }} animate={{ left: 0 }} exit={{ left: -530 }}>
        <BoxTop>
          <Title>
            Cadastre uma <span>nova categoria</span>
          </Title>
          <CloseButton onClick={closeCategoryModal} type="button">
            <MdClose size={26} />
            Fechar
          </CloseButton>
        </BoxTop>
        <Wrapper>
          <Form onSubmit={handleSubmit}>
            <Grid>
              <InputItem
                style={{
                  borderBottomColor: name.length > 0 ? 'var(--primary)' : '#DF3347'
                }}
              >
                <InputTitleArea>
                  <InputTitle>Nome da categoria</InputTitle>
                  <span>*</span>
                </InputTitleArea>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Digitar..."
                />
              </InputItem>
              <InputItem>
                <InputTitleArea>
                  <InputTitle>Observações</InputTitle>
                </InputTitleArea>
                <Input
                  onChange={(e) => setObservations(e.target.value)}
                  value={observations}
                  placeholder="Digitar..."
                />
              </InputItem>
            </Grid>
            <SubmitButtonArea>
              <SubmitButton type="submit">Finalizar Cadastro</SubmitButton>
            </SubmitButtonArea>
          </Form>
        </Wrapper>
      </Box>
    </Container>
  )
}

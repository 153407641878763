import React, { useState } from 'react'
import { Container, Content, Description, Button } from './style'

import { HiPlus, HiMinus } from 'react-icons/hi'
export const Question: React.FC = () => {
  const [isShowing, setIsShowing] = useState(false)

  return (
    <Container>
      <Content>
        <Button isActive={isShowing} onClick={() => setIsShowing(!isShowing)}>
          <div>{isShowing ? <HiMinus size={18} /> : <HiPlus size={18} />}</div>
          Título da pergunta
        </Button>
        {isShowing && (
          <Description>
            Texto da descrição da perguntar que só vai aparecer quando o usuário
            clicar em cima do título
          </Description>
        )}
      </Content>
    </Container>
  )
}

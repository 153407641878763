import React from 'react'
import { Container } from './style'

import { AiOutlineMail, AiOutlineQuestionCircle } from 'react-icons/ai'
import { Link, useLocation } from 'react-router-dom'

export const Footer: React.FC = () => {
  const location = useLocation()

  return (
    <Container isHome={location.pathname === '/home'}>
      <p>Copyrigt © Todos direitos reservados.</p>
      <div>
        <Link to="/tutorial">
          <AiOutlineQuestionCircle className="icon" size={20} />
        </Link>
        <Link to="/support">
          <AiOutlineMail className="icon" size={20} />
        </Link>
      </div>
    </Container>
  )
}

import React from 'react'

import {
  AiOutlineUser,
  AiOutlineUsergroupAdd,
  AiOutlineVideoCamera
} from 'react-icons/ai'
import { BiHomeAlt } from 'react-icons/bi'
import { IoMdExit } from 'react-icons/io'
import { MdOutlineContactSupport } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import { Container, Item } from './style'

export const DropdownProfile: React.FC = () => {
  const { logOut, isAdministrative, hasClient, setAdmInUser, admInUser } = useUser()

  return (
    <Container initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
      {hasClient && isAdministrative && (
        <>
          <Link
            onClick={() => {
              if (admInUser) {
                setAdmInUser(false)
              }
            }}
            to="/adm-dashboard"
          >
            <Item>
              <BiHomeAlt className="icon" size={22} />
              <span>Dashboard</span>
            </Item>
          </Link>
          <Link
            onClick={() => {
              if (admInUser) {
                setAdmInUser(false)
              }
            }}
            to="/adm-clients"
          >
            <Item>
              <AiOutlineUsergroupAdd className="icon" size={22} />
              <span>Lista de Pagadores</span>
            </Item>
          </Link>
          <Link
            onClick={() => {
              if (admInUser) {
                setAdmInUser(false)
              }
            }}
            to="/adm-my-data"
          >
            <Item>
              <AiOutlineUser className="icon" size={22} />
              <span>Meus Dados</span>
            </Item>
          </Link>
          <Link
            onClick={() => {
              if (admInUser) {
                setAdmInUser(false)
              }
            }}
            to="/adm-tutorial"
          >
            <Item>
              <AiOutlineVideoCamera className="icon" size={22} />
              <span>Tutoriais</span>
            </Item>
          </Link>
          <Link
            onClick={() => {
              if (admInUser) {
                setAdmInUser(false)
              }
            }}
            to="/adm-support"
          >
            <Item>
              <MdOutlineContactSupport className="icon" size={22} />
              <span>Suporte</span>
            </Item>
          </Link>
          <Item
            onClick={() => {
              logOut()
              if (admInUser) {
                setAdmInUser(false)
              }
            }}
          >
            <IoMdExit className="icon" size={22} />
            <span>Sair</span>
          </Item>
        </>
      )}
      {!hasClient && isAdministrative && (
        <Item onClick={logOut}>
          <IoMdExit className="icon" size={22} />
          <span>Sair</span>
        </Item>
      )}
      {!hasClient && !isAdministrative && (
        <>
          <Link to="dashboard">
            <Item>
              <BiHomeAlt className="icon" size={22} />
              <span>Dashboard</span>
            </Item>
          </Link>
          <Link to="/list-clients">
            <Item>
              <AiOutlineUsergroupAdd className="icon" size={22} />
              <span>Lista de Pagadores</span>
            </Item>
          </Link>
          <Link to="/my-data">
            <Item>
              <AiOutlineUser className="icon" size={22} />
              <span>Meus Dados</span>
            </Item>
          </Link>
          <Link to="/tutorial">
            <Item>
              <AiOutlineVideoCamera className="icon" size={22} />
              <span>Tutoriais</span>
            </Item>
          </Link>
          <Link to="/support">
            <Item>
              <MdOutlineContactSupport className="icon" size={22} />
              <span>Suporte</span>
            </Item>
          </Link>
          <Item onClick={logOut}>
            <IoMdExit className="icon" size={22} />
            <span>Sair</span>
          </Item>
        </>
      )}
    </Container>
  )
}

import { motion } from 'framer-motion'
import styled from 'styled-components'

interface ContainerProps {
  showHalfHeader: boolean
  isHome: boolean
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  width: ${(props) => (props.showHalfHeader ? '100%' : 'calc(100% - 145px)')};
  height: ${(props) => (props.showHalfHeader ? '80px' : '130px')};

  display: ${(props) => (props.isHome === true ? 'none' : 'flex')};
  flex-direction: column;
  box-shadow: 0px 10px 17px -17px #000000;
  z-index: 1;

  @media (max-width: 1030px) {
    width: 100%;
    height: 80px;
  }
`

interface TopProps {
  showHalfHeader: boolean
}

export const Top = styled.div<TopProps>`
  height: ${(props) => (props.showHalfHeader ? '100%' : '60%')};
  border-bottom: ${(props) => (props.showHalfHeader ? 'none' : '1px solid #aaa')};
  padding: 12px 52px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  @media (max-width: 1030px) {
    height: 100%;
    border-bottom: none;
  }
`

export const Logo = styled.img`
  width: 108px;
  height: 37px;
`

export const Title = styled.p`
  color: #333333;
  font-size: 13px;
  font-weight: 500;

  span {
    color: var(--primary);
  }
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

export const IconArea = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  margin-right: 22px;
`

export const Icon = styled.button`
  background: #f4f8f7;
  width: 40px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 4px;

  transition: background 0.2s, fill 0.2s, border 0.2s, color 0.2s;

  .icon {
    fill: #5a9681;
  }

  &:hover {
    background: #138059;
    .icon {
      fill: #fff;
    }
    .number {
      background: #fff;
      border: 1px solid #fff;
      color: #5a9681;
    }
  }
`

export const NotificationNumber = styled.div`
  border: 2px solid #5a9681;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 5px;
  top: 5px;
  background: #5a9681;
`

export const MobileNotificationNumber = styled.div`
  border: 2px solid #5a9681;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
`

interface UserProps {
  defaultColor: string
}

export const User = styled.div<UserProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: #333;
  margin-left: 12px;

  h1 {
    font-size: 16px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    font-size: 15px;
  }
`

interface BottomProps {
  showHalfHeader: boolean
}

export const Bottom = styled.div<BottomProps>`
  height: 40%;

  display: ${(props) => (props.showHalfHeader ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  padding: 0 52px;

  @media (max-width: 1030px) {
    display: none;
  }
`

export const Menu = styled.ul`
  display: flex;
  list-style: none;
  gap: 42px;

  @media (max-width: 1110px) {
    gap: 22px;
  }
`

interface MenuItemProps {
  isActive: boolean
}

export const MenuItem = styled.li<MenuItemProps>`
  position: relative;

  a {
    text-decoration: none;
    /* color: #333333; */
    color: ${(props) => (props.isActive ? 'var(--primary)' : '#333333')};

    transition: color 0.2s;

    &:hover {
      color: var(--primary);
    }
  }

  h1 {
    font-size: 16px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: color 0.2s;
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }
  }

  p {
    font-size: 15px;
  }
`

export const ClientName = styled.div``

interface MobileWrapperProps {
  width: string
}

export const MobileWrapper = styled(motion.div)<MobileWrapperProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${(props) => props.width};
  overflow-y: auto;
  height: 100vh;
  background: #343333;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 62px 20px;
`

export const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 22px 0;
`

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const Button = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
  height: 60px;
  margin-bottom: 22px;

  .icon {
    fill: #a1a1a1;
    transition: fill 0.2s;
  }

  div:nth-child(1) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  div:nth-child(2) {
    position: absolute;
    top: 15px;
    right: 35%;
  }

  p {
    font-size: 15px;
    color: #a1a1a1;

    transition: color 0.2s;
  }

  &:hover {
    p {
      color: var(--primary);
    }
    .icon {
      fill: var(--primary);
    }
  }
`

export const MobileMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 42px;
  text-align: center;

  a {
    color: #a1a1a1;
    text-align: center;
    font-size: 15px;
  }
`

export const MobileMyAccount = styled.div`
  display: flex;
  flex-direction: column;
`

export const MobileInlineButton = styled.button`
  div {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  display: flex;
  width: 220px;
  text-align: start;
  align-items: center;
  margin-bottom: 24px;
  font-size: 18px;
  color: #a1a1a1;
  padding: 4px;
`

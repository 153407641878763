import styled from 'styled-components'

interface ContainerProps {
  isHome: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  padding: 12px 52px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(213, 213, 213, 0.35);

  display: ${(props) => (props.isHome === true ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;

  p {
    color: #7f7f7f;
    font-size: 12px;
  }

  div {
    display: flex;
    gap: 22px;

    a {
      .icon {
        fill: #7f7f7f;
        transition: fill 0.2s;

        &:hover {
          fill: var(--primary);
        }
      }
    }
  }
`

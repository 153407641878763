import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f6f8fa;

  padding-top: 16px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  overflow-y: auto;
  padding: 22px 52px;

  @media (max-width: 600px) {
    padding: 22px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;

  @media (max-width: 815px) {
    flex-direction: column;
  }
`

export const Title = styled.h1`
  color: #343333;
  font-size: 20px;

  @media (max-width: 400px) {
    width: 280px;
    text-align: center;
  }
`

export const Wrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  gap: 42px;
  width: 100%;

  @media (max-width: 1190px) {
    flex-direction: column;
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 22px;
  height: 560px;

  border-radius: 11px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.35);

  @media (max-width: 1190px) {
    width: 100%;
  }
`

export const LeftTitle = styled.p`
  font-family: 'Montserrat', sans-serif;
  color: #333333;
  text-align: left;
  line-height: normal;
  margin-bottom: 22px;

  span {
    color: #d73c24;
    font-size: 14px;
  }
`

export const CalendarArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 22px;
  margin-bottom: 42px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const CalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const CalendarWrapperHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    color: #999;
    font-size: 14px;
  }
`

export const InputMonth = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* width: 50%; */
  padding: 12px;
  font-size: 16px;
  border-radius: 6px;
`

export const Options = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  height: 200px;

  @media (max-width: 1190px) {
    gap: 22px;
  }

  @media (max-width: 830px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 460px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 100px;
`

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 42px;

  @media (max-width: 520px) {
    flex-direction: column;
    gap: 22px;
  }
`

export const SaveAsModelArea = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

interface ButtonSaveAsModelProps {
  isActive: boolean
}

export const ButtonSaveAsModel = styled.button<ButtonSaveAsModelProps>`
  display: flex;
  align-items: center;
  gap: 14px;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.isActive && 'var(--primary)'};
`

export const InputSaveAsModel = styled.input`
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 16px;
  padding: 4px;

  width: 250px;
`

export const ButtonGenereteReport = styled.button`
  background: var(--primary);
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  padding: 26px 56px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */

  width: 30%;
  gap: 22px;

  @media (max-width: 1190px) {
    width: 100%;
  }
`

export const Video = styled.div`
  height: 293px;
  width: 100%;
  border-radius: 11px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 22px;
  position: relative;

  padding: 22px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 11px;
    left: 0;
    top: 0;
    filter: brightness(0.7);
  }

  a {
    background: var(--primary);
    width: 50px;
    height: 50px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  div {
    display: flex;
    flex-direction: column;
    font-size: 22px;
    z-index: 2;

    p:nth-child(1) {
      font-weight: 700;
      color: #fff;
      filter: brightness(1.5);
    }

    p:nth-child(2) {
      font-weight: 700;
      color: var(--primary);
    }
  }
`

export const ReportModelList = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReportModelItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 4px;
  border-bottom: 2px solid rgba(215, 215, 215, 0.56);

  span {
    color: #37434f;
    font-size: 14px;
    font-weight: 400;
  }

  div {
    display: flex;
    align-items: center;
    gap: 12px;

    button {
      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.6);
      }
    }
  }
`

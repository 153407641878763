import styled from 'styled-components'

export const Container = styled.div`
  /* width: 265px;
  height: 217px; */
  padding: 22px;

  box-shadow: 0px 0px 11px 0px rgba(215, 215, 215, 0.59);
  background: #fff;
  border-radius: 20px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 90%;
`

export const UserPhoto = styled.img`
  width: 61px;
  height: 61px;
  border-radius: 50%;
`

export const Name = styled.p`
  font-size: 14px;
  color: #343333;
  font-weight: bold;
`

export const ButtonArea = styled.div`
  position: relative;
`

export const OptionButton = styled.button`
  color: var(--primary);

  transition: filter 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: brightness(0.8);
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 12px;
`

export const Content = styled.p`
  color: #6f827d;
  font-size: 14px;
`

import React, { useState } from 'react'
import { BsCheck } from 'react-icons/bs'
import { AiOutlineClose } from 'react-icons/ai'
import {
  ActualPlanText,
  Container,
  Description,
  Item,
  ItemArea,
  Name,
  Price,
  SelectButton
} from './style'

interface PlanCardProps {
  name: string
  description: string
  price: string
  upToTen?: boolean
  upToThirty?: boolean
  analyticsModule?: boolean
  financeModule?: boolean
  accountingModule?: boolean
  networkPlatform?: boolean
  unlimitedCloud?: boolean
  isActive: boolean
}

export const PlanCard: React.FC<PlanCardProps> = ({
  name,
  description,
  price,
  upToTen,
  upToThirty,
  analyticsModule,
  financeModule,
  accountingModule,
  networkPlatform,
  unlimitedCloud,
  isActive
}) => {
  return (
    <Container isActive={isActive}>
      <Name>{name}</Name>
      <Description>{description}</Description>
      <Price>
        <span>$</span>
        <h1>{price}</h1>
        <span>/ Mon</span>
      </Price>
      <ItemArea>
        <Item isActive={upToTen}>
          <p>Up to 10 Active Users</p>
          <div>
            {upToTen ? (
              <>
                <BsCheck size={22} color="var(--primary)" />
              </>
            ) : (
              <>
                <AiOutlineClose size={16} color="#A7ABBB" />
              </>
            )}
          </div>
        </Item>
        <Item isActive={upToThirty}>
          <p>Up to 30 Active Users</p>
          <div>
            {upToThirty ? (
              <>
                <BsCheck size={22} color="var(--primary)" />
              </>
            ) : (
              <>
                <AiOutlineClose size={16} color="#A7ABBB" />
              </>
            )}
          </div>
        </Item>
        <Item isActive={analyticsModule}>
          <p>Analytics Module</p>
          <div>
            {analyticsModule ? (
              <>
                <BsCheck size={22} color="var(--primary)" />
              </>
            ) : (
              <>
                <AiOutlineClose size={16} color="#A7ABBB" />
              </>
            )}
          </div>
        </Item>
        <Item isActive={financeModule}>
          <p>Finance Module</p>
          <div>
            {financeModule ? (
              <>
                <BsCheck size={22} color="var(--primary)" />
              </>
            ) : (
              <>
                <AiOutlineClose size={16} color="#A7ABBB" />
              </>
            )}
          </div>
        </Item>
        <Item isActive={accountingModule}>
          <p>Accouting Module</p>
          <div>
            {accountingModule ? (
              <>
                <BsCheck size={22} color="var(--primary)" />
              </>
            ) : (
              <>
                <AiOutlineClose size={16} color="#A7ABBB" />
              </>
            )}
          </div>
        </Item>
        <Item isActive={networkPlatform}>
          <p>Network Platform</p>
          <div>
            {networkPlatform ? (
              <>
                <BsCheck size={22} color="var(--primary)" />
              </>
            ) : (
              <>
                <AiOutlineClose size={16} color="#A7ABBB" />
              </>
            )}
          </div>
        </Item>
        <Item isActive={unlimitedCloud}>
          <p>Unlimited Cloud Space</p>
          <div>
            {unlimitedCloud ? (
              <>
                <BsCheck size={22} color="var(--primary)" />
              </>
            ) : (
              <>
                <AiOutlineClose size={16} color="#A7ABBB" />
              </>
            )}
          </div>
        </Item>
      </ItemArea>
      {isActive ? (
        <ActualPlanText>PLANO ATUAL</ActualPlanText>
      ) : (
        <SelectButton>Select</SelectButton>
      )}
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f6f8fa;

  padding-top: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 52px;

  @media (max-width: 815px) {
    flex-direction: column;
  }
`

export const Title = styled.h1`
  color: #343333;
  font-size: 20px;

  @media (max-width: 400px) {
    width: 280px;
    text-align: center;
  }
`

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 42px;

  @media (max-width: 815px) {
    flex-direction: column;
    gap: 0;
  }
`

export const Search = styled.div`
  background-color: rgba(224, 236, 232, 0.51);
  border-radius: 4px;

  display: flex;
  align-items: center;
  height: 40px;
  width: 360px;

  input {
    padding: 0 12px;
    width: 90%;
    height: 100%;
  }

  @media (max-width: 815px) {
    margin: 12px 0;
  }

  @media (max-width: 400px) {
    width: 280px;
  }
`

export const NewButton = styled.button`
  background: var(--primary);
  width: 157px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 4px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 32px;
  justify-content: space-between;
  padding: 22px 52px;

  @media (max-width: 1175px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 820px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 565px) {
    grid-template-columns: 1fr;
    padding: 22px;
  }
`

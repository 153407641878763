import React, { FormEvent, useState } from 'react'
import { Footer } from '../../components/Footer'
import { useUser } from '../../contexts/UserContext'
import {
  Container,
  Description,
  Grid,
  Input,
  InputItem,
  InputTitle,
  InputTitleArea,
  SubmitButton,
  Title,
  Tooltip,
  Wrapper
} from './style'

import { AnimatePresence } from 'framer-motion'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Header } from '../../components/Header'
import { ModalWelcome } from '../../components/ModalWelcome'
import { useModal } from '../../contexts/ModalContext'
import AdmApi from '../../services/AdmApi'
import { useAlert } from 'react-alert'

export const RegisterNewUser: React.FC = () => {
  const { key, setAdmHeaderInfo, setHaveClients } = useUser()
  const alert = useAlert()
  const { welcomeModalIsOpen } = useModal()

  const [seg, setSeg] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [doc, setDoc] = useState('')
  const [razao, setRazao] = useState('')
  const [nome, setNome] = useState('')

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (seg === '' || razao === '') {
      return alert.show(
        'Preencha os campos Segmento e Razão Social para cadastrar seu pagador'
      )
    }

    const data: App.FirstClient = {
      key: localStorage.getItem('@seven_key')!,
      seg,
      email,
      tel,
      doc,
      razao,
      nome
    }

    AdmApi.create_client(data)
      .then(() => {
        AdmApi.header_info(key).then((res) => {
          setAdmHeaderInfo(res.data.header)
          if (res.data.header.num_clientes > 0) {
            setHaveClients(true)
          }
          localStorage.setItem('@seven_haveClients', 'true')
        })
      })
      .catch((e) => console.log(e))
  }

  return (
    <Container>
      <Header showHalfHeader={true} />
      <AnimatePresence key="welcome">
        {welcomeModalIsOpen && <ModalWelcome />}
      </AnimatePresence>
      <Wrapper>
        <Title>
          Vamos cadastrar seu <span>primeiro pagador?</span>{' '}
        </Title>
        <Description>
          Para poder utilizar o sistema você precisa cadastrar ao menos um pagador.
          Insira os dados básicos para iniciar o cadastro
        </Description>
        <Grid onSubmit={handleSubmit}>
          <InputItem>
            <InputTitleArea>
              <InputTitle>Segmento</InputTitle>
              <span>*</span>
              <Tooltip
                data-tip="Preencha o tipo de serviço que o seu pagador presta ou
qual categoria ele ocupa no mercado de trabalho"
              >
                <AiOutlineQuestionCircle className="icon" size={20} />
                <ReactTooltip
                  place="bottom"
                  backgroundColor="#2D3B36"
                  effect="solid"
                />
              </Tooltip>
            </InputTitleArea>
            <Input
              value={seg}
              onChange={(e) => setSeg(e.target.value)}
              placeholder="Pesquisar"
            />
          </InputItem>
          <InputItem>
            <InputTitleArea>
              <InputTitle>E-mail</InputTitle>
            </InputTitleArea>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Digite aqui..."
            />
          </InputItem>
          <InputItem>
            <InputTitleArea>
              <InputTitle>Telefone</InputTitle>
            </InputTitleArea>
            <Input
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              placeholder="Digite aqui..."
            />
          </InputItem>
          <InputItem>
            <InputTitleArea>
              <InputTitle>CPF/CNPJ</InputTitle>
            </InputTitleArea>
            <Input
              value={doc}
              onChange={(e) => setDoc(e.target.value)}
              placeholder="Digite aqui..."
            />
          </InputItem>
          <InputItem>
            <InputTitleArea>
              <InputTitle>Razão Social</InputTitle>
              <span>*</span>
            </InputTitleArea>
            <Input
              value={razao}
              onChange={(e) => setRazao(e.target.value)}
              placeholder="Digite aqui..."
            />
          </InputItem>
          <InputItem>
            <InputTitleArea>
              <InputTitle>Nome Fantasia</InputTitle>
            </InputTitleArea>
            <Input
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              placeholder="Digite aqui..."
            />
          </InputItem>
          <SubmitButton type="submit">Cadastrar</SubmitButton>
        </Grid>
      </Wrapper>
      <Footer />
    </Container>
  )
}

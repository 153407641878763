import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { BsPlus, BsSearch } from 'react-icons/bs'
import { ClientCard } from '../../components/ClientCard'
import { ModalEditClient } from '../../components/ModalEditClient'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmApi from '../../services/AdmApi'
import {
  Container,
  Header,
  NewClientButton,
  RightContent,
  Search,
  Title,
  Wrapper
} from './style'

interface ClientsProps {
  id: number
  email: string
  tel: string
  nome: string
  img: string
  tipo_img: string
}

export const ADMClients: React.FC = () => {
  const { key } = useUser()
  const { editClientModalIsOpen, openCreateClientModal, updateList } = useModal()

  const [clients, setClients] = useState<ClientsProps[]>()
  const [search, setSearch] = useState('')

  const getData = async () => {
    await AdmApi.list_clients(key).then((res) => setClients(res.data.Clientes))
  }

  useEffect(() => {
    getData()
  }, [updateList])

  return (
    <Container>
      <AnimatePresence>
        {editClientModalIsOpen && <ModalEditClient />}
      </AnimatePresence>
      <Header>
        <Title>Clientes Cadastrados ({clients?.length})</Title>
        <RightContent>
          <Search>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              placeholder="Pesquisar"
            />
            <BsSearch size={22} fill="var(--primary)" />
          </Search>
          <NewClientButton onClick={openCreateClientModal}>
            <BsPlus size={22} fill="#fff" />
            Novo cliente
          </NewClientButton>
        </RightContent>
      </Header>
      <Wrapper>
        {clients
          // eslint-disable-next-line array-callback-return
          ?.filter((values) => {
            if (search === '') {
              return values
            } else if (values.nome?.toLowerCase().includes(search.toLowerCase())) {
              return values
            }
          })
          .map((client) => {
            return (
              <ClientCard
                id={client.id}
                key={client.id}
                nome={client.nome}
                tel={client.tel}
                email={client.email}
                img={client.img}
                tipo_img={client.tipo_img}
              />
            )
          })}
      </Wrapper>
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* background: #f3f5f2; */
  padding: 12px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
  padding-left: 18px;

  position: relative;
`

export const Line = styled.div`
  background: #f3f5f2;
  width: 4px;
  height: 120%;

  position: absolute;
  left: -5px;
  top: 16px;
`

export const Icon = styled.div`
  background: #fff;
  border: 2px solid #f3f5f2;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: -13px;
`

export const Title = styled.h1`
  font-size: 18px;

  span {
    font-size: 12px;
    color: #ccc;
  }
`

export const Content = styled.p`
  font-size: 15px;
  max-width: 90%;
`

export const ButtonDelete = styled.button`
  position: absolute;
  right: 15px;
  top: 50%;

  transform: translate(0, -50%);

  transition: filter;

  &:hover {
    filter: brightness(0.8);
  }
`

import { AxiosError } from 'axios'
import moment from 'moment'
import React, { FormEvent, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  AiOutlineCloudUpload,
  AiOutlineDelete,
  AiOutlineQuestionCircle
} from 'react-icons/ai'
import { BsCalendar4Week } from 'react-icons/bs'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import { useModal } from '../../../../contexts/ModalContext'
import { useUser } from '../../../../contexts/UserContext'
import AdmInUserApi from '../../../../services/AdmInUserApi'
import IndividualApi from '../../../../services/IndividualApi'
import { CalendarPicker } from '../../../CalendarPicker'
import {
  InputTitleArea,
  InputTitleAreaWithButton,
  Tooltip
} from '../../../ModalCategory/style'
import { InputTitle } from '../../../ModalEditClient/style'
import {
  ButtonDelete,
  ButtonViewFile,
  DateLine,
  Form,
  Grid,
  Input,
  InputImage,
  InputImageArea,
  InputImageLabel,
  InputItem,
  SubmitButton,
  SubmitButtonArea
} from './style'
import CurrencyFormat from 'react-currency-format'
import CurrencyInput from 'react-currency-input-field'
import { formatBRL } from '../../../../utils/formatBRL'

interface ClientsProps {
  id: number
  nome: string
  tel: string
  email: string
  desc: string
}

interface ProviderrProps {
  id: number
  nome: string
  tel: string
  email: string
  desc: string
}

interface AccountProps {
  id: number
  nome: string
  banco: string
  agencia: string
  conta: string
  tipo: string
  desc: string
}

interface CategoryProps {
  id: number
  nome: string
  desc: string
}

interface FormSingleProps {
  type: 'Entrada' | 'Saida'
  isEdit?: boolean
  id?: string
}

interface ProductProps {
  id: number
  nome: string
  preco: string
  categoria: string
  sku: string
  obs: string
}

export const FormSingle: React.FC<FormSingleProps> = ({ type, isEdit, id }) => {
  const initialData: any = type

  const alert = useAlert()
  const { key, reloadGraphics, admInUserId, admInUser } = useUser()
  const {
    openSupportModal,
    closePaymentModal,
    openPersonModal,
    personModalIsOpen,
    openCategoryModal,
    openNewBankAccountModal,
    categoryModalIsOpen,
    newBankAccountModalIsOpen,
    closeEditPaymentModal,
    openProductModal,
    productModalIsOpen
  } = useModal()

  const [tipo, setTipo] = useState('Único')
  const [dataPrev, setDataPrev] = useState('')
  const [dataPag, setDataPag] = useState('')
  const [cadastro, setCadastro] = useState(initialData)
  const [valor, setValor] = useState('')
  const [tipoPag, setTipoPag] = useState('Dinheiro')
  const [respPag, setRespPag] = useState('Receber de')
  const [respNome, setRespNome] = useState('')
  const [projeto, setProjeto] = useState('')
  const [conta, setConta] = useState('')
  const [historico, setHistorico] = useState('')
  const [categoria, setCategoria] = useState('')
  const [status, setStatus] = useState('Em dia')
  const [arquivo, setArquivo] = useState<any>()

  const [clientList, setClientList] = useState<ClientsProps[]>([])
  const [providerList, setProviderList] = useState<ProviderrProps[]>([])
  const [categoryList, setCategoryList] = useState<CategoryProps[]>([])
  const [accountList, setAccountList] = useState<AccountProps[]>([])
  const [productList, setProductList] = useState<ProductProps[]>([])

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.list_clients(key).then((res) => {
        setClientList(res.data.clientes)
      })
      IndividualApi.list_forn(key).then((res) => {
        setProviderList(res.data.fornecedores)
      })
      IndividualApi.list_accounts(key).then((res) => {
        setAccountList(res.data.contas)
      })
      IndividualApi.list_categories(key).then((res) => {
        setCategoryList(res.data.categorias)
      })
      IndividualApi.list_products(key).then((res) => {
        setProductList(res.data.produtos)
      })
    } else {
      AdmInUserApi.list_clients(key, admInUserId).then((res) => {
        setClientList(res.data.clientes)
      })
      AdmInUserApi.list_forn(key, admInUserId).then((res) => {
        setProviderList(res.data.fornecedores)
      })
      AdmInUserApi.list_accounts(key, admInUserId).then((res) => {
        setAccountList(res.data.contas)
      })
      AdmInUserApi.list_categories(key, admInUserId).then((res) => {
        setCategoryList(res.data.categorias)
      })
      AdmInUserApi.list_products(key, admInUserId).then((res) => {
        setProductList(res.data.produtos)
      })
    }
  }, [
    personModalIsOpen,
    categoryModalIsOpen,
    newBankAccountModalIsOpen,
    productModalIsOpen
  ])

  useEffect(() => {
    if (isEdit) {
      if (!admInUser) {
        IndividualApi.lancamento(key, id!).then((res) => {
          const newDate = res.data.data_prev
            .replace('/', '-')
            .replace('/', '-')
            .split('-')
          const newDatePag = res.data.data_pag
            .replace('/', '-')
            .replace('/', '-')
            .split('-')

          const formatedDatePag = `${newDatePag[2]}-${newDatePag[1]}-${newDatePag[0]}`
          const formatedDate = `${newDate[2]}-${newDate[1]}-${newDate[0]}`

          setCadastro(res.data.cadastro)
          setTipo(res.data.tipo)
          setDataPrev(formatedDate)
          setDataPag(formatedDatePag)
          setValor(res.data.valor.replace('.', ','))
          setTipoPag(res.data.tipo_pag)
          setRespNome(res.data.resp_pag)
          setRespNome(res.data.resp_nome)
          setProjeto(res.data.projeto)
          setHistorico(res.data.historico)
          setCategoria(res.data.categoria)
          setStatus(res.data.status)
          setConta(res.data.conta)
          setArquivo(res.data.arquivo)
        })
      } else {
        AdmInUserApi.lancamento(key, id!, admInUserId).then((res) => {
          const newDate = res.data.data_prev
            .replace('/', '-')
            .replace('/', '-')
            .split('-')

          const newDatePag = res.data.data_pag
            .replace('/', '-')
            .replace('/', '-')
            .split('-')

          const formatedDatePag = `${newDatePag[2]}-${newDatePag[1]}-${newDatePag[0]}`

          const formatedDate = `${newDate[2]}-${newDate[1]}-${newDate[0]}`

          setCadastro(res.data.cadastro)
          setTipo(res.data.tipo)
          setDataPrev(formatedDate)
          setDataPag(formatedDatePag)
          setValor(formatBRL(res.data.valor))
          setTipoPag(res.data.tipo_pag)
          setRespNome(res.data.resp_pag)
          setRespNome(res.data.resp_nome)
          setProjeto(res.data.projeto)
          setHistorico(res.data.historico)
          setCategoria(res.data.categoria)
          setStatus(res.data.status)
          setConta(res.data.conta)
          setArquivo(res.data.arquivo)
        })
      }
    }
  }, [])

  const formatString = (value: string) => {
    const hasDot = value.includes('.')

    if (!hasDot || value.split('.')[1].length > 2) {
      return parseFloat(value).toFixed(2)
    }

    if (value.split('.')[1].length === 1) {
      return value
    }

    return parseFloat(value).toFixed(2)
  }

  const data = {
    key: key,
    tipo,
    data_prev: dataPrev,
    data_pag: dataPag,
    cadastro: cadastro,
    valor: valor.replace('R$', '').replace(/\./g, '').replace(',', '.'),
    tipo_pag: tipoPag,
    resp_pag: type === 'Entrada' ? 'Cliente' : 'Fornecedor',
    resp_nome: respNome,
    projeto: projeto,
    historico,
    categoria,
    conta,
    status,
    arquivo,
    data_registro: moment().format('YYYY-MM-DD')
  }

  const clean = () => {
    setTipo('Único')
    setDataPrev('')
    setCadastro('Entrada')
    setValor('')
    setTipoPag('Dinheiro')
    setRespPag('Receber de')
    setRespNome('')
    setProjeto('')
    setHistorico('')
    setCategoria('Categoria 1')
    setStatus('Em dia')
    setArquivo(undefined)
  }

  const deletePayment = () => {
    if (!admInUser) {
      IndividualApi.del_lancamento(key, id!).then((res) => {
        clean()
        closeEditPaymentModal()
        alert.show(res.data.text)
      })
    } else {
      AdmInUserApi.del_lancamento(key, id!, admInUserId).then((res) => {
        clean()
        closeEditPaymentModal()
        alert.show(res.data.text)
      })
    }
  }

  const handleDrop = (e: any) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]

    // Verifique se o tipo de arquivo é válido com base na extensão
    if (file) {
      const fileExtension = file.name.split('.').pop()
      const allowedExtensions = ['png', 'jpg', 'jpeg', 'pdf']

      if (allowedExtensions.includes(fileExtension.toLowerCase())) {
        setArquivo(file)
      } else {
        alert.show('Por favor, selecione um arquivo PNG, JPG, JPEG ou PDF válido.')
      }
    }
  }

  const handleDragOver = (e: any) => {
    e.preventDefault()
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (dataPrev !== '' && valor !== '') {
      if (!isEdit) {
        if (!admInUser) {
          IndividualApi.new_entry(data)
            .then((res) => {
              alert.show(res.data.text)
              clean()
              closePaymentModal()
            })
            .catch((error: AxiosError) => alert.show(error.response?.data.error))
            .finally(() => reloadGraphics())
        } else {
          AdmInUserApi.new_entry(data, admInUserId)
            .then((res) => {
              alert.show(res.data.text)
              clean()
              closePaymentModal()
            })
            .catch((error: AxiosError) => alert.show(error.response?.data.error))
            .finally(() => reloadGraphics())
        }
      } else {
        if (!admInUser) {
          IndividualApi.update_lancamento(data, id!)
            .then((res) => {
              alert.show(res.data.text)
              clean()
              closeEditPaymentModal()
            })
            .catch((error) => alert.show(error.response?.data.error))
            .finally(() => reloadGraphics())
        } else {
          AdmInUserApi.update_lancamento(data, id!, admInUserId)
            .then((res) => {
              alert.show(res.data.text)
              clean()
              closeEditPaymentModal()
            })
            .catch((error) => alert.show(error.response?.data.error))
            .finally(() => reloadGraphics())
        }
      }
    } else {
      alert.show('Preencha todos os campos')
    }
  }

  const paymentOptions = [
    { value: 'Débito', label: 'Débito' },
    { value: 'Crédito', label: 'Crédito' },
    { value: 'Dinheiro', label: 'Dinheiro' },
    { value: 'Pix', label: 'Pix' },
    { value: 'Boleto', label: 'Boleto' },
    { value: 'Transferência', label: 'Transferência' }
  ]

  const clientsOptions = clientList?.map((cliente) => {
    return { value: cliente.nome, label: cliente.nome }
  })

  const providerOptions = providerList?.map((provider) => {
    return { value: provider.nome, label: provider.nome }
  })

  const categoriesOptions = categoryList?.map((category) => {
    return { value: category.nome, label: category.nome }
  })

  const preCategoriesOptions = [
    { value: 'Livros', label: 'Livros' },
    { value: 'Material escolar', label: 'Material escolar' },
    { value: 'Aluguel', label: 'Aluguel' },
    { value: 'Conta de luz', label: 'Conta de luz' },
    { value: 'Consulta médica', label: 'Consulta médica' },
    { value: 'Medicamento', label: 'Medicamento' },
    { value: 'Plano de saúde', label: 'Plano de saúde' },
    { value: 'Academia', label: 'Academia' },
    { value: 'Auxiliar doméstica', label: 'Auxiliar doméstica' },
    { value: 'Supermercado', label: 'Supermercado' },
    { value: '', label: '' }
  ]

  const finalCategoriesOptions = [...categoriesOptions, ...preCategoriesOptions]

  const accountOptions = accountList?.map((account) => {
    return { value: account.nome, label: account.nome }
  })

  const productOptions = productList?.map((product) => {
    return { value: product.nome, label: product.nome }
  })

  const preProductOptions = [
    { value: 'Educação', label: 'Educação' },
    { value: 'Saúde', label: 'Saúde' },
    { value: 'Pessoal', label: 'Pessoal' },
    { value: 'Doméstico', label: 'Doméstico' },
    { value: 'Investimento', label: 'Investimento' },
    { value: 'Profissional', label: 'Profissional' },
    { value: '', label: '' }
  ]

  const finalProductOptions = [...productOptions, ...preProductOptions]

  const statusOptions = [
    { value: 'Atrasado', label: 'Atrasado' },
    { value: 'Em dia', label: 'Em dia' },
    { value: 'Pago', label: 'Pago' }
  ]

  const customSelectStyles = {
    container: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#ffffff'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    control: (provided: any) => ({
      ...provided,
      background: 'transparent',
      border: 'none',
      color: '#768882'
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Grid>
        <InputItem
          style={{
            borderBottomColor: dataPrev.length > 0 ? 'var(--primary)' : '#DF3347'
          }}
        >
          <InputTitleArea>
            <InputTitle>Data prevista</InputTitle>
            <span>*</span>
          </InputTitleArea>
          <input
            type="date"
            value={dataPrev}
            onChange={(e) => setDataPrev(e.target.value)}
          />
        </InputItem>
        <InputItem>
          <InputTitleArea>
            <InputTitle>Data do pagamento</InputTitle>
          </InputTitleArea>
          <input
            type="date"
            value={dataPag}
            onChange={(e) => setDataPag(e.target.value)}
          />
        </InputItem>
        <InputItem
          style={{
            borderBottomColor: valor.length > 0 ? 'var(--primary)' : '#DF3347'
          }}
        >
          <InputTitleArea>
            <InputTitle>Valor total</InputTitle>
            <span>*</span>
            {/* <Tooltip data-tip="Os centavos são informados a partir de um . (200.00, 300.50)">
              <AiOutlineQuestionCircle className="icon" size={16} />
              <ReactTooltip
                place="bottom"
                backgroundColor="#2D3B36"
                effect="solid"
              />
            </Tooltip> */}
          </InputTitleArea>
          <CurrencyFormat
            value={valor}
            fixedDecimalScal={true}
            onChange={(e: any) => setValor(e.target.value)}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale={true}
            inputMode="numeric"
          />
        </InputItem>
        <InputItem>
          <InputTitleAreaWithButton>
            <InputTitle>
              Forma de {type === 'Entrada' ? 'recebimento' : 'pagamento'}
            </InputTitle>
          </InputTitleAreaWithButton>
          <div>
            <Select
              placeholder="Dinheiro"
              styles={customSelectStyles}
              value={{ label: tipoPag, value: tipoPag }}
              onChange={(e) => {
                setTipoPag(e!.value)
              }}
              options={paymentOptions.concat({ value: '', label: '' })}
            />
          </div>
        </InputItem>
        <InputItem>
          <InputTitleAreaWithButton>
            {type === 'Entrada' ? (
              <>
                <InputTitle>Receber de</InputTitle>
                <button onClick={() => openPersonModal('client')} type="button">
                  criar
                </button>
              </>
            ) : (
              <>
                <InputTitle>Pagar a</InputTitle>
                <button onClick={() => openPersonModal('provider')} type="button">
                  criar
                </button>
              </>
            )}
          </InputTitleAreaWithButton>
          <div>
            {type === 'Entrada' ? (
              <>
                <Select
                  placeholder="Escolha um cliente"
                  noOptionsMessage={() => 'Nenhum cliente cadastrado'}
                  styles={customSelectStyles}
                  value={{ label: respNome, value: respNome }}
                  onChange={(e) => {
                    setRespNome(e!.value)
                  }}
                  options={clientsOptions.concat({ value: '', label: '' })}
                />
              </>
            ) : (
              <>
                <Select
                  placeholder="Escolha um fornecedor"
                  noOptionsMessage={() => 'Nenhum fornecedor cadastrado'}
                  styles={customSelectStyles}
                  value={{ label: respNome, value: respNome }}
                  onChange={(e) => {
                    setRespNome(e!.value)
                  }}
                  options={providerOptions.concat({ value: '', label: '' })}
                />
              </>
            )}
          </div>
        </InputItem>
        {/* {respPag === 'Receber de' && (
          <InputItem>
            <InputTitleAreaWithButton>
              <InputTitle>Receber de</InputTitle>
              <button onClick={() => openPersonModal('client')} type="button">
                criar
              </button>
            </InputTitleAreaWithButton>
            <div>
              <Select
                placeholder="Escolha um cliente"
                styles={customSelectStyles}
                value={{ label: respNome, value: respNome }}
                onChange={(e) => {
                  setRespNome(e!.value)
                }}
                options={clientsOptions}
              />
            </div>
          </InputItem>
        )}
        {respPag === 'Pagar a' && (
          <InputItem>
            <InputTitleAreaWithButton>
              <InputTitle>Pagar a</InputTitle>
              <button onClick={() => openPersonModal('provider')} type="button">
                criar
              </button>
            </InputTitleAreaWithButton>
            <div>
              <Select
                placeholder="Escolha um fornecedor"
                styles={customSelectStyles}
                value={{ label: respNome, value: respNome }}
                onChange={(e) => {
                  setRespNome(e!.value)
                }}
                options={providerOptions}
              />
            </div>
          </InputItem>
        )} */}
        <InputItem>
          <InputTitleAreaWithButton>
            <InputTitle>Centro de custo</InputTitle>
            <button onClick={openProductModal} type="button">
              criar
            </button>
          </InputTitleAreaWithButton>
          <div>
            <Select
              placeholder="Escolha um centro de custo"
              noOptionsMessage={() => 'Nenhum centro de custo cadastrado'}
              styles={customSelectStyles}
              value={{ label: projeto, value: projeto }}
              onChange={(e) => {
                setProjeto(e!.value)
              }}
              options={finalProductOptions}
            />
          </div>
        </InputItem>
        <InputItem>
          <InputTitleAreaWithButton>
            <InputTitle>Conta bancária</InputTitle>
            <button onClick={openNewBankAccountModal} type="button">
              criar
            </button>
          </InputTitleAreaWithButton>
          <div>
            <Select
              placeholder="Escolha uma conta bancária"
              styles={customSelectStyles}
              noOptionsMessage={() => 'Nenhuma conta bancária cadastrada'}
              value={{ label: conta, value: conta }}
              onChange={(e) => {
                setConta(e!.value)
              }}
              options={accountOptions.concat({ value: '', label: '' })}
            />
          </div>
        </InputItem>
        <InputItem>
          <InputTitleAreaWithButton>
            <InputTitle>Categoria</InputTitle>
            <button onClick={openCategoryModal} type="button">
              criar
            </button>
          </InputTitleAreaWithButton>
          <div>
            <Select
              placeholder="Escolha uma categoria"
              noOptionsMessage={() => 'Nenhuma categoria cadastrada'}
              styles={customSelectStyles}
              value={{ label: categoria, value: categoria }}
              onChange={(e) => {
                setCategoria(e!.value)
              }}
              options={finalCategoriesOptions}
            />
          </div>
        </InputItem>
        <InputItem>
          <InputTitleArea>
            <InputTitle>Status</InputTitle>
          </InputTitleArea>
          <Select
            placeholder="Em dia"
            styles={customSelectStyles}
            value={{ label: status, value: status }}
            onChange={(e) => {
              setStatus(e!.value)
            }}
            options={statusOptions}
          />
        </InputItem>
        <InputItem>
          <InputTitleArea>
            <InputTitle>Observação</InputTitle>
          </InputTitleArea>
          <Input
            onChange={(e) => setHistorico(e.target.value)}
            value={historico}
            placeholder="Digitar..."
          />
        </InputItem>
      </Grid>
      {arquivo !== 'arquivo.pdf' && isEdit && (
        <ButtonViewFile target="_blank" href={arquivo!}>
          Ver documento
        </ButtonViewFile>
      )}
      <InputImageArea onDrop={handleDrop} onDragOver={handleDragOver}>
        <InputTitleArea>
          <InputTitle>Anexo</InputTitle>
          <Tooltip data-tip="Anexe contratos, propostas comerciais, notas fiscais ou comprovantes de pagamento">
            <AiOutlineQuestionCircle className="icon" size={16} />
            <ReactTooltip place="bottom" backgroundColor="#2D3B36" effect="solid" />
          </Tooltip>
          <p style={{ marginLeft: '4px' }}>{arquivo && arquivo.name}</p>
        </InputTitleArea>
        <InputImageLabel htmlFor="file">
          <AiOutlineCloudUpload size={24} fill="var(--primary)" />
          Clique ou solte o arquivo aqui
        </InputImageLabel>
        <InputImage
          onChange={(e) => setArquivo(e!.target!.files![0])}
          id="file"
          name="file"
          type="file"
          accept=".png, .jpg, .jpeg, .pdf"
        />
      </InputImageArea>
      <SubmitButtonArea isEdit={isEdit}>
        {isEdit && (
          <ButtonDelete type="button" onClick={deletePayment}>
            <AiOutlineDelete size={22} fill="#fff" />
          </ButtonDelete>
        )}
        <SubmitButton type="submit">
          {isEdit ? 'Salvar' : 'Finalizar Cadastro'}
        </SubmitButton>
      </SubmitButtonArea>
    </Form>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  background: #f6f8fa;
  padding: 42px 52px;
  gap: 42px;
  width: 100%;
  height: 100%;
  background: #f6f8fa;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 1120px) {
    flex-direction: column;
  }

  @media (max-width: 640px) {
    padding: 42px 0;
  }
`

interface HeaderProps {
  hasBottomBorder: boolean
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  flex-direction: column;
  padding: 22px 0;
  gap: 12px;
  margin-bottom: 12px;
  border-bottom: ${(props) =>
    props.hasBottomBorder ? '1px solid rgba(55,55,57,0.1)' : 'none'};
`

export const DataHeader = styled.div<HeaderProps>`
  display: flex;
  flex-direction: column;
  padding: 22px 0;
  gap: 12px;
  margin-bottom: 12px;
  border-bottom: ${(props) =>
    props.hasBottomBorder ? '1px solid rgba(55,55,57,0.1)' : 'none'};
`

export const ImageArea = styled.div`
  margin-right: 18px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  input {
    display: none;
  }

  label {
    font-size: 12px;
    border: 1px solid var(--primary);
    padding: 4px;
    border-radius: 4px;
    background: var(--primary);
    cursor: pointer;
    color: #fff;
    font-weight: 500;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 40%;
  height: 500px;

  border-radius: 11px;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.35);

  @media (max-width: 1120px) {
    width: 100%;
  }
`

export const Title = styled.p`
  color: #343333;
  font-size: 18px;
  font-weight: bold;
  padding: 0 22px;
`

export const SubTitle = styled.p`
  color: #7f7f7f;
  font-size: 14px;
  padding: 0 22px;
`

export const Form = styled.form`
  display: grid;
  grid-template-columns: 20% 20% auto;
  grid-template-rows: auto;
  gap: 22px;
  padding: 18px 22px;

  @media (max-width: 415px) {
    padding: 18px 12px;
  }
`

interface InputItemProps {
  isDisabled?: boolean
}

export const InputItem = styled.div<InputItemProps>`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.isDisabled ? '0.5' : '')};
`

export const InputTitle = styled.p`
  color: #7f7f7f;
  font-size: 13px;
`

export const Input = styled.input`
  padding: 8px 0;
  border-bottom: 1px solid #d7d7d7;
`

export const SubmitButton = styled.button`
  width: 140px;
  height: 40px;
  border: 1px solid var(--primary);
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 6px;
  justify-self: flex-end;

  transition: background 0.2s;

  &:hover {
    background: #138059;
  }
`

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  @media (max-width: 1120px) {
    width: 100%;
  }

  @media (max-width: 715px) {
    flex-direction: column;
    height: auto;
  }
`

export const Right = styled.div`
  background: #fff;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 22px;
  padding: 18px 22px;

  border-radius: 11px;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.35);

  @media (max-width: 415px) {
    padding: 18px 12px;
  }
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 1120px) {
    width: 100%;
  }
`

export const RightTitle = styled.p`
  color: #343333;
  font-size: 18px;
  font-weight: bold;
  padding: 0 22px;

  @media (max-width: 1290px) {
    padding: 0;
    width: 300px;
  }

  @media (max-width: 1120px) {
    text-align: center;
    width: 100%;
  }
`

export const RightSubTitle = styled.p`
  color: #7f7f7f;
  font-size: 14px;
  padding: 0 22px;

  @media (max-width: 1290px) {
    padding: 0;
  }

  @media (max-width: 1120px) {
    text-align: center;
  }
`

export const Graphic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Number = styled.p`
  color: var(--primary);
  span {
    font-weight: bold;
    font-size: 24px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;

  p:nth-child(1) {
    color: #7f7f7f;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 22px;
  }

  p {
    color: #aaaaaa;
    font-size: 12px;
    text-align: start;
  }
`

export const UpgradeButton = styled.button`
  width: 181px;
  height: 40px;
  background: var(--primary);
  color: #fff;
  border-radius: 4px;
  margin-top: 22px;
  align-self: center;

  color: #fff;
  text-decoration: none;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const Banner = styled.img`
  width: 338px;
  height: 338px;
`

export const RightInfo = styled.p`
  color: #7f7f7f;
  font-size: 14px;
  margin-top: 22px;
  text-align: center;
  font-weight: 500;
`

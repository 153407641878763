import React, { useEffect, useState } from 'react'
import { BsPlus, BsSearch } from 'react-icons/bs'
import { IndividualContainer } from '../../components/IndividualContainer'
import { ListCard } from '../../components/ListCard'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmInUserApi from '../../services/AdmInUserApi'
import IndividualApi from '../../services/IndividualApi'
import {
  Container,
  Header,
  NewButton,
  RightContent,
  Search,
  Title,
  Wrapper
} from './style'

interface ProductsProps {
  id: number
  nome: string
  preco: string
  sku: string
  obs: string
}

export const ListProducts: React.FC = () => {
  const { openProductModal, updateList } = useModal()
  const { key, admInUser, admInUserId } = useUser()

  const [products, setProducts] = useState<ProductsProps[]>()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.list_products(key).then((res) => setProducts(res.data.produtos))
    } else {
      AdmInUserApi.list_products(key, admInUserId).then((res) =>
        setProducts(res.data.produtos)
      )
    }
  }, [updateList])

  return (
    <IndividualContainer>
      <Container>
        <Header>
          <Title>Centros de custo cadastrados ({products?.length})</Title>
          <RightContent>
            <Search>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Pesquisar"
              />
              <BsSearch size={22} fill="var(--primary)" />
            </Search>
            <NewButton onClick={openProductModal}>
              <BsPlus size={22} fill="#fff" />
              Novo Centro de custo
            </NewButton>
          </RightContent>
        </Header>
        <Wrapper>
          {products
            // eslint-disable-next-line array-callback-return
            ?.filter((item) => {
              if (search === '') {
                return item
              } else if (item.nome.toLowerCase().includes(search.toLowerCase())) {
                return item
              }
            })
            .map((product) => {
              return (
                <ListCard
                  type="product"
                  id={String(product.id)}
                  key={product.id}
                  productData={{
                    nome: product.nome,
                    preco: product.preco,
                    sku: product.sku,
                    obs: product.obs
                  }}
                />
              )
            })}
        </Wrapper>
      </Container>
    </IndividualContainer>
  )
}

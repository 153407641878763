import md5 from 'md5'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { BiKey, BiUserCircle } from 'react-icons/bi'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import AdmApi from '../../services/AdmApi'
import AuthApi from '../../services/AuthApi'
import IndividualApi from '../../services/IndividualApi'
import {
  Banner,
  ButtonArea,
  Container,
  Content,
  ForgotPasswordButton,
  Form,
  IconArea,
  Input,
  Item,
  Line,
  LinkButton,
  Logo,
  PasswordButton,
  SubmitButton,
  Title
} from './style'

export const Login: React.FC = () => {
  const {
    setStatus,
    setPlan,
    setKey,
    setHasUser,
    setHaveClients,
    setAdmHeaderInfo,
    setIndividualHeaderInfo
  } = useUser()
  const alert = useAlert()
  const history = useHistory()
  const location = useLocation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [encryptedPassword, setEncryptedPassword] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [passwordIsHide, setPasswordIsHide] = useState(true)
  const [isForgot, setIsForgot] = useState(false)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (!isForgot) {
      if (email !== '' && password !== '') {
        AuthApi.login(email, encryptedPassword)
          .then((res) => {
            localStorage.setItem('@seven_key', res.data.key)
            localStorage.setItem('@seven_plan', res.data.plano)
            if (res.data.clientes) {
              localStorage.setItem('@seven_haveClients', 'true')
            } else {
              localStorage.setItem('@seven_haveClients', 'false')
            }
            setKey(res.data.key)
            setStatus(res.data.status)
            setPlan(res.data.plano)
            setHaveClients(res.data.clientes)
            setHasUser(true)
            if (res.data.plano === 'administrativo') {
              AdmApi.header_info(res.data.key).then((res) =>
                setAdmHeaderInfo(res.data.header)
              )
              history.push('/adm-dashboard')
            } else {
              IndividualApi.header_info(res.data.key).then((res) =>
                setIndividualHeaderInfo(res.data)
              )
              history.push('/dashboard')
            }
          })
          .catch(() => alert.show('Usuário ou senha incorretos.'))
      } else {
        alert.show('Preencha todos os campos')
      }
    } else {
      if (email === '') {
        return alert.show('Preencha o campo email')
      }

      AuthApi.sendEmail(email)
        .then(() => {
          alert.show('Foi enviado uma mensagem para seu email')
        })
        .catch((e: any) => {
          alert.show(e.response.data.error)
        })
    }
  }

  useEffect(() => {
    setEncryptedPassword(md5(password))
  }, [password])

  return (
    <Container>
      <Banner />
      <Content>
        <Logo src="./assets/logo.png" />
        <Title>Faça seu Login</Title>
        <Form onSubmit={handleSubmit}>
          {!isForgot ? (
            <>
              <Item>
                <div>
                  <IconArea>
                    <BiUserCircle size={22} fill="#7d91a4" />
                  </IconArea>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Insira seu email"
                  />
                </div>
              </Item>
              <Item>
                <div>
                  <IconArea>
                    <BiKey size={22} fill="#7d91a4" />
                  </IconArea>
                  <Input
                    type={passwordIsHide ? 'password' : ''}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Insira sua senha"
                  />
                </div>
                <PasswordButton
                  type="button"
                  onClick={() => setPasswordIsHide(!passwordIsHide)}
                >
                  {passwordIsHide ? (
                    <BsFillEyeFill size={22} fill="#aaa" />
                  ) : (
                    <BsFillEyeSlashFill size={22} fill="#aaa" />
                  )}
                </PasswordButton>
              </Item>
            </>
          ) : (
            <>
              <Item>
                <div>
                  <IconArea>
                    <BiUserCircle size={22} fill="#7d91a4" />
                  </IconArea>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Insira o email de recuperação"
                  />
                </div>
              </Item>
            </>
          )}

          <ButtonArea>
            {!isForgot ? (
              <>
                <ForgotPasswordButton
                  onClick={() => setIsForgot(true)}
                  type="button"
                >
                  Perdeu a senha?
                </ForgotPasswordButton>
                <SubmitButton type="submit">LOGAR</SubmitButton>
              </>
            ) : (
              <>
                <ForgotPasswordButton
                  onClick={() => setIsForgot(false)}
                  type="button"
                >
                  Voltar ao login
                </ForgotPasswordButton>
                <SubmitButton type="submit">ENVIAR</SubmitButton>
              </>
            )}
          </ButtonArea>
        </Form>
        <Line />
      </Content>
    </Container>
  )
}

import styled from 'styled-components'

import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OverlayBackdrop = styled(Overlay)`
  background: rgba(132, 142, 142, 0.7);
  z-index: 15;
`

export const OverlayBody = styled(Overlay)`
  overflow-y: auto;
  z-index: 20;
`

export const Box = styled(motion.div)`
  width: 530px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 24px;
  position: relative;
  z-index: 25;
  overflow-y: auto;

  @media (max-width: 660px) {
    width: 100%;
  }
`

export const BoxTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: rgba(153, 153, 153, 0.6);

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const Title = styled.p`
  font-weight: 500;

  span {
    color: var(--primary);
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`

export const InputTitleArea = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  margin-bottom: 4px;

  span {
    color: red;
  }
`
export const InputTitleAreaWithButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  margin-bottom: 4px;

  button {
    color: var(--primary);
    font-size: 12px;
  }
`

export const InputTitle = styled.p`
  font-size: 11px;
  span {
    color: red;
    font-size: 16px;
  }
`

export const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;

  .icon {
    color: #c4d3ce;
  }

  &:hover {
    .icon {
      color: #48bdd7;
    }
  }
`

export const ChooseArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px;
`

interface ChooseItemProps {
  isActive: boolean
}

export const ChooseItem = styled.button<ChooseItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 12px;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.54);
  width: 147px;
  height: 47px;

  background: ${(props) => (props.isActive ? '#f3f5f2' : '#fff')};

  transition: background 0.2s;

  &:hover {
    background: #f3f5f2;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`

export const Grid = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 22px;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`

export const InputItem = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 2px solid #d7d7d7;
`

export const Input = styled.input`
  padding: 6px 0;
  width: 90%;
  margin-right: 8px;
`

export const InputImageArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 22px;
`

export const InputImageLabel = styled.label`
  width: 100%;
  border: 2px dotted var(--primary);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding: 14px;
  border-radius: 4px;
`

export const InputImage = styled.input`
  display: none;
`

export const SubmitButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
`
export const SubmitButton = styled.button`
  color: #fff;
  background: var(--primary);
  width: 241px;
  height: 45px;
  border-radius: 100px;
  font-weight: 500;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

import api from './api'

interface UpdateClientProps {
  key?: string
  id?: string
  seg: string
  email: string
  tel: string
  doc: string
  razao: string
  nome: string
  image?: File
}

interface UpdateMyDataProps {
  key: string
  nome: string
  doc: string
  email: string
  tel: string
  pwd?: string
}

interface UpgradePlanProps {
  key: string
  nome: string
  email: string
  tel: string
  texto: string
}

class AdmApi {
  public async create_client(data: App.FirstClient) {
    return api.post('/adms/clientes/create_client.php', data).then((res) => res)
  }

  public async list_clients(key: string) {
    return api.post('/adms/clientes/clients.php', { key }).then((res) => res)
  }

  public async client_info(key: string, id: string) {
    return api
      .post('/adms/clientes/client_infos.php', { key, id })
      .then((res) => res)
  }

  public async my_data(key: string) {
    return api.post('/adms/meusdados/get_infos.php', { key }).then((res) => res)
  }

  public async update_my_data(data: UpdateMyDataProps) {
    return api.post('/adms/meusdados/update_infos.php', data).then((res) => res)
  }

  public async upgrade_plan(data: UpgradePlanProps) {
    return api.post('/adms/meusdados/upgrade_plan.php', data).then((res) => res)
  }

  public async update_client(data: UpdateClientProps) {
    const fd = new FormData()

    if (data.key) {
      fd.append('key', data.key)
    }
    if (data.id) {
      fd.append('id', data.id)
    }
    fd.append('seg', data.seg)
    fd.append('email', data.email)
    fd.append('tel', data.tel)
    fd.append('doc', data.doc)
    fd.append('razao', data.razao)
    fd.append('nome', data.nome)
    if (data.image) {
      fd.append('image', data.image)
    }

    return api.post('/adms/clientes/update_client.php', fd).then((res) => res)
  }

  public async delete_client(key: string, id: string) {
    return api.post('/adms/clientes/del_client.php', { key, id }).then((res) => res)
  }

  public async header_info(key: string) {
    return api.post('/adms/header.php', { key }).then((res) => res)
  }

  public async send_support(data: App.SendSupport) {
    return api.post('/adms/send_suport.php', data).then((res) => res)
  }

  public async dash1(key: string) {
    return api.post('/adms/dashboard/dash1', { key }).then((res) => res)
  }

  public async notificacoes(key: string) {
    return api.post('/adms/notificacoes.php', { key }).then((res) => res)
  }

  public async list_notify(key: string) {
    return api.post('/adms/notify/list', { key }).then((res) => res)
  }

  public async view_notify(key: string) {
    return api.post('/adms/notify/update_view', { key }).then((res) => res)
  }

  public async delete_notify(key: string, id: string) {
    return api.post('/adms/notify/delete', { key, id }).then((res) => res)
  }
}

export default new AdmApi()

import styled from 'styled-components'

export const Container = styled.div`
  background: #f6f8fa;
  padding: 42px 52px;
  height: 600px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 1130px) {
    padding: 42px 12px;
  }
`

export const FirstLine = styled.div`
  width: 100%;
  display: flex;
  gap: 22px;
  padding-bottom: 42px;

  @media (max-width: 1130px) {
    flex-direction: column;
  }
`

export const CustomersWithReminders = styled.div`
  padding: 22px 0;
  background: #fff;
  border-radius: 11px;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.35);
  width: 65%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: 1130px) {
    width: 100%;
  }

  @media (max-width: 760px) {
    overflow-x: auto;
  }
`

export const CustomersWithRemindersHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;

  div {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  @media (max-width: 760px) {
    flex-direction: column;

    div {
      gap: 6px;
    }
  }
`

interface ChooseButtonProps {
  isActive: boolean
}

export const ChooseButton = styled.button<ChooseButtonProps>`
  background: ${(props) => (props.isActive ? '#F3F7F6' : '')};
  color: ${(props) => (props.isActive ? 'var(--primary)' : '#555555')};
  border-radius: 3px;
  padding: 6px 12px;
  font-size: 17px;
  border-radius: 4px;

  transition: background 0.2s, color 0.2s;
`

export const CustomersWithRemindersItemArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 22px;
`

export const Reminders = styled.div`
  padding: 22px;
  background: #fff;
  border-radius: 11px;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.35);
  width: 35%;
  height: 460px;

  @media (max-width: 1130px) {
    width: 100%;
  }
`

export const Title = styled.h1`
  font-size: 14px;
  color: #343333;
`

export const SecondLine = styled.div`
  width: 100%;
  display: flex;
  gap: 22px;

  @media (max-width: 1030px) {
    flex-direction: column;
  }
`

export const RegisterCustomer = styled.div`
  padding: 22px 0;
  background: #fff;
  border-radius: 11px;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.35);
  width: 35%;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 610px;

  @media (max-width: 1030px) {
    width: 100%;
  }

  @media (max-width: 430px) {
    img {
      width: 100%;
      height: 400px;
    }
  }
`

export const RegisterCustomerTitle = styled.h1`
  width: 50%;
  text-align: center;
  span {
    font-size: 22px;
    font-weight: 500;
  }
`

export const RegisterCustomerSubmitArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 22px;
  width: 100%;
  padding: 22px 52px;

  @media (max-width: 430px) {
    padding: 22px;
  }
`

export const ListButton = styled.button`
  width: 166px;
  height: 45px;
  background: $fff;
  border: 1px solid var(--primary);
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: var(--primary);
    text-decoration: none;
  }

  transition: background 0.2s, color 0.2s;

  &:hover {
    background: var(--primary);
    a {
      color: #fff;
    }
  }
`

export const RegisterButton = styled.button`
  width: 166px;
  height: 45px;
  background: var(--primary);
  border: 1px solid var(--primary);
  color: #fff;
  border-radius: 100px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const TotalCustomers = styled.div`
  padding: 22px 0;
  background: #fff;
  border-radius: 11px;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.35);
  width: 65%;

  height: 610px;

  @media (max-width: 1030px) {
    width: 100%;
  }
`

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f6f8fa;
  padding: 42px 52px;
  gap: 42px;

  @media (max-width: 640px) {
    padding: 42px 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;

  border-radius: 11px;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.35);
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 0;
  gap: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(55, 55, 57, 0.1);
`

export const Title = styled.p`
  color: #343333;
  font-size: 18px;
  font-weight: bold;
  padding: 0 22px;

  @media (max-width: 640px) {
    text-align: center;
  }
`

export const SubTitle = styled.p`
  color: #7f7f7f;
  font-size: 14px;
  padding: 0 22px;

  @media (max-width: 640px) {
    text-align: center;
  }
`

export const Grid = styled.div`
  display: flex;
  grid-template-columns: repeat(3, 33%);
  column-gap: 22px;
  justify-content: center;
  padding: 42px 0;

  @media (max-width: 1235px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
`

export const ReturnButton = styled.div`
  a {
    text-decoration: none;
    color: rgba(51, 51, 51, 0.7);
    display: flex;
    font-weight: 600;
  }
`

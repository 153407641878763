import styled from 'styled-components'

interface ContainerProps {
  isHome: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  height: 276px;
  padding: 0 82px;
  margin-top: 80px;
  display: ${(props) => props.isHome === true && 'none'};

  @media (max-width: 700px) {
    padding: 0 12px;
  }
`

export const Banner = styled.img`
  width: 276px;
  height: 276px;

  @media (max-width: 1010px) {
    display: none;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70%;
  padding: 22px;

  @media (max-width: 530px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

export const TopLeft = styled.div`
  display: flex;
  flex-direction: column;
`

export const TopRight = styled.div`
  display: flex;
  align-items: center;
  gap: 44px;
`

export const Title = styled.h1`
  color: #343333;
  margin-bottom: 12px;

  @media (max-width: 570px) {
    font-size: 22px;
  }
`

export const Info = styled.p`
  margin-bottom: 6px;
  span {
    color: var(--primary);
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;

  a {
    cursor: pointer;

    transition: background 0.2s;

    &:hover {
      background: transparent;
    }
  }

  a,
  div {
    background: #f4f8f7;
    padding: 6px;
    height: 45px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 6px;

    @media (max-width: 530px) {
      display: none;
    }
  }

  span {
    font-size: 20px;
  }

  @media (max-width: 530px) {
    align-items: center;
  }
`

export const Menu = styled.ul`
  height: 30%;
  display: flex;
  gap: 100px;
  padding: 0 22px;

  @media (max-width: 770px) {
    width: 100%;
    margin-top: 12px;
    padding: 0;
    gap: 0;
    justify-content: space-between;
  }
`

interface MenuItemProps {
  isActive: boolean
}

export const MenuItem = styled.div<MenuItemProps>`
  border-bottom: ${(props) =>
    props.isActive ? '2px solid var(--primary)' : 'none'};

  display: flex;
  align-items: center;

  a {
    color: ${(props) => (props.isActive ? ' var(--primary)' : '#555555')};
    text-decoration: none;
    font-weight: 500;

    transition: color 0.2s;
  }

  @media (max-width: 360px) {
    font-size: 14px;
  }
`

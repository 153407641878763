import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { Link, useHistory } from 'react-router-dom'
import { IndividualContainer } from '../../components/IndividualContainer'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'
import {
  Banner,
  Container,
  Info,
  InputItem,
  Left,
  Right,
  SubmitButton,
  SubTitle,
  Title,
  Wrapper
} from './style'

export const Support: React.FC = () => {
  const { key, user } = useUser()
  const alert = useAlert()
  const history = useHistory()

  const [nome, setNome] = useState(user!.nome)
  const [email, setEmail] = useState('')
  const [texto, setTexto] = useState('')

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (nome !== '' && email !== '' && texto !== '') {
      const data: App.SendSupport = {
        key,
        nome,
        email,
        texto
      }

      IndividualApi.send_support(data)
        .then((res) => {
          alert.show(res.data.text)
          history.push('/dashboard')
        })
        .catch((e) => {
          console.log(e)
          alert.show('Ocorreu algum erro')
        })
    }
  }

  return (
    <IndividualContainer>
      <Container>
        <Wrapper onSubmit={handleSubmit}>
          <Left>
            <Title>Suporte</Title>
            <SubTitle>
              Tem alguma dúvida que não foi responsida? Fale conosco
            </SubTitle>
            <InputItem>
              <p>Insira seu nome completo</p>
              <input
                onChange={(e) => setNome(e.target.value)}
                placeholder="Insira seu nome"
                value={nome}
              />
            </InputItem>
            <InputItem>
              <p>E-mail</p>
              <input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Insira seu email"
                value={email}
              />
            </InputItem>
            <InputItem>
              <p>Dúvida</p>
              <textarea
                onChange={(e) => setTexto(e.target.value)}
                rows={8}
                placeholder="Insira sua dúvida"
              />
            </InputItem>
            <SubmitButton type="submit">ENVIAR MENSAGEM</SubmitButton>
          </Left>
          <Right>
            <Banner src="./assets/adm-support/banner.svg" alt="banner" />
            <Info>
              Sua dúvida pode já ter sido <span>RESPONDIDA!</span>
            </Info>
            <SubTitle>
              Confira nossa seção de Tutoriais e Dúvidas frequentes
            </SubTitle>
            <Link to="/tutorial">CONFERIR</Link>
          </Right>
        </Wrapper>
      </Container>
    </IndividualContainer>
  )
}

/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react'
import {
  Bottom,
  Button,
  ButtonArea,
  ClientName,
  Container,
  Icon,
  IconArea,
  Info,
  Logo,
  Menu,
  MenuItem,
  MobileInlineButton,
  MobileMenu,
  MobileWrapper,
  NotificationNumber,
  Title,
  Top,
  User
} from './style'

import { AnimatePresence } from 'framer-motion'
import HamburgerMenu from 'react-hamburger-menu'
import { BiCube, BiDollar } from 'react-icons/bi'
import { Link, useLocation } from 'react-router-dom'
import useWindowSize from '../../hooks/useWindowSize'
import { DropdownProfile } from '../DropdownProfile'

import {
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiOutlineBank,
  AiOutlineUserAdd
} from 'react-icons/ai'
import { HiHome } from 'react-icons/hi'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { RiArrowDownSFill, RiLuggageCartLine } from 'react-icons/ri'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import { DropdownList } from '../DropdownList'
import { BsFolder } from 'react-icons/bs'
import IndividualApi from '../../services/IndividualApi'
import AdmApi from '../../services/AdmApi'

interface HeaderProps {
  showHalfHeader: boolean
}

export const Header: React.FC<HeaderProps> = ({ showHalfHeader }) => {
  const {
    logOut,
    isAdministrative,
    admHeaderInfo,
    individualHeaderInfo,
    admInUser,
    setAdmInUser,
    admInUserName,
    key
  } = useUser()
  const { openNotificationModal } = useModal()
  const { screenWidth } = useWindowSize()
  const location = useLocation()
  const {
    openPaymentModal,
    openProductModal,
    openPersonModal,
    openNewBankAccountModal,
    openCategoryModal,
    updateNotification
  } = useModal()

  const [profileDropdownIsOpen, setProfileDropdownIsOpen] = useState(false)
  const [listDropdownIsOpen, setListDropdownIsOpen] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const [listIsOpen, setListIsOpen] = useState(false)
  const [notifications, setNotifications] = useState<number>()

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const profileDropdownRef = useRef<HTMLDivElement>(null)
  const listDropdownRef = useRef<HTMLDivElement>(null)

  const toggleProfileDropdown = () => {
    setProfileDropdownIsOpen(!profileDropdownIsOpen)
  }
  const closeProfileDropdown = () => {
    setProfileDropdownIsOpen(false)
  }

  const toggleListDropdown = () => {
    setListDropdownIsOpen(!listDropdownIsOpen)
  }
  const closeListDropdown = () => {
    setListDropdownIsOpen(false)
  }

  useEffect(() => {
    const checkIfClickedOutsideOne = (e: any) => {
      if (
        profileDropdownIsOpen &&
        profileDropdownRef.current &&
        !profileDropdownRef!.current!.contains(e.target)
      ) {
        closeProfileDropdown()
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutsideOne)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutsideOne)
    }
  }, [profileDropdownIsOpen])

  useEffect(() => {
    const checkIfClickedOutsideOne = (e: any) => {
      if (
        listDropdownIsOpen &&
        listDropdownRef.current &&
        !listDropdownRef!.current!.contains(e.target)
      ) {
        closeListDropdown()
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutsideOne)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutsideOne)
    }
  }, [listDropdownIsOpen])

  useEffect(() => {
    if (!isAdministrative) {
      IndividualApi.list_notify(key).then((res) => {
        setNotifications(res.data.filter((n: any) => n.view === 0).length)
      })
    } else {
      AdmApi.list_notify(key).then((res) => {
        setNotifications(res.data.filter((n: any) => n.view === 0).length)
      })
    }
  }, [updateNotification])

  return (
    <Container
      showHalfHeader={showHalfHeader}
      isHome={location.pathname === '/home'}
    >
      <Top showHalfHeader={showHalfHeader}>
        <Logo src="./assets/logo.png" alt="logo" />
        {screenWidth >= 1030 ? (
          <>
            <Info>
              <IconArea>
                {location.pathname !== '/new-customer' && (
                  <Icon>
                    <Link
                      onClick={() => {
                        if (admInUser) {
                          setAdmInUser(false)
                        }
                      }}
                      to={isAdministrative ? '/adm-dashboard' : 'dashboard'}
                    >
                      <HiHome size={22} className="icon" />
                    </Link>
                  </Icon>
                )}
                <Icon onClick={openNotificationModal}>
                  <IoMdNotificationsOutline size={22} className="icon" />
                  {/* {!isAdministrative &&
                    notifications !== undefined &&
                    notifications !== 0 && (
                      <NotificationNumber className="number">
                        {notifications}
                      </NotificationNumber>
                    )}
                  {isAdministrative &&
                    admHeaderInfo &&
                    admHeaderInfo?.num_lembrete > 0 ? (
                    <NotificationNumber className="number">
                      {admHeaderInfo?.num_lembrete}
                    </NotificationNumber>
                  ) : (
                    <></>
                  )} */}
                </Icon>
              </IconArea>
              <User
                defaultColor="#333"
                ref={profileDropdownRef}
                onClick={toggleProfileDropdown}
              >
                <h1>
                  {isAdministrative
                    ? admHeaderInfo?.nome
                    : individualHeaderInfo?.nome}
                </h1>
                <div>
                  <p>Minha conta</p>
                  <RiArrowDownSFill size={16} />
                  <AnimatePresence key="profiledropdown">
                    {profileDropdownIsOpen && <DropdownProfile />}
                  </AnimatePresence>
                </div>
              </User>
            </Info>
          </>
        ) : (
          <>
            <AnimatePresence>
              <div style={{ zIndex: 2 }}>
                <HamburgerMenu
                  isOpen={isOpen}
                  menuClicked={toggleMenu}
                  color="var(--primary)"
                />
              </div>
              {isOpen && (
                <MobileWrapper
                  width={screenWidth > 660 ? '40vw' : '80vw'}
                  key="mobile-menu"
                  initial={{ x: 400 }}
                  animate={{ x: 30 }}
                  exit={{ x: 400 }}
                >
                  {!isAdministrative ? (
                    <>
                      <ButtonArea>
                        <Button onClick={() => openPaymentModal('Entrada')}>
                          <div>
                            <BiDollar className="icon" size={26} />
                          </div>
                          <div>
                            <AiFillPlusCircle className="icon" size={16} />
                          </div>
                          <p>Nova Entrada</p>
                        </Button>
                        <Button onClick={() => openPaymentModal('Saida')}>
                          <div>
                            <BiDollar className="icon" size={26} />
                          </div>
                          <div>
                            <AiFillMinusCircle className="icon" size={16} />
                          </div>
                          <p>Nova Saída</p>
                        </Button>
                        <Button onClick={openProductModal}>
                          <div>
                            <BiCube className="icon" size={26} />
                          </div>
                          <p>Novo Centro de custo</p>
                        </Button>
                        <Button onClick={() => openPersonModal('provider')}>
                          <div>
                            <RiLuggageCartLine className="icon" size={26} />
                          </div>
                          <p>Novo Fornecedor</p>
                        </Button>
                        <Button onClick={() => openPersonModal('client')}>
                          <div>
                            <AiOutlineUserAdd className="icon" size={26} />
                          </div>
                          <p>Novo Pagador</p>
                        </Button>
                        <Button onClick={openNewBankAccountModal}>
                          <div>
                            <AiOutlineBank className="icon" size={26} />
                          </div>
                          <p>Nova Conta bancária</p>
                        </Button>
                        <Button onClick={openCategoryModal}>
                          <div>
                            <BsFolder className="icon" size={26} />
                          </div>
                          <p>Nova Categoria</p>
                        </Button>
                      </ButtonArea>
                      <MobileMenu>
                        <MenuItem
                          isActive={
                            admInUser
                              ? location.pathname === '/adm-in-user-dashboard'
                              : location.pathname === '/dashboard'
                          }
                        >
                          <Link
                            to={admInUser ? '/adm-in-user-dashboard' : '/dashboard'}
                          >
                            Dashboard
                          </Link>
                        </MenuItem>
                        <MenuItem
                          isActive={
                            admInUser
                              ? location.pathname === '/adm-in-user-release-book'
                              : location.pathname === '/release-book'
                          }
                        >
                          <Link
                            to={
                              admInUser
                                ? '/adm-in-user-release-book'
                                : '/release-book'
                            }
                          >
                            Livro de Lançamentos
                          </Link>
                        </MenuItem>
                        <MenuItem
                          isActive={
                            admInUser
                              ? location.pathname === '/adm-in-user-report'
                              : location.pathname === '/report'
                          }
                        >
                          <Link to={admInUser ? '/adm-in-user-report' : '/report'}>
                            Relatórios
                          </Link>
                        </MenuItem>
                        <MenuItem
                          isActive={false}
                          onClick={() => setListIsOpen(!listIsOpen)}
                        >
                          <Link to="#">Listagens</Link>
                        </MenuItem>
                        {listIsOpen && (
                          <>
                            <MenuItem
                              isActive={
                                admInUser
                                  ? location.pathname ===
                                    '/adm-in-user-list-providers'
                                  : location.pathname === '/list-providers'
                              }
                            >
                              <Link
                                to={
                                  admInUser
                                    ? '/adm-in-user-list-providers'
                                    : '/list-providers'
                                }
                              >
                                Fornecedores
                              </Link>
                            </MenuItem>
                            <MenuItem
                              isActive={
                                admInUser
                                  ? location.pathname === '/adm-in-list-clients'
                                  : location.pathname === '/list-clients'
                              }
                            >
                              <Link
                                to={
                                  admInUser
                                    ? '/adm-in-user-list-clients'
                                    : '/list-clients'
                                }
                              >
                                Pagadores
                              </Link>
                            </MenuItem>
                            <MenuItem
                              isActive={
                                admInUser
                                  ? location.pathname ===
                                    '/adm-in-user-list-categories'
                                  : location.pathname === '/list-categories'
                              }
                            >
                              <Link
                                to={
                                  admInUser
                                    ? '/adm-in-user-list-categories'
                                    : '/list-categories'
                                }
                              >
                                Categorias
                              </Link>
                            </MenuItem>
                            <MenuItem
                              isActive={
                                admInUser
                                  ? location.pathname ===
                                    '/adm-in-user-list-bank-accounts'
                                  : location.pathname === '/list-bank-accounts'
                              }
                            >
                              <Link
                                to={
                                  admInUser
                                    ? '/adm-in-user-list-bank-accounts'
                                    : '/list-bank-accounts'
                                }
                              >
                                Contas Bancárias
                              </Link>
                            </MenuItem>
                            <MenuItem
                              isActive={
                                admInUser
                                  ? location.pathname ===
                                    '/adm-in-user-list-products'
                                  : location.pathname === '/list-products'
                              }
                            >
                              <Link
                                to={
                                  admInUser
                                    ? '/adm-in-user-list-products'
                                    : '/list-products'
                                }
                              >
                                Centro de custo
                              </Link>
                            </MenuItem>
                            <MenuItem
                              isActive={
                                admInUser
                                  ? location.pathname ===
                                    '/adm-in-user-list-recurrences'
                                  : location.pathname === '/list-recurrences'
                              }
                            >
                              <Link
                                to={
                                  admInUser
                                    ? '/adm-in-user-list-recurrences'
                                    : '/list-recurrences'
                                }
                              >
                                Recorrências
                              </Link>
                            </MenuItem>
                          </>
                        )}
                        <MenuItem isActive={false} onClick={logOut}>
                          <Link to="#">Sair</Link>
                        </MenuItem>
                      </MobileMenu>
                    </>
                  ) : (
                    <>
                      <MobileMenu>
                        <MenuItem isActive={location.pathname === '/adm-dashboard'}>
                          <Link to="/adm-dashboard">Dashboard</Link>
                        </MenuItem>
                        <MenuItem isActive={location.pathname === '/adm-clients'}>
                          <Link to="/adm-clients">Lista de Pagadores</Link>
                        </MenuItem>
                        <MenuItem isActive={location.pathname === '/adm-my-data'}>
                          <Link to="/adm-my-data">Meus Dados</Link>
                        </MenuItem>
                        <MenuItem isActive={location.pathname === '/adm-tutorial'}>
                          <Link to="/adm-tutorial">Tutoriais</Link>
                        </MenuItem>
                        <MenuItem isActive={location.pathname === '/adm-support'}>
                          <Link to="/adm-support">Suporte</Link>
                        </MenuItem>
                        <MenuItem isActive={false} onClick={logOut}>
                          <Link to="#">Sair</Link>
                        </MenuItem>
                      </MobileMenu>
                    </>
                  )}
                </MobileWrapper>
              )}
            </AnimatePresence>
          </>
        )}
      </Top>
      <Bottom showHalfHeader={showHalfHeader}>
        <Menu>
          <MenuItem
            isActive={
              admInUser
                ? location.pathname === '/adm-in-user-dashboard'
                : location.pathname === '/dashboard'
            }
          >
            <Link to={admInUser ? '/adm-in-user-dashboard' : '/dashboard'}>
              Dashboard
            </Link>
          </MenuItem>
          <MenuItem
            isActive={
              admInUser
                ? location.pathname === '/adm-in-user-release-book'
                : location.pathname === '/release-book'
            }
          >
            <Link to={admInUser ? '/adm-in-user-release-book' : '/release-book'}>
              Livro de Lançamentos
            </Link>
          </MenuItem>
          <MenuItem
            isActive={
              admInUser
                ? location.pathname === '/adm-in-user-report'
                : location.pathname === '/report'
            }
          >
            <Link to={admInUser ? '/adm-in-user-report' : '/report'}>
              Relatórios
            </Link>
          </MenuItem>
          <MenuItem isActive={false}>
            <div ref={listDropdownRef} onClick={toggleListDropdown}>
              <p>Listagens</p>
              <RiArrowDownSFill size={16} />
              <AnimatePresence key="listdropdown">
                {listDropdownIsOpen && <DropdownList />}
              </AnimatePresence>
            </div>
          </MenuItem>
        </Menu>
        {isAdministrative && (
          <ClientName>
            <p>Cliente: {admInUserName}</p>
          </ClientName>
        )}
      </Bottom>
    </Container>
  )
}

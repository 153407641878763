import React, { useState } from 'react'
import {
  Checkbox,
  CheckboxItem,
  Container,
  DisplayMoreColumnsButton,
  Header,
  HeaderContent,
  Search,
  Title,
  Top,
  TopInfo,
  TopSubTitle,
  TopTitle,
  Wrapper
} from './style'

import { BsFillGrid1X2Fill, BsSearch } from 'react-icons/bs'
import Select from 'react-select'
import { IndividualContainer } from '../../components/IndividualContainer'
import { useUser } from '../../contexts/UserContext'

export const ReleaseListing: React.FC = () => {
  const [days, setDays] = useState(30)

  const { user } = useUser()

  const options = [
    { value: 15, label: '15 dias' },
    { value: 30, label: '30 dias' },
    { value: 60, label: '60 dias' },
    { value: 90, label: '90 dias' }
  ]

  const customSelectStyles = {
    container: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#e4eeec',
      color: '#768882'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    control: (provided: any) => ({
      ...provided,
      background: '#e4eeec',
      border: 'none',
      borderRadius: '20px',
      width: '120px',
      color: '#768882'
    })
  }

  return (
    <IndividualContainer>
      <Container>
        <Header>
          <Title>Lançamentos (34)</Title>
          <HeaderContent>
            <CheckboxItem>
              <Checkbox type="checkbox" />
              <p>Entradas</p>
            </CheckboxItem>
            <CheckboxItem>
              <Checkbox type="checkbox" />
              <p>Saídas</p>
            </CheckboxItem>
            <Select
              placeholder="30 dias"
              onChange={(e) => {
                setDays(e!.value)
              }}
              options={options}
              styles={customSelectStyles}
            />
            <Search>
              <input type="text" placeholder="Pesquisar" />
              <BsSearch size={22} fill="var(--primary)" />
            </Search>
          </HeaderContent>
        </Header>
        <Wrapper>
          <Top>
            <TopInfo>
              <TopTitle>
                Listagem de lançamentos do cliente <span>{user?.nome}</span>
              </TopTitle>
              <TopSubTitle>Confira os lançamentos desse cliente abaixo</TopSubTitle>
            </TopInfo>
            <DisplayMoreColumnsButton>
              <p>Exibir mais colunas</p>
              <div>
                <BsFillGrid1X2Fill color="var(--primary)" />
              </div>
            </DisplayMoreColumnsButton>
          </Top>
        </Wrapper>
      </Container>
    </IndividualContainer>
  )
}

import React from 'react'
import { useLocation } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import { Home } from '../../pages/Home'
import { ADMHeader } from '../ADMHeader'
import { Header } from '../Header'
import { Container } from './style'
import { ADMDashboard } from '../../pages/ADMDashboard'

export const AdministrativeLayout: React.FC = ({ children }) => {
  const { pathname } = useLocation()
  const { user } = useUser()

  return (
    <Container>
      <Header showHalfHeader={true} />
      {user?.clientes && <ADMHeader />}
      {children}
    </Container>
  )
}

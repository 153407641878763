import React from 'react'
import { BiPlay } from 'react-icons/bi'
import {
  Button,
  Container,
  Description,
  Left,
  Name,
  Number,
  Right,
  Thumbnail
} from './style'

export const TutorialCard: React.FC = () => {
  return (
    <Container>
      <Left>
        <Number>1</Number>
      </Left>
      <Right>
        <Name>Como cadastrar uma saída</Name>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
          bibendum laoreet. Proin gravida dolor sit amet lacus
        </Description>
        <Thumbnail>
          <Button>
            <BiPlay size={24} />
          </Button>
        </Thumbnail>
      </Right>
    </Container>
  )
}

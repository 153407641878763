import api from './api'

interface UpdateMyDataProps {
  key: string
  nome: string
  doc: string
  email: string
  image?: File
  tel: string
  pwd?: string
}

interface UpgradePlanProps {
  key: string
  nome: string
  email: string
  tel: string
  texto: string
}

class IndividualApi {
  public async header_info(key: string) {
    return api.post('/individual/header.php', { key }).then((res) => {
      return res
    })
  }

  public async new_entry(data: App.Payment) {
    const formData = new FormData()

    formData.append('key', data.key)
    formData.append('tipo', data.tipo)
    formData.append('data_prev', data.data_prev)
    formData.append('data_pag', data.data_pag)
    formData.append('data_registro', data.data_registro)
    formData.append('cadastro', data.cadastro.toLowerCase())
    formData.append('valor', data.valor)
    formData.append('tipo_pag', data.tipo_pag)
    formData.append('resp_pag', data.resp_pag)
    formData.append('resp_nome', data.resp_nome)
    formData.append('projeto', data.projeto)
    formData.append('conta', data.conta)
    formData.append('historico', data.historico)
    formData.append('categoria', data.categoria)
    formData.append('status', data.status)
    formData.append('arquivo', data.arquivo)

    return api.post('/individual/add/new_entry.php', formData).then((res) => res)
  }

  public async new_rec(data: App.Recurrence) {
    return api.post('/individual/add/new_rec.php', data).then((res) => res)
  }

  public async new_par(data: App.Installments) {
    return api.post('/individual/add/new_par.php', data).then((res) => res)
  }

  public async new_product(data: App.NewProduct) {
    return api.post('/individual/add/new_product.php', data).then((res) => res)
  }

  public async new_provider(data: App.NewPerson) {
    return api.post('/individual/add/new_forn.php', data).then((res) => res)
  }

  public async new_client(data: App.NewPerson) {
    return api.post('/individual/add/new_client.php', data).then((res) => res)
  }

  public async new_category(data: App.NewCategory) {
    return api.post('/individual/add/new_category.php', data).then((res) => res)
  }

  public async new_center(data: App.NewCostCenter) {
    return api.post('/individual/add/new_center.php', data).then((res) => res)
  }

  public async new_bank_account(data: App.NewBankAccount) {
    return api.post('/individual/add/new_account.php', data).then((res) => res)
  }

  // LISTAGEM

  public async list_clients(key: string) {
    return api.post('/individual/listagem/clients.php', { key }).then((res) => res)
  }

  public async list_centers(key: string) {
    return api.post('/individual/listagem/center.php', { key }).then((res) => res)
  }

  public async list_categories(key: string) {
    return api.post('/individual/listagem/category.php', { key }).then((res) => res)
  }

  public async list_products(key: string) {
    return api.post('/individual/listagem/product.php', { key }).then((res) => res)
  }

  public async list_forn(key: string) {
    return api.post('/individual/listagem/forn.php', { key }).then((res) => res)
  }

  public async list_accounts(key: string) {
    return api.post('/individual/listagem/accounts.php', { key }).then((res) => res)
  }

  public async list_recs(key: string) {
    return api.post('/individual/listagem/recs.php', { key }).then((res) => res)
  }

  // DELETAR

  public async delete_client(key: string, id: string) {
    return api
      .post('/individual/delete/delete_client.php', { key, id })
      .then((res) => res)
  }

  public async delete_center(key: string, id: string) {
    return api
      .post('/individual/delete/delete_center.php', { key, id })
      .then((res) => res)
  }

  public async delete_category(key: string, id: string) {
    return api
      .post('/individual/delete/delete_category.php', { key, id })
      .then((res) => res)
  }

  public async delete_product(key: string, id: string) {
    return api
      .post('/individual/delete/delete_product.php', { key, id })
      .then((res) => res)
  }

  public async delete_forn(key: string, id: string) {
    return api
      .post('/individual/delete/delete_forn.php', { key, id })
      .then((res) => res)
  }

  public async delete_account(key: string, id: string) {
    return api
      .post('/individual/delete/delete_account.php', { key, id })
      .then((res) => res)
  }

  public async delete_rec(key: string, id: string) {
    return api
      .post('/individual/delete/delete_rec.php', { key, id })
      .then((res) => res)
  }

  // ATUALIZAR

  public async update_client(
    key: string,
    id: string,
    nome: string,
    tel: string,
    email: string,
    desc: string
  ) {
    return api
      .post('/individual/update/update_client.php', {
        key,
        id,
        nome,
        tel,
        email,
        desc
      })
      .then((res) => res)
  }

  public async update_center(key: string, id: string, nome: string, desc: string) {
    return api
      .post('/individual/update/update_center.php', { key, id, nome, desc })
      .then((res) => res)
  }

  public async update_category(key: string, id: string, nome: string, desc: string) {
    return api
      .post('/individual/update/update_category.php', { key, id, nome, desc })
      .then((res) => res)
  }

  public async update_recurrence({
    cadastro,
    conta,
    categoria,
    data_registro,
    dia_prev,
    historico,
    key,
    projeto,
    resp_nome,
    resp_pag,
    tipo,
    tipo_pag,
    valor,
    id
  }: App.Recurrence) {
    return api
      .post('/individual/update/update_rec.php', {
        cadastro,
        conta,
        categoria,
        data_registro,
        dia_prev,
        historico,
        key,
        projeto,
        resp_nome,
        resp_pag,
        tipo,
        tipo_pag,
        valor,
        id
      })
      .then((res) => res)
  }

  public async update_product(
    key: string,
    id: string,
    nome: string,
    categoria: string,
    preco: string,
    sku: string,
    obs: string
  ) {
    return api
      .post('/individual/update/update_product.php', {
        key,
        id,
        nome,
        categoria,
        preco,
        sku,
        obs
      })
      .then((res) => res)
  }

  public async update_forn(
    key: string,
    id: string,
    nome: string,
    tel: string,
    email: string,
    desc: string
  ) {
    return api
      .post('/individual/update/update_forn.php', {
        key,
        id,
        nome,
        tel,
        email,
        desc
      })
      .then((res) => res)
  }

  public async update_account(
    key: string,
    id: string,
    nome: string,
    banco: string,
    agencia: string,
    conta: string,
    tipo: string,
    desc: string
  ) {
    return api
      .post('/individual/update/update_account.php', {
        key,
        id,
        nome,
        banco,
        agencia,
        conta,
        tipo,
        desc
      })
      .then((res) => res)
  }
  // COLUMNS

  public async list_columns(key: string) {
    return api.post('/individual/lancamentos/coluns.php', { key }).then((res) => res)
  }

  public async update_columns(data: App.Columns) {
    return api
      .post('/individual/lancamentos/update_coluns.php', data)
      .then((res) => res)
  }

  // LANÇAMENTOS

  public async list_lancamentos(key: string, inicio?: string, final?: string) {
    if (inicio !== '' && final !== '') {
      return api
        .post('/individual/lancamentos/lancamentos.php', { key, inicio, final })
        .then((res) => res)
    } else {
      return api
        .post('/individual/lancamentos/lancamentos.php', { key })
        .then((res) => res)
    }
  }

  public async lancamento(key: string, id: string) {
    return api
      .post('/individual/lancamentos/get_lancamento.php', { key, id })
      .then((res) => res)
  }

  public async del_lancamento(key: string, id: string) {
    return api
      .post('/individual/lancamentos/del_lancamento.php', { key, id })
      .then((res) => res)
  }

  public async update_lancamento(data: App.Payment, id: string) {
    const formData = new FormData()

    formData.append('id', id)
    formData.append('key', data.key)
    formData.append('tipo', data.tipo)
    formData.append('data_prev', data.data_prev)
    formData.append('data_pag', data.data_pag)
    formData.append('data_registro', data.data_registro)
    formData.append('cadastro', data.cadastro.toLowerCase())
    formData.append('valor', data.valor)
    formData.append('tipo_pag', data.tipo_pag)
    formData.append('resp_pag', data.resp_pag)
    formData.append('resp_nome', data.resp_nome)
    formData.append('projeto', data.projeto)
    formData.append('conta', data.conta)
    formData.append('historico', data.historico)
    formData.append('categoria', data.categoria)
    formData.append('status', data.status)
    formData.append('arquivo', data.arquivo)

    return api
      .post('/individual/lancamentos/update_lancamento.php', formData)
      .then((res) => res)
  }

  // DASHBOARD

  public async dash1(key: string, dias: number) {
    return api
      .post('/individual/dashboard/dash1.php', {
        key,
        dias
      })
      .then((res) => res)
  }

  public async previsao(key: string, dias: string) {
    return api
      .post('/individual/dashboard/previsao.php', {
        key,
        dias
      })
      .then((res) => res)
  }

  public async categorias(key: string, dias: string) {
    return api
      .post('/individual/dashboard/categoria.php', {
        key,
        dias
      })
      .then((res) => res)
  }

  public async lucro(key: string, meses: number) {
    return api
      .post('/individual/dashboard/lucro.php', {
        key,
        meses
      })
      .then((res) => res)
  }

  // SUPPORT

  public async send_support(data: App.SendSupport) {
    return api.post('/individual/send_suport.php', data).then((res) => res)
  }

  // MY DATA

  public async my_data(key: string) {
    return api
      .post('/individual/meusdados/get_infos.php', { key })
      .then((res) => res)
  }

  public async update_my_data(data: UpdateMyDataProps) {
    const fd = new FormData()

    fd.append('key', data.key)
    fd.append('nome', data.nome)
    fd.append('doc', data.doc)
    fd.append('email', data.email)
    fd.append('tel', data.tel)
    if (data.image) {
      fd.append('image', data.image)
    }
    if (data.pwd) {
      fd.append('pwd', data.pwd)
    }

    return api.post('/individual/meusdados/update_infos.php', fd).then((res) => res)
  }

  public async upgrade_plan(data: UpgradePlanProps) {
    return api
      .post('/individual/meusdados/upgrade_plan.php', data)
      .then((res) => res)
  }

  // REPORT

  public async generate_report(data: App.GenerateReport) {
    return api.post('/individual/relatorio/generate.php', data).then((res) => res)
  }

  public async list_models(key: string) {
    return api
      .post('/individual/relatorio/get_models.php', { key })
      .then((res) => res)
  }

  public async delete_model(key: string, id: number) {
    return api
      .post('/individual/relatorio/del_model.php', { key, id })
      .then((res) => res)
  }

  public async atividades(key: string) {
    return api
      .post('/individual/dashboard/atividades.php', { key })
      .then((res) => res)
  }

  public async notificacoes(key: string) {
    return api.post('/individual/notificacoes.php', { key }).then((res) => res)
  }

  public async list_notify(key: string) {
    return api.post('/individual/notify/list', { key }).then((res) => res)
  }

  public async view_notify(key: string) {
    return api.post('/individual/notify/update_view', { key }).then((res) => res)
  }

  public async delete_notify(key: string, id: string) {
    return api.post('/individual/notify/delete', { key, id }).then((res) => res)
  }
}

export default new IndividualApi()

import styled from 'styled-components'

export const Container = styled.div`
  background: #f6f8fa;
  padding: 42px 52px;

  display: flex;
  flex-direction: column;
  margin-top: 80px;
  height: calc(100vh - 80px);

  @media (max-width: 640px) {
    padding: 42px 12px;
  }
`

export const Wrapper = styled.form`
  display: flex;
  background: #fff;
  padding: 42px;

  @media (max-width: 1050px) {
    flex-direction: column;
  }

  @media (max-width: 470px) {
    padding: 22px;
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;

  border-right: 1px solid #d7d7d7;
  padding-right: 42px;
  width: 50%;
  height: 100%;

  @media (max-width: 1050px) {
    width: 100%;
    height: auto;
    border-right: 0;
    padding-right: 0;
  }
`

export const Title = styled.p`
  color: #343333;
  font-size: 18px;
  font-weight: bold;

  @media (max-width: 1050px) {
    text-align: center;
  }
`
export const SubTitle = styled.p`
  color: #7f7f7f;
  font-size: 14px;
  margin-bottom: 22px;

  @media (max-width: 1050px) {
    text-align: center;
  }
`
export const InputItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  p {
    color: #7f7f7f;
    font-size: 13px;
  }

  input {
    border-bottom: 1px solid #d7d7d7;
    padding: 12px 0;
    color: #000;
    font-size: 14px;
  }

  textarea {
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    padding: 12px 0;
    color: #000;
    font-size: 14px;
    resize: none;
  }
`

export const SubmitButton = styled.button`
  background: var(--primary);
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 9px;
  width: 181px;
  height: 40px;
  margin-top: 32px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 1050px) {
    align-self: center;
  }
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 42px;
  width: 50%;
  height: 100%;

  a {
    border: 1px solid var(--primary);
    color: var(--primary);
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 181px;
    height: 40px;
    border-radius: 9px;

    transition: background 0.2s;

    &:hover {
      background: #138059;
    }
  }

  @media (max-width: 1050px) {
    width: 100%;
    height: auto;
  }
`

export const Banner = styled.img`
  width: 436px;
  height: 436px;

  @media (max-width: 440px) {
    width: 150%;
  }
`

export const Info = styled.p`
  color: #333;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 260px;
  margin-bottom: 22px;

  span {
    color: var(--primary);
    font-size: 20px;
  }
`

import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmApi from '../../services/AdmApi'
import IndividualApi from '../../services/IndividualApi'
import { toBase64 } from '../../utils/fileToBase64'
import {
  Box,
  Container,
  ImageArea,
  Input,
  InputArea,
  InputItem,
  InputTitle,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  Title
} from './style'
import AdmInUserApi from '../../services/AdmInUserApi'

export const ModalEditClient: React.FC = () => {
  const { closeEditClientModal, editId, editData, updatingList } = useModal()
  const { key, plan, isAdministrative, admInUser, admInUserId } = useUser()
  const alert = useAlert()

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeEditClientModal()
    }
  }

  const [nome, setNome] = useState(editData.nome)
  const [email, setEmail] = useState(editData.email)
  const [tel, setTel] = useState(editData.tel)
  const [desc, setDesc] = useState(editData.desc)

  const [editName, setEditName] = useState('')
  const [editEmail, setEditEmail] = useState('')
  const [editTel, setEditTel] = useState('')
  const [editDoc, setEditDoc] = useState('')
  const [editRazao, setEditRazao] = useState('')
  const [editSeg, setEditSeg] = useState('')
  const [image, setImage] = useState<File>()

  const [base64, setBase64] = useState('')
  const [tipo, setTipo] = useState('')

  const fileRef = useRef<any>(null)

  const [srcImage, setSrcImage] = useState('')

  useEffect(() => {
    AdmApi.client_info(key, String(editId)!).then((res) => {
      setEditName(res.data.Cliente.nome)
      setEditEmail(res.data.Cliente.email)
      setEditTel(res.data.Cliente.tel)
      setEditDoc(res.data.Cliente.doc)
      setEditRazao(res.data.Cliente.razao)
      setEditSeg(res.data.Cliente.seg)
      setTipo(res.data.Cliente.tipo_img)
      setBase64(res.data.Cliente.img)
    })
  }, [])

  const verifyFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const url = await toBase64(e.target!.files![0])
    setBase64(url.substring(url.indexOf(',') + 1))
    setTipo(e.target!.files![0].type)
    if (e.target.files) {
      const [file]: any = Array.from(fileRef.current.files)
      setImage(file)
      setSrcImage(URL.createObjectURL(file))
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (plan === 'individual') {
      IndividualApi.update_client(key, String(editId), nome, tel, email, desc).then(
        (res) => {
          alert.show(res.data.text)
          updatingList()
          closeEditClientModal()
        }
      )
    } else {
      if (!admInUser) {
        if (image) {
          AdmApi.update_client({
            key,
            id: String(editId),
            nome: editName,
            tel: editTel,
            razao: editRazao,
            email: editEmail,
            doc: editDoc,
            seg: editSeg,
            image
          }).then((res) => {
            alert.show(res.data.text)
            updatingList()
            closeEditClientModal()
          })
        } else {
          AdmApi.update_client({
            key,
            id: String(editId),
            nome: editName,
            tel: editTel,
            razao: editRazao,
            email: editEmail,
            doc: editDoc,
            seg: editSeg
          }).then((res) => {
            alert.show(res.data.text)
            updatingList()
            closeEditClientModal()
          })
        }
      } else {
        AdmInUserApi.update_client(
          key,
          String(editId),
          nome,
          tel,
          email,
          desc,
          admInUserId
        ).then((res) => {
          alert.show(res.data.text)
          updatingList()
          closeEditClientModal()
        })
      }
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
        <Title>Editar</Title>
        {plan === 'individual' ||
          (admInUser && (
            <InputArea onSubmit={handleSubmit}>
              <InputItem>
                <InputTitle>Nome</InputTitle>
                <Input value={nome} onChange={(e) => setNome(e.target.value)} />
              </InputItem>
              <InputItem>
                <InputTitle>Email</InputTitle>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} />
              </InputItem>
              <InputItem>
                <InputTitle>Telefone</InputTitle>
                <Input value={tel} onChange={(e) => setTel(e.target.value)} />
              </InputItem>
              {desc && (
                <>
                  <InputItem>
                    <InputTitle>Descrição</InputTitle>
                    <Input value={desc} onChange={(e) => setDesc(e.target.value)} />
                  </InputItem>
                </>
              )}
              <SubmitButton type="submit">Editar</SubmitButton>
            </InputArea>
          ))}
        {isAdministrative && !admInUser && (
          <InputArea onSubmit={handleSubmit}>
            <ImageArea>
              {srcImage !== '' ? (
                <img src={srcImage} alt="user image" />
              ) : (
                <img src={`data:image/${tipo};base64,${base64}`} alt="user image" />
              )}

              <input
                onChange={verifyFile}
                type="file"
                id="image"
                ref={fileRef}
                accept="image/png, image/jpg"
              />
              <label htmlFor="image">Alterar imagem</label>
            </ImageArea>
            <InputItem>
              <InputTitle>Nome</InputTitle>
              <Input
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
              />
            </InputItem>
            <InputItem>
              <InputTitle>Email</InputTitle>
              <Input
                value={editEmail}
                onChange={(e) => setEditEmail(e.target.value)}
              />
            </InputItem>
            <InputItem>
              <InputTitle>Telefone</InputTitle>
              <Input value={editTel} onChange={(e) => setEditTel(e.target.value)} />
            </InputItem>
            <InputItem>
              <InputTitle>Documento</InputTitle>
              <Input value={editDoc} onChange={(e) => setEditDoc(e.target.value)} />
            </InputItem>
            <InputItem>
              <InputTitle>Razao</InputTitle>
              <Input
                value={editRazao}
                onChange={(e) => setEditRazao(e.target.value)}
              />
            </InputItem>
            <InputItem>
              <InputTitle>Segmento</InputTitle>
              <Input value={editSeg} onChange={(e) => setEditSeg(e.target.value)} />
            </InputItem>
            <SubmitButton type="submit">Editar</SubmitButton>
          </InputArea>
        )}
      </Box>
    </Container>
  )
}

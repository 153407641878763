import React, { useEffect, useState } from 'react'
import { BiReset } from 'react-icons/bi'
import { Calendar } from './style'

import DatePicker from '@amir04lm26/react-modern-calendar-date-picker'
import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css'

const myCustomLocale = {
  // months list by order
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],

  // week days by order
  weekDays: [
    {
      name: 'Domingo', // used for accessibility
      short: 'D', // displayed at the top of days' rows
      isWeekend: true // is it a formal weekend or not?
    },
    {
      name: 'Segunda',
      short: 'S'
    },
    {
      name: 'Terça-feira',
      short: 'T'
    },
    {
      name: 'Quarta-feira',
      short: 'Q'
    },
    {
      name: 'Quinta-feira',
      short: 'Q'
    },
    {
      name: 'Sexta-feira',
      short: 'S'
    },
    {
      name: 'Sábado',
      short: 'S',
      isWeekend: true
    }
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject: any) {
    return gregorainTodayObject
  },

  // return a native JavaScript date here
  toNativeDate(date: any) {
    return new Date(date.year, date.month - 1, date.day)
  },

  // return a number for date's month length
  getMonthLength(date: any) {
    return new Date(date.year, date.month, 0).getDate()
  },

  // return a transformed digit to your locale
  transformDigit(digit: any) {
    return digit
  },

  // texts in the date picker
  nextMonth: 'Próximo mês',
  previousMonth: 'Mês anterior',
  openMonthSelector: 'Abra o seletor de mês',
  openYearSelector: 'Abra o seletor de ano',
  closeMonthSelector: 'Feche o seletor de mês',
  closeYearSelector: 'Feche o seletor de mês',
  defaultPlaceholder: 'Selecione...',

  // for input range value
  from: 'from',
  to: 'to',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false
}

interface CalendarPickerProps {
  initialDate: React.Dispatch<React.SetStateAction<string>>
  endDate: React.Dispatch<React.SetStateAction<string>>
}

export const RangeCalendarPicker: React.FC<CalendarPickerProps> = ({
  initialDate,
  endDate
}) => {
  const [selectedDayRange, setSelectedDayRange] = useState<any>({
    from: null,
    to: null
  })

  const formatInputValue = () => {
    if (selectedDayRange.from === null && selectedDayRange.to === null) {
      return 'Selecionar datas'
    }
    if (selectedDayRange.from !== null && selectedDayRange.to === null) {
      return `${selectedDayRange.from.year}/${selectedDayRange.from.month}/${selectedDayRange.from.day} - xxxx-xx-xx`
    }

    if (selectedDayRange.from === null && selectedDayRange.to !== null) {
      return `xxxx-xx-xx - ${selectedDayRange.to.year}/${selectedDayRange.to.month}/${selectedDayRange.to.day}`
    }

    return `
      ${selectedDayRange.from.year}/${selectedDayRange.from.month}/${selectedDayRange.from.day} - ${selectedDayRange.to.year}/${selectedDayRange.to.month}/${selectedDayRange.to.day}
    `
  }

  const clear = () => {
    setSelectedDayRange({ from: null, to: null })
    initialDate('')
    endDate('')
  }

  useEffect(() => {
    if (selectedDayRange.from !== null) {
      initialDate(
        `${selectedDayRange.from.year}/${selectedDayRange.from.month}/${selectedDayRange.from.day}`
      )
    }
    if (selectedDayRange.to !== null) {
      endDate(
        `${selectedDayRange.to.year}/${selectedDayRange.to.month}/${selectedDayRange.to.day}`
      )
    }
  }, [selectedDayRange])

  return (
    <>
      <Calendar>
        <DatePicker
          calendarPopperPosition="bottom"
          value={selectedDayRange}
          onChange={setSelectedDayRange}
          formatInputText={formatInputValue}
          inputClassName="calendar-input"
          calendarClassName="calendar"
          colorPrimary="var(--primary)"
          shouldHighlightWeekends
          locale={myCustomLocale}
        />
        <button onClick={clear}>
          <BiReset size={20} fill="#333" />
        </button>
      </Calendar>
    </>
  )
}

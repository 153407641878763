import md5 from 'md5'
import React, { FormEvent, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmApi from '../../services/AdmApi'
import {
  Banner,
  Container,
  Form,
  Graphic,
  Header,
  Info,
  Input,
  InputItem,
  InputTitle,
  Left,
  Number,
  Right,
  RightColumn,
  RightContent,
  RightInfo,
  RightSubTitle,
  RightTitle,
  SubmitButton,
  SubTitle,
  Title,
  UpgradeButton
} from './style'

export const ADMMyData: React.FC = () => {
  const { key } = useUser()
  const alert = useAlert()
  const { setKey } = useUser()
  const { openUpgradeModal } = useModal()

  const [clientes, setClientes] = useState(0)
  const [tipoDoc, setTipoDoc] = useState('')
  const [doc, setDoc] = useState('')
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')

  const [pwd1, setPwd1] = useState('')
  const [pwd2, setPwd2] = useState('')

  useEffect(() => {
    AdmApi.my_data(key).then((res) => {
      setTipoDoc(res.data.tipo_doc)
      setNome(res.data.nome)
      setEmail(res.data.email)
      setTel(res.data.tel)
      setDoc(res.data.doc)
      setClientes(res.data.clientes)
    })
  }, [])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (pwd1 === '' && pwd2 === '') {
      AdmApi.update_my_data({ key, nome, email, doc, tel })
        .then((res) => alert.show(res.data.text))
        .catch(() => alert.show('Algo deu errado'))
    } else {
      if (pwd1 !== pwd2) return alert.show('As senhas devem ser iguais')
      if (pwd1.length <= 5) {
        return alert.show('A senha deve ter pelo menos 6 dígitos')
      }

      AdmApi.update_my_data({
        key,
        nome,
        email,
        doc,
        tel,
        pwd: md5(pwd1)
      })
        .then((res) => {
          alert.show(res.data.text)
          setKey(res.data.newkey)
          setPwd1('')
          setPwd2('')
        })
        .catch(() => alert.show('Algo deu errado'))
    }
  }

  return (
    <Container>
      <Left>
        <Header hasBottomBorder>
          <Title>Dados de cadastro</Title>
          <SubTitle>Confira os seus dados de cadastro</SubTitle>
        </Header>
        <Form onSubmit={handleSubmit}>
          <InputItem isDisabled>
            <InputTitle>Tipo</InputTitle>
            <Input value={tipoDoc === 'CPF' ? 'PF' : 'PJ'} disabled />
          </InputItem>
          <InputItem isDisabled style={{ gridColumn: 'span 2' }}>
            <InputTitle>{tipoDoc === 'CPF' ? 'CPF' : 'CNPJ'}</InputTitle>
            <Input value={doc} disabled />
          </InputItem>
          <InputItem style={{ gridColumn: 'span 2' }}>
            <InputTitle>Insira seu nome completo</InputTitle>
            <Input value={nome} onChange={(e) => setNome(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Insira o seu celular</InputTitle>
            <Input value={tel} onChange={(e) => setTel(e.target.value)} />
          </InputItem>
          <InputItem style={{ gridColumn: 'span 3' }}>
            <InputTitle>E-mail</InputTitle>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          </InputItem>
          <Title style={{ gridColumn: 'span 3', textAlign: 'center' }}>
            Alterar senha
          </Title>
          <InputItem style={{ gridColumn: 'span 2' }}>
            <InputTitle>Nova Senha</InputTitle>
            <Input value={pwd1} onChange={(e) => setPwd1(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Confirmar Senha</InputTitle>
            <Input value={pwd2} onChange={(e) => setPwd2(e.target.value)} />
          </InputItem>
          <SubmitButton type="submit" style={{ gridColumn: 'span 3' }}>
            Atualizar
          </SubmitButton>
        </Form>
      </Left>
      <RightColumn>
        <Right>
          <RightContent>
            <Header hasBottomBorder={false}>
              <RightTitle>Dados do plano contratado</RightTitle>
              <RightSubTitle>Confira os dados do seu plano</RightSubTitle>
            </Header>
            <Graphic>
              <Number>
                <span>{clientes}</span>/50
              </Number>
            </Graphic>
            <Info>
              <p>Clientes Cadastrados</p>
              <p>Precisa cadastrar mais clientes?</p>
              <p>Faça upgrade do seu plano</p>
            </Info>
            <UpgradeButton onClick={openUpgradeModal}>FAZER UPGRADE</UpgradeButton>
          </RightContent>
          <Banner src="./assets/my-data/banner.svg" alt="banner" />
        </Right>
        <RightInfo>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
          bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra
          justo commodo. Proin
        </RightInfo>
      </RightColumn>
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
`

export const Banner = styled.div`
  width: 50%;
  height: 100%;
  background-image: url('./assets/banner.png');
  background-size: cover;
  clip-path: ellipse(68% 78% at 32% 51%);

  @media (max-width: 690px) {
    display: none;
  }
`

export const Content = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px;

  @media (max-width: 690px) {
    width: 100%;
    padding: 42px 0px;
  }
`

export const Logo = styled.img`
  width: 186px;
  height: 63px;
  margin-bottom: 62px;
`

export const Title = styled.p`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 38px;
  color: #10232e;
`

export const Form = styled.form`
  width: 80%;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--primary);
  padding-bottom: 18px;
  padding-right: 12px;

  div {
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 8px;
    width: 90%;
  }
`

export const IconArea = styled.div`
  border-right: 1px dotted #7d91a4;
  padding-right: 6px;
  width: 30px;
`

export const Input = styled.input`
  font-size: 18px;
  color: #7d91a4;
  width: 100%;
`

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ForgotPasswordButton = styled.button`
  color: #333333;

  transition: color 0.2s;

  &:hover {
    color: var(--primary);
  }
`

export const SubmitButton = styled.button`
  background: var(--primary);
  color: #fff;
  padding: 12px 48px;
  border-radius: 26px;
  font-weight: bold;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const LinkButton = styled.div`
  margin-top: 52px;

  a {
    color: #333333;
    text-decoration: none;
  }

  span {
    font-weight: bold;
  }

  @media (max-width: 805px) {
    text-align: center;
  }

  @media (max-width: 400px) {
    padding: 0 12px;
  }
`

export const Line = styled.div`
  height: 2px;
  width: 80%;
  padding: 0 100px;
  background: rgba(30, 30, 30, 0.1);
  margin-top: 32px;
`

export const PasswordButton = styled.button``

import React, { useEffect, useState } from 'react'
import { BsPlus, BsSearch } from 'react-icons/bs'
import { IndividualContainer } from '../../components/IndividualContainer'
import { ListCard } from '../../components/ListCard'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmInUserApi from '../../services/AdmInUserApi'
import IndividualApi from '../../services/IndividualApi'
import {
  Container,
  Header,
  NewButton,
  RightContent,
  Search,
  Title,
  Wrapper
} from './style'

interface AccountProps {
  id: number
  nome: string
  banco: string
  agencia: string
  conta: string
  tipo: string
  desc: string
}

export const ListBankAccounts: React.FC = () => {
  const { openNewBankAccountModal, updateList } = useModal()
  const { key, admInUser, admInUserId } = useUser()

  const [accounts, setAccounts] = useState<AccountProps[]>()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.list_accounts(key).then((res) => setAccounts(res.data.contas))
    } else {
      AdmInUserApi.list_accounts(key, admInUserId).then((res) =>
        setAccounts(res.data.contas)
      )
    }
  }, [updateList])

  return (
    <IndividualContainer>
      <Container>
        <Header>
          <Title>Contas bancárias cadastradas ({accounts?.length})</Title>
          <RightContent>
            <Search>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Pesquisar"
              />
              <BsSearch size={22} fill="var(--primary)" />
            </Search>
            <NewButton onClick={openNewBankAccountModal}>
              <BsPlus size={22} fill="#fff" />
              Nova conta bancária
            </NewButton>
          </RightContent>
        </Header>
        <Wrapper>
          {accounts
            // eslint-disable-next-line array-callback-return
            ?.filter((item) => {
              if (search === '') {
                return item
              } else if (item.nome.toLowerCase().includes(search.toLowerCase())) {
                return item
              }
            })
            .map((account) => {
              return (
                <ListCard
                  type="bank"
                  key={account.id}
                  id={String(account.id)}
                  bankData={{
                    apelido: account.nome,
                    banco: account.banco,
                    ag: account.agencia,
                    conta: account.conta,
                    desc: account.desc,
                    tipo: account.tipo
                  }}
                />
              )
            })}
        </Wrapper>
      </Container>
    </IndividualContainer>
  )
}

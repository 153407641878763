import { createContext, ReactNode, useContext, useState } from 'react'

interface ModalContextData {
  paymentModalIsOpen: boolean
  editPaymentModalId: string
  editPaymentModalCadastro: string
  editPaymentModalIsOpen: boolean
  productModalIsOpen: boolean
  personModalIsOpen: boolean
  notificationModalIsOpen: boolean
  verifyPaymentIsOpen: boolean
  welcomeModalIsOpen: boolean
  supportModalIsOpen: boolean
  newBankAccountModalIsOpen: boolean
  categoryModalIsOpen: boolean
  costCenterModalIsOpen: boolean
  editClientModalIsOpen: boolean
  editProviderModalIsOpen: boolean
  editBankModalIsOpen: boolean
  editCategoryModalIsOpen: boolean
  editCenterModalIsOpen: boolean
  editProductModalIsOpen: boolean
  editRecurrenceModalIsOpen: boolean
  updateList: boolean
  updatingList: () => void
  editId: number | undefined
  editData: any
  setEditData: React.Dispatch<any>
  personModalType: 'client' | 'provider' | undefined
  paymentModalType: 'Entrada' | 'Saida' | undefined
  createClientModalIsOpen: boolean
  upgradeModalIsOpen: boolean
  columnsModalIsOpen: boolean
  openPaymentModal: (type: 'Entrada' | 'Saida') => void
  closePaymentModal: () => void
  openEditPaymentModal: (
    type: 'Entrada' | 'Saida',
    id: string,
    cadastro: string
  ) => void
  closeEditPaymentModal: () => void
  openProductModal: () => void
  closeProductModal: () => void
  openPersonModal: (type: 'client' | 'provider') => void
  closePersonModal: () => void
  openNotificationModal: () => void
  closeNotificationModal: () => void
  openVerifyPayment: (link: string) => void
  closeVerifyPayment: () => void
  openWelcomeModal: () => void
  closeWelcomeModal: () => void
  openSupportModal: () => void
  closeSupportModal: () => void
  openNewBankAccountModal: () => void
  closeNewBankAccountModal: () => void
  openCategoryModal: () => void
  closeCategoryModal: () => void
  openCostCenterModal: () => void
  closeCostCenterModal: () => void
  openEditClientModal: (id: number) => void
  closeEditClientModal: () => void
  openEditProviderModal: (id: number) => void
  closeEditProviderModal: () => void
  openEditBankModal: (id: number) => void
  closeEditBankModal: () => void
  openEditCategoryModal: (id: number) => void
  closeEditCategoryModal: () => void
  openEditCenterModal: (id: number) => void
  closeEditCenterModal: () => void
  openEditProductModal: (id: number) => void
  closeEditProductModal: () => void
  openEditRecurrenceModal: (id: number) => void
  closeEditRecurrenceModal: () => void
  openCreateClientModal: () => void
  closeCreateClientModal: () => void
  openUpgradeModal: () => void
  closeUpgradeModal: () => void
  openColumnsModal: () => void
  closeColumnsModal: () => void
  updateNotification: boolean
  updatingNotification: () => void
  updateNotification2: boolean
  updatingNotification2: () => void
  paymentLink: string
  deleteAlertIsOpen: boolean
  setDeleteAlertIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface ModalProviderProps {
  children: ReactNode
}

export const ModalContext = createContext({} as ModalContextData)

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false)
  const [editPaymentModalIsOpen, setEditPaymentModalIsOpen] = useState(false)
  const [paymentModalType, setPaymentModalType] = useState<'Entrada' | 'Saida'>()
  const [editPaymentModalId, setEditPaymentModalId] = useState('')
  const [editPaymentModalCadastro, setEditPaymentModalCadastro] = useState('')

  const [productModalIsOpen, setProductModalIsOpen] = useState(false)

  const [personModalIsOpen, setPersonModalIsOpen] = useState(false)
  const [personModalType, setPersonModalType] = useState<'client' | 'provider'>()

  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState(false)
  const [verifyPaymentIsOpen, setVerifyPaymentIsOpen] = useState(false)
  const [welcomeModalIsOpen, setWelcomeModalIsOpen] = useState(true)
  const [supportModalIsOpen, setSupportModalIsOpen] = useState(false)
  const [newBankAccountModalIsOpen, setNewBankAccountModalIsOpen] = useState(false)
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false)
  const [costCenterModalIsOpen, setCostCenterModalIsOpen] = useState(false)

  const [editClientModalIsOpen, setEditClientModalIsOpen] = useState(false)
  const [editProviderModalIsOpen, setEditProviderModalIsOpen] = useState(false)
  const [editBankModalIsOpen, setEditBankModalIsOpen] = useState(false)
  const [editCategoryModalIsOpen, setEditCategoryModalIsOpen] = useState(false)
  const [editCenterModalIsOpen, setEditCenterModalIsOpen] = useState(false)
  const [editProductModalIsOpen, setEditProductModalIsOpen] = useState(false)
  const [editRecurrenceModalIsOpen, setEditRecurrenceModalIsOpen] = useState(false)
  const [editId, setEditId] = useState<number>()

  const [updateNotification, setUpdateNotitication] = useState(false)
  const [updateNotification2, setUpdateNotitication2] = useState(false)

  const [updateList, setUpdateList] = useState(false)
  const [editData, setEditData] = useState<any>()

  const [paymentLink, setPaymentLink] = useState('')

  const [createClientModalIsOpen, setCreateClientModalIsOpen] = useState(false)
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false)

  const [columnsModalIsOpen, setColumnsModalIsOpen] = useState(false)

  const [deleteAlertIsOpen, setDeleteAlertIsOpen] = useState(false)

  const updatingList = () => {
    setUpdateList(!updateList)
  }

  const updatingNotification = () => {
    setUpdateNotitication(!updateNotification)
  }

  const updatingNotification2 = () => {
    setUpdateNotitication2(!updateNotification2)
  }

  const openPaymentModal = (type: 'Entrada' | 'Saida') => {
    setPaymentModalIsOpen(true)
    setPaymentModalType(type)
  }

  const openEditPaymentModal = (
    type: 'Entrada' | 'Saida',
    id: string,
    cadastro: string
  ) => {
    setEditPaymentModalIsOpen(true)
    setPaymentModalType(type)
    setEditPaymentModalId(id)
    setEditPaymentModalCadastro(cadastro)
  }

  const closePaymentModal = () => {
    setPaymentModalIsOpen(false)
  }
  const closeEditPaymentModal = () => {
    setEditPaymentModalIsOpen(false)
  }

  const openProductModal = () => {
    setProductModalIsOpen(true)
  }

  const closeProductModal = () => {
    setProductModalIsOpen(false)
  }

  const openPersonModal = (type: 'client' | 'provider') => {
    setPersonModalIsOpen(true)
    setPersonModalType(type)
  }

  const closePersonModal = () => {
    setPersonModalIsOpen(false)
  }

  const openNotificationModal = () => {
    setNotificationModalIsOpen(true)
  }

  const closeNotificationModal = () => {
    setNotificationModalIsOpen(false)
  }

  const openVerifyPayment = (link: string) => {
    setPaymentLink(link)
    setVerifyPaymentIsOpen(true)
  }

  const closeVerifyPayment = () => {
    setVerifyPaymentIsOpen(false)
  }

  const openWelcomeModal = () => {
    setWelcomeModalIsOpen(true)
  }

  const closeWelcomeModal = () => {
    setWelcomeModalIsOpen(false)
  }

  const openSupportModal = () => {
    setSupportModalIsOpen(true)
  }

  const closeSupportModal = () => {
    setSupportModalIsOpen(false)
  }

  const openNewBankAccountModal = () => {
    setNewBankAccountModalIsOpen(true)
  }

  const closeNewBankAccountModal = () => {
    setNewBankAccountModalIsOpen(false)
  }

  const openCategoryModal = () => {
    setCategoryModalIsOpen(true)
  }

  const closeCategoryModal = () => {
    setCategoryModalIsOpen(false)
  }

  const openCostCenterModal = () => {
    setCostCenterModalIsOpen(true)
  }

  const closeCostCenterModal = () => {
    setCostCenterModalIsOpen(false)
  }

  const openEditClientModal = (id: number) => {
    setEditClientModalIsOpen(true)
    setEditId(id)
  }

  const closeEditClientModal = () => {
    setEditClientModalIsOpen(false)
  }

  const openEditProviderModal = (id: number) => {
    setEditProviderModalIsOpen(true)
    setEditId(id)
  }

  const closeEditProviderModal = () => {
    setEditProviderModalIsOpen(false)
  }

  const openEditBankModal = (id: number) => {
    setEditBankModalIsOpen(true)
    setEditId(id)
  }

  const closeEditBankModal = () => {
    setEditBankModalIsOpen(false)
  }

  const openEditCategoryModal = (id: number) => {
    setEditCategoryModalIsOpen(true)
    setEditId(id)
  }

  const closeEditCategoryModal = () => {
    setEditCategoryModalIsOpen(false)
  }

  const openEditCenterModal = (id: number) => {
    setEditCenterModalIsOpen(true)
    setEditId(id)
  }

  const closeEditCenterModal = () => {
    setEditCenterModalIsOpen(false)
  }

  const openEditProductModal = (id: number) => {
    setEditProductModalIsOpen(true)
    setEditId(id)
  }

  const closeEditProductModal = () => {
    setEditProductModalIsOpen(false)
  }

  const openEditRecurrenceModal = (id: number) => {
    setEditRecurrenceModalIsOpen(true)
    setEditId(id)
  }

  const closeEditRecurrenceModal = () => {
    setEditRecurrenceModalIsOpen(false)
  }

  const openCreateClientModal = () => {
    setCreateClientModalIsOpen(true)
  }

  const closeCreateClientModal = () => {
    setCreateClientModalIsOpen(false)
  }

  const openUpgradeModal = () => {
    setUpgradeModalIsOpen(true)
  }

  const closeUpgradeModal = () => {
    setUpgradeModalIsOpen(false)
  }

  const openColumnsModal = () => {
    setColumnsModalIsOpen(true)
  }

  const closeColumnsModal = () => {
    setColumnsModalIsOpen(false)
  }

  return (
    <ModalContext.Provider
      value={{
        paymentModalIsOpen,
        editPaymentModalId,
        editPaymentModalCadastro,
        openPaymentModal,
        closePaymentModal,
        editPaymentModalIsOpen,
        openEditPaymentModal,
        closeEditPaymentModal,
        productModalIsOpen,
        openProductModal,
        closeProductModal,
        personModalIsOpen,
        openPersonModal,
        closePersonModal,
        personModalType,
        notificationModalIsOpen,
        openNotificationModal,
        closeNotificationModal,
        verifyPaymentIsOpen,
        openVerifyPayment,
        closeVerifyPayment,
        welcomeModalIsOpen,
        openWelcomeModal,
        closeWelcomeModal,
        supportModalIsOpen,
        openSupportModal,
        closeSupportModal,
        newBankAccountModalIsOpen,
        openNewBankAccountModal,
        closeNewBankAccountModal,
        categoryModalIsOpen,
        openCategoryModal,
        closeCategoryModal,
        costCenterModalIsOpen,
        openCostCenterModal,
        closeCostCenterModal,
        editClientModalIsOpen,
        editProviderModalIsOpen,
        editBankModalIsOpen,
        editCategoryModalIsOpen,
        editCenterModalIsOpen,
        editProductModalIsOpen,
        openEditProductModal,
        closeEditProductModal,
        openEditBankModal,
        closeEditBankModal,
        openEditCategoryModal,
        closeEditCategoryModal,
        openEditCenterModal,
        closeEditCenterModal,
        openEditClientModal,
        closeEditClientModal,
        openEditProviderModal,
        closeEditProviderModal,
        editId,
        updateList,
        updatingList,
        editData,
        setEditData,
        paymentModalType,
        createClientModalIsOpen,
        openCreateClientModal,
        closeCreateClientModal,
        upgradeModalIsOpen,
        openUpgradeModal,
        closeUpgradeModal,
        columnsModalIsOpen,
        openColumnsModal,
        closeColumnsModal,
        openEditRecurrenceModal,
        closeEditRecurrenceModal,
        editRecurrenceModalIsOpen,
        updateNotification,
        updatingNotification,
        updateNotification2,
        updatingNotification2,
        paymentLink,
        deleteAlertIsOpen,
        setDeleteAlertIsOpen
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  return useContext(ModalContext)
}

import React from 'react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { PlanCard } from '../../components/PlanCard'
import {
  Container,
  Grid,
  Header,
  ReturnButton,
  SubTitle,
  Title,
  Wrapper
} from './style'

export const ADMUpgradePlan: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <Header>
          <Title>Fazer upgrade</Title>
          <SubTitle>Veja os planos para upgrade abaixo</SubTitle>
        </Header>
        <Grid>
          <PlanCard
            isActive={true}
            name="Nome 1"
            description="Optimal for 10+ team size and new startup"
            price="39"
            upToTen
            upToThirty
            analyticsModule
          />
          <PlanCard
            isActive={false}
            name="Nome 2"
            description="Optimal for 100+ team size and grown company"
            price="339"
            upToTen
            upToThirty
            analyticsModule
            financeModule
            accountingModule
          />
          <PlanCard
            isActive={false}
            name="Nome 3"
            description="Optimal for 1000+ team and enterprise"
            price="999"
            upToTen
            upToThirty
            analyticsModule
            financeModule
            accountingModule
            networkPlatform
            unlimitedCloud
          />
        </Grid>
      </Wrapper>
      <ReturnButton>
        <Link to="adm-my-data">
          <MdKeyboardArrowLeft color="rgba(51, 51, 51, 0.7)" size={20} />
          Retornar para meus dados
        </Link>
      </ReturnButton>
    </Container>
  )
}

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CashFlow } from '../../pages/CashFlow'
import { Dashboard } from '../../pages/Dashboard'
import { ListBankAccounts } from '../../pages/ListBankAccounts'
import { ListCategories } from '../../pages/ListCategories'
import { ListClients } from '../../pages/ListClients'
import { ListCostCenters } from '../../pages/ListCostCenters'
import { ListProducts } from '../../pages/ListProducts'
import { ListProviders } from '../../pages/ListProviders'
import { ListRecurrence } from '../../pages/ListRecurrence'
import { MyData } from '../../pages/MyData'
import { ReleaseBook } from '../../pages/ReleaseBook'
import { ReleaseListing } from '../../pages/ReleaseListing'
import { Report } from '../../pages/Report'
import { Support } from '../../pages/Support'
import { Tutorial } from '../../pages/Tutorial'

export const IndividualRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/release-book" component={ReleaseBook} />
      <Route path="/cash-flow" component={CashFlow} />
      <Route path="/report" component={Report} />
      <Route path="/release-listing" component={ReleaseListing} />
      <Route path="/list-providers" component={ListProviders} />
      <Route path="/list-products" component={ListProducts} />
      <Route path="/list-clients" component={ListClients} />
      <Route path="/list-categories" component={ListCategories} />
      <Route path="/list-cost-centers" component={ListCostCenters} />
      <Route path="/list-bank-accounts" component={ListBankAccounts} />
      <Route path="/list-recurrences" component={ListRecurrence} />
      <Route path="/support" component={Support} />
      <Route path="/tutorial" component={Tutorial} />
      <Route path="/my-data" component={MyData} />
    </Switch>
  )
}

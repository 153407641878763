import React, { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import { CashFlow } from '../../pages/CashFlow'
import { Dashboard } from '../../pages/Dashboard'
import { ListBankAccounts } from '../../pages/ListBankAccounts'
import { ListCategories } from '../../pages/ListCategories'
import { ListClients } from '../../pages/ListClients'
import { ListCostCenters } from '../../pages/ListCostCenters'
import { ListProducts } from '../../pages/ListProducts'
import { ListProviders } from '../../pages/ListProviders'
import { ListRecurrence } from '../../pages/ListRecurrence'
import { MyData } from '../../pages/MyData'
import { RegisterNewUser } from '../../pages/RegisterNewUser'
import { ReleaseBook } from '../../pages/ReleaseBook'
import { ReleaseListing } from '../../pages/ReleaseListing'
import { Report } from '../../pages/Report'
import { Support } from '../../pages/Support'
import { Tutorial } from '../../pages/Tutorial'

export const AdministrativeInUserRoutes: React.FC = () => {
  const { user, hasClient } = useUser()

  if (user && !hasClient) {
    return <RegisterNewUser />
  }

  return (
    <Switch>
      <Route path="/adm-in-user-dashboard" component={Dashboard} />
      <Route path="/adm-in-user-release-book" component={ReleaseBook} />
      <Route path="/adm-in-user-cash-flow" component={CashFlow} />
      <Route path="/adm-in-user-report" component={Report} />
      <Route path="/adm-in-user-release-listing" component={ReleaseListing} />
      <Route path="/adm-in-user-list-providers" component={ListProviders} />
      <Route path="/adm-in-user-list-products" component={ListProducts} />
      <Route path="/adm-in-user-list-clients" component={ListClients} />
      <Route path="/adm-in-user-list-categories" component={ListCategories} />
      <Route path="/adm-in-user-list-cost-centers" component={ListCostCenters} />
      <Route path="/adm-in-user-list-bank-accounts" component={ListBankAccounts} />
      <Route path="/adm-in-user-list-recurrences" component={ListRecurrence} />
      <Route path="/adm-in-user-support" component={Support} />
      <Route path="/adm-in-user-tutorial" component={Tutorial} />
      <Route path="/adm-in-user-my-data" component={MyData} />
    </Switch>
  )
}

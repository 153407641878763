import React from 'react'
import { useLocation } from 'react-router-dom'
import useWindowSize from '../../hooks/useWindowSize'
import { Home } from '../../pages/Home'
import { Header } from '../Header'
import { Sidebar } from '../Sidebar'

export const IndividualLayout: React.FC = ({ children }) => {
  const { screenWidth } = useWindowSize()
  const { pathname } = useLocation()

  return (
    <>
      {screenWidth >= 1030 && <Sidebar />}
      <Header showHalfHeader={false} />
      {children}
    </>
  )
}

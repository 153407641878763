import { useModal } from '../../contexts/ModalContext'
import { Box, Container, OverlayBackdrop, OverlayBody, Title } from './style'

export const ModalSuport: React.FC = () => {
  const { closeSupportModal } = useModal()

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeSupportModal()
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
        <Title>Modal que abre em cima de outra modal</Title>
      </Box>
    </Container>
  )
}

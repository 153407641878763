import {
  Box,
  BoxTop,
  CloseButton,
  Container,
  InputTitle,
  InputTitleArea,
  OverlayBackdrop,
  OverlayBody,
  Title,
  Tooltip,
  Wrapper
} from './style'

import 'moment-timezone'
import ReactTooltip from 'react-tooltip'

import { AiOutlineQuestionCircle } from 'react-icons/ai'

import { MdClose } from 'react-icons/md'
import { useModal } from '../../contexts/ModalContext'
import { FormSingle } from '../layout/ModalPayment/FormSingle'

interface ModalEditPaymentProps {
  type: 'Entrada' | 'Saida'
  id: string
  cadastro: string
}

export const ModalEditPayment: React.FC<ModalEditPaymentProps> = ({
  type,
  id,
  cadastro
}) => {
  const { closeEditPaymentModal } = useModal()

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeEditPaymentModal()
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ left: -530 }} animate={{ left: 0 }} exit={{ left: -530 }}>
        <BoxTop>
          <Title>Detalhes do lançamento</Title>
          <CloseButton onClick={closeEditPaymentModal} type="button">
            <MdClose size={26} />
            Fechar
          </CloseButton>
        </BoxTop>
        <Wrapper>
          <InputTitleArea>
            <InputTitle>Tipo do lançamento</InputTitle>
            <Tooltip data-tip="Tipo do lançamento">
              <AiOutlineQuestionCircle className="icon" size={16} />
              <ReactTooltip
                place="bottom"
                backgroundColor="#2D3B36"
                effect="solid"
              />
            </Tooltip>
          </InputTitleArea>
          <FormSingle isEdit={true} id={id} type={type} />
        </Wrapper>
      </Box>
    </Container>
  )
}

import React, { useState } from 'react'
import {
  Button,
  ButtonArea,
  Container,
  Image,
  SubMenu,
  SubMenuButtonArea
} from './style'

import {
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiOutlineBank,
  AiOutlineMenu,
  AiOutlineUserAdd
} from 'react-icons/ai'
import { BiCube, BiDollar, BiStoreAlt } from 'react-icons/bi'
import { BsFolder } from 'react-icons/bs'
import { RiLuggageCartLine } from 'react-icons/ri'

import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'

export const Sidebar: React.FC = () => {
  const { user, admInUser, admInUserImg, admInUserImgType } = useUser()
  const {
    openPaymentModal,
    openProductModal,
    openPersonModal,
    openNewBankAccountModal,
    openCategoryModal
  } = useModal()

  const [subMenuIsVisible, setSubMenuIsVisible] = useState(false)

  return (
    <>
      <Container onMouseLeave={() => setSubMenuIsVisible(false)}>
        <p>Cadastros</p>
        {!admInUser ? (
          <Image
            src={`data:image/${user?.imgtype};base64,${user?.img}`}
            alt="user image"
          />
        ) : (
          <Image
            src={`data:image/${admInUserImgType};base64,${admInUserImg}`}
            alt="user image"
          />
        )}

        <ButtonArea>
          <Button id="first-step" onClick={() => openPaymentModal('Entrada')}>
            <div>
              <BiDollar className="icon" size={26} />
            </div>
            <div>
              <AiFillPlusCircle className="icon" size={16} />
            </div>
            <p>Nova Entrada</p>
          </Button>
          <Button id="second-step" onClick={() => openPaymentModal('Saida')}>
            <div>
              <BiDollar className="icon" size={26} />
            </div>
            <div>
              <AiFillMinusCircle className="icon" size={16} />
            </div>
            <p>Nova Saída</p>
          </Button>
          <Button onClick={openProductModal}>
            <div>
              <BiCube className="icon" size={26} />
            </div>
            <p>Novo Centro de custo</p>
          </Button>
          <Button onClick={() => openPersonModal('provider')}>
            <div>
              <RiLuggageCartLine className="icon" size={26} />
            </div>
            <p>Novo Fornecedor</p>
          </Button>
          <Button onClick={() => openPersonModal('client')}>
            <div>
              <AiOutlineUserAdd className="icon" size={26} />
            </div>
            <p>Novo Pagador</p>
          </Button>
          <Button onMouseEnter={() => setSubMenuIsVisible(true)}>
            <div>
              <AiOutlineMenu className="icon" size={26} />
            </div>
            <p>Outros Cadastros</p>
          </Button>
        </ButtonArea>
      </Container>
      {subMenuIsVisible && (
        <SubMenu
          initial={{ left: 0 }}
          animate={{ left: 145 }}
          exit={{ left: 0 }}
          onMouseEnter={() => setSubMenuIsVisible(true)}
          onMouseLeave={() => setSubMenuIsVisible(false)}
        >
          <p>Outros Cadastros</p>
          <SubMenuButtonArea>
            <Button onClick={openNewBankAccountModal}>
              <div>
                <AiOutlineBank className="icon" size={26} />
              </div>
              <p>Nova Conta bancária</p>
            </Button>
            <Button onClick={openCategoryModal}>
              <div>
                <BsFolder className="icon" size={26} />
              </div>
              <p>Nova Categoria</p>
            </Button>
          </SubMenuButtonArea>
        </SubMenu>
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useUser } from '../../../../contexts/UserContext'
import AdmInUserApi from '../../../../services/AdmInUserApi'
import IndividualApi from '../../../../services/IndividualApi'
import { Container, Header, HeaderInfo, Item, ItemList, Title } from './style'

interface ActivitiesProps {
  id: number
  titulo: string
  criado: string
}

export const Activities: React.FC = () => {
  const { key, updateGraphics, admInUser, admInUserId } = useUser()

  const [atividades, setAtividades] = useState<ActivitiesProps[]>([])

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.atividades(key).then((res) => setAtividades(res.data.atividades))
    } else {
      AdmInUserApi.atividades(key, admInUserId).then((res) =>
        setAtividades(res.data.atividades)
      )
    }
  }, [updateGraphics])

  return (
    <Container>
      <Header>
        <HeaderInfo>
          <Title>Atividades</Title>
        </HeaderInfo>
      </Header>
      <ItemList>
        {atividades?.map((atividade, index) => {
          return (
            <Item key={index}>
              <span>{atividade.criado}</span>-<span>{atividade.titulo}</span>
            </Item>
          )
        })}
      </ItemList>
    </Container>
  )
}

import React from 'react'
import { Container } from './style'

import { BiDollar } from 'react-icons/bi'
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai'

interface DoubleIconProps {
  isPlus: boolean
}

export const DoubleIcon = ({ isPlus }: DoubleIconProps) => {
  return (
    <Container>
      <div>
        <BiDollar size={32} fill="var(--primary)" />
      </div>
      <div>
        {isPlus ? (
          <AiFillPlusCircle size={18} fill="var(--primary)" />
        ) : (
          <AiFillMinusCircle size={18} fill="var(--primary)" />
        )}
      </div>
    </Container>
  )
}

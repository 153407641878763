import React from 'react'
import { useLocation } from 'react-router-dom'
import useWindowSize from '../../hooks/useWindowSize'
import { Home } from '../../pages/Home'
import { Header } from '../Header'
import { Sidebar } from '../Sidebar'
import { Container } from './style'

export const AdministrativeInUserLayout: React.FC = ({ children }) => {
  const { screenWidth } = useWindowSize()
  const { pathname } = useLocation()

  return (
    <Container>
      <Header showHalfHeader={false} />
      {screenWidth >= 1030 && <Sidebar />}
      {children}
    </Container>
  )
}

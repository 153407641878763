import { AxiosError } from 'axios'
import md5 from 'md5'
import React, { useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import { BiKey } from 'react-icons/bi'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { useHistory, useLocation } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import AuthApi from '../../services/AuthApi'
import Select from 'react-select'
import InputMask from 'react-input-mask'
import {
  Banner,
  ButtonArea,
  Container,
  Content,
  ForgotPasswordButton,
  Form,
  Grid,
  IconArea,
  Input,
  InputTitle,
  Item,
  Line,
  LinkButton,
  Logo,
  PasswordButton,
  SubmitButton,
  Title
} from './style'
import AdmApi from '../../services/AdmApi'
import IndividualApi from '../../services/IndividualApi'

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const RegisterCode: React.FC = () => {
  const {
    setStatus,
    setPlan,
    setKey,
    setHasUser,
    setHaveClients,
    setAdmHeaderInfo,
    setIndividualHeaderInfo
  } = useUser()
  const alert = useAlert()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [cel, setCel] = useState('')
  const [cpfCnpj, setCpfCnpj] = useState('')
  const [type, setType] = useState('PF')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [passwordIsHide, setPasswordIsHide] = useState(true)
  const [confirmPasswordIsHide, setConfirmPasswordIsHide] = useState(true)

  const validName = /[A-z][ ][A-z]/
  const validEmail = /\S+@\S+\.\S+/

  const options = [
    { value: 'PF', label: 'Pessoa Física' },
    { value: 'PJ', label: 'Pessoa Jurídica' }
  ]

  const url = window.location.href
  const splitedUrl = url.split('/')

  const code = splitedUrl[splitedUrl.length - 1]

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (type === 'PF' && cpfCnpj.replace(/\D/g, '').length < 11) {
      return alert.show('Cpf inválido')
    }

    if (type === 'PJ' && cpfCnpj.replace(/\D/g, '').length < 14) {
      return alert.show('Cpf inválido')
    }

    if (!validName.test(name)) {
      return alert.show('Nome inválido')
    }

    if (cel.replace(/\D+/g, '').length < 11) {
      return alert.show('Número de celular inválido')
    }

    // if (!validEmail.test(email)) {
    //   return alert.show('Email inválido')
    // }

    if (password.length < 6) {
      return alert.show('A senha deve conter pelo menos 6 dígitos')
    }

    if (password !== confirmPassword) {
      return alert.show('As senhas devem ser iguais')
    }

    const data: App.RegisterProps = {
      code: code,
      nome: name,
      doc: cpfCnpj,
      tipo_doc: type === 'PF' ? 'CPF' : 'CNPJ',
      tel: cel,
      // email: email,
      pwd: md5(password)
    }

    AuthApi.register(data)
      .then(() => {
        AuthApi.login(email, md5(password))
          .then((res) => {
            localStorage.setItem('@seven_key', res.data.key)
            localStorage.setItem('@seven_plan', res.data.plano)
            if (res.data.clientes) {
              localStorage.setItem('@seven_haveClients', 'true')
            } else {
              localStorage.setItem('@seven_haveClients', 'false')
            }
            setKey(res.data.key)
            setStatus(res.data.status)
            setPlan(res.data.plano)
            setHaveClients(res.data.clientes)
            setHasUser(true)
            if (res.data.plano === 'administrativo') {
              AdmApi.header_info(res.data.key).then((res) =>
                setAdmHeaderInfo(res.data.header)
              )
              history.push('/adm-dashboard')
            } else {
              IndividualApi.header_info(res.data.key).then((res) =>
                setIndividualHeaderInfo(res.data)
              )
              history.push('/dashboard')
            }
          })
          .catch(() => alert.show('Usuário ou senha incorretos.'))
      })
      .catch((e: AxiosError) => {
        alert.show(e.response?.data.error)
      })
  }

  useEffect(() => {
    if (code) {
      AuthApi.registerCode(code)
        .then((res) => {
          setName(res.data.name)
          // setEmail(res.data.email)
          setCel(res.data.tel)
        })
        .catch(() => {
          alert.show('Código inválido')
          history.push('/login')
        })
    } else {
      alert.show('Código inválido')
      history.push('/login')
    }
  }, [])

  return (
    <Container>
      <Banner />
      <Content>
        <Logo src="/assets/logo.png" />
        <Title>Finalizar cadastro</Title>
        <Form onSubmit={handleSubmit}>
          <Grid>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              <InputTitle style={{ marginBottom: '18px' }}>Tipo</InputTitle>
              <Select
                placeholder="Selecione..."
                onChange={(e) => {
                  setType(e!.value)
                  setCpfCnpj('')
                }}
                options={options}
              />
            </div>
            {type === 'PF' ? (
              <Item isValid={cpfCnpj.replace(/\D+/g, '').length === 11}>
                <InputTitle>Insira seu CPF</InputTitle>
                <InputMask
                  mask="999.999.999-99"
                  onChange={(e) => setCpfCnpj(e.target.value)}
                  placeholder="xxx.xxx.xxx-xx"
                  value={cpfCnpj}
                />
              </Item>
            ) : (
              <Item isValid={cpfCnpj.replace(/\D+/g, '').length === 14}>
                <InputTitle>Insira seu CNPJ</InputTitle>
                <InputMask
                  mask="99.999.999.9999-99"
                  onChange={(e) => setCpfCnpj(e.target.value)}
                  placeholder="xx.xxx.xxx.xxxx-xx"
                />
              </Item>
            )}

            <Item isValid={validName.test(name)}>
              <InputTitle>Nome completo</InputTitle>
              <Input
                onChange={(e) => setName(e.target.value)}
                placeholder="Digite aqui..."
                value={name}
              />
            </Item>
            <Item isValid={cel.replace(/\D+/g, '').length === 11}>
              <InputTitle>Celular </InputTitle>
              <InputMask
                mask="(99)99999-9999"
                onChange={(e) => setCel(e.target.value)}
                placeholder="(xx)xxxxx-xxxx"
                value={cel}
              />
            </Item>

            {/* <Item style={{ gridColumn: 'span 2' }} isValid={validEmail.test(email)}>
              <InputTitle>Email</InputTitle>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@gmail.com"
                value={email}
              />
            </Item> */}

            <Item isValid={password.length >= 6}>
              <InputTitle>Insira sua senha</InputTitle>
              <div>
                <Input
                  onChange={(e) => setPassword(e.target.value)}
                  type={passwordIsHide ? 'password' : ''}
                  placeholder="Digite aqui..."
                  value={password}
                />
                <PasswordButton
                  type="button"
                  onClick={() => setPasswordIsHide(!passwordIsHide)}
                >
                  {passwordIsHide ? (
                    <BsFillEyeFill size={22} fill="#aaa" />
                  ) : (
                    <BsFillEyeSlashFill size={22} fill="#aaa" />
                  )}
                </PasswordButton>
              </div>
            </Item>

            <Item
              isValid={
                password === confirmPassword &&
                password.length >= 1 &&
                confirmPassword.length >= 1
              }
            >
              <InputTitle>Confirme sua senha</InputTitle>
              <div>
                <Input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type={confirmPasswordIsHide ? 'password' : ''}
                  placeholder="Digite aqui..."
                  value={confirmPassword}
                />
                <PasswordButton
                  type="button"
                  onClick={() => setConfirmPasswordIsHide(!confirmPasswordIsHide)}
                >
                  {confirmPasswordIsHide ? (
                    <BsFillEyeFill size={22} fill="#aaa" />
                  ) : (
                    <BsFillEyeSlashFill size={22} fill="#aaa" />
                  )}
                </PasswordButton>
              </div>
            </Item>
          </Grid>

          <ButtonArea>
            {/* <ForgotPasswordButton
              onClick={() => history.push('/login')}
              type="button"
            >
              Voltar ao login
            </ForgotPasswordButton> */}
            <SubmitButton type="submit">ENVIAR</SubmitButton>
          </ButtonArea>
        </Form>
        <Line />
      </Content>
    </Container>
  )
}

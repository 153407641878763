import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'

interface UserContextData {
  hasUser: boolean
  setHasUser: React.Dispatch<React.SetStateAction<boolean>>
  createdNow: boolean
  setCreatedNow: React.Dispatch<React.SetStateAction<boolean>>
  key: string
  setKey: React.Dispatch<React.SetStateAction<string>>
  status: string
  setStatus: React.Dispatch<React.SetStateAction<string>>
  plan: string
  setPlan: React.Dispatch<React.SetStateAction<string>>
  logOut: () => void
  haveClients: boolean
  setHaveClients: React.Dispatch<React.SetStateAction<boolean>>
  admHeaderInfo: App.AdmHeaderInfo | undefined
  individualHeaderInfo: App.IndividualHeaderInfo | undefined
  setAdmHeaderInfo: React.Dispatch<
    React.SetStateAction<App.AdmHeaderInfo | undefined>
  >
  setIndividualHeaderInfo: React.Dispatch<
    React.SetStateAction<App.IndividualHeaderInfo | undefined>
  >
  choosedPlan: string
  setChoosedPlan: React.Dispatch<React.SetStateAction<string>>
  user: App.User | undefined
  isAdministrative: boolean
  hasClient: boolean
  updateAllProfileImages: () => void
  updateProfileImage: boolean
  admInUser: boolean
  setAdmInUser: React.Dispatch<React.SetStateAction<boolean>>
  updateGraphics: boolean
  reloadGraphics: () => void
  admInUserId: string
  setAdmInUserId: React.Dispatch<React.SetStateAction<string>>
  admInUserName: string
  setAdmInUserName: React.Dispatch<React.SetStateAction<string>>
  admInUserImg: string
  setAdmInUserImg: React.Dispatch<React.SetStateAction<string>>
  admInUserImgType: string
  setAdmInUserImgType: React.Dispatch<React.SetStateAction<string>>
}

interface UserProviderProps {
  children: ReactNode
}

export const UserContext = createContext({} as UserContextData)

export const UserProvider = ({ children }: UserProviderProps) => {
  const history = useHistory()

  const [hasUser, setHasUser] = useState(false)
  const [createdNow, setCreatedNow] = useState(false)
  const [key, setKey] = useState('')
  const [status, setStatus] = useState('')
  const [plan, setPlan] = useState('')
  const [haveClients, setHaveClients] = useState(false)
  const [choosedPlan, setChoosedPlan] = useState('')
  const [admHeaderInfo, setAdmHeaderInfo] = useState<App.AdmHeaderInfo>()
  const [individualHeaderInfo, setIndividualHeaderInfo] =
    useState<App.IndividualHeaderInfo>()
  const [updateProfileImage, setUpdateProfileImage] = useState(false)
  const [admInUser, setAdmInUser] = useState(false)
  const [admInUserId, setAdmInUserId] = useState('')
  const [admInUserName, setAdmInUserName] = useState('')
  const [admInUserImg, setAdmInUserImg] = useState('')
  const [admInUserImgType, setAdmInUserImgType] = useState('')

  const [updateGraphics, setUpdateGraphics] = useState(false)

  const reloadGraphics = () => {
    setUpdateGraphics(!updateGraphics)
  }
  const user = useMemo<App.User | undefined>(() => {
    if (!admHeaderInfo && !individualHeaderInfo) return undefined
    return {
      clientes: (admHeaderInfo?.num_clientes || 0) > 0,
      key: localStorage.getItem('@seven_key') as string,
      plano: (admHeaderInfo?.plano ||
        individualHeaderInfo?.plano) as App.User['plano'],
      status: 'ativo',
      dias: admHeaderInfo?.dias || individualHeaderInfo?.dias,
      nome: (admHeaderInfo?.nome || individualHeaderInfo?.nome) as App.User['nome'],
      imgtype: admHeaderInfo?.imgtype || individualHeaderInfo?.imgtype,
      img: admHeaderInfo?.img || individualHeaderInfo?.img
    }
  }, [admHeaderInfo, individualHeaderInfo])

  const isAdministrative = user?.plano === 'administrativo'

  const hasClient = useMemo(() => {
    return user?.clientes || false
  }, [user?.clientes])

  const updateAllProfileImages = () => {
    setUpdateProfileImage(!updateProfileImage)
  }

  const logOut = () => {
    localStorage.removeItem('@seven_key')
    localStorage.removeItem('@seven_plan')
    localStorage.removeItem('@seven_haveClients')
    setHasUser(false)
    setAdmHeaderInfo(undefined)
    setIndividualHeaderInfo(undefined)
    history.push('/login')
  }

  return (
    <UserContext.Provider
      value={{
        hasUser,
        setHasUser,
        createdNow,
        setCreatedNow,
        key,
        setKey,
        status,
        setStatus,
        plan,
        setPlan,
        logOut,
        haveClients,
        setHaveClients,
        admHeaderInfo,
        setAdmHeaderInfo,
        choosedPlan,
        setChoosedPlan,
        individualHeaderInfo,
        setIndividualHeaderInfo,
        user,
        isAdministrative,
        hasClient,
        updateAllProfileImages,
        updateProfileImage,
        admInUser,
        setAdmInUser,
        updateGraphics,
        reloadGraphics,
        admInUserId,
        setAdmInUserId,
        admInUserImg,
        setAdmInUserImg,
        admInUserImgType,
        setAdmInUserImgType,
        admInUserName,
        setAdmInUserName
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  return useContext(UserContext)
}

import styled from 'styled-components'

import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OverlayBackdrop = styled(Overlay)`
  background: rgba(95, 112, 106, 0.8);
  z-index: 15;
`

export const OverlayBody = styled(Overlay)`
  overflow-y: auto;
  z-index: 20;
`

export const Box = styled(motion.div)`
  width: 454px;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 24px;
  position: relative;
  z-index: 25;
  overflow-y: auto;
  border-radius: 10px;
`
export const Image = styled.img`
  width: 239px;
  height: 239px;
`

export const Title = styled.p`
  color: #453e3e;
  text-align: center;
  margin: 12px 0;
`

export const SubTitle = styled.p`
  color: var(--primary);
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`

export const Button = styled.button`
  width: 231px;
  height: 47px;
  background: var(--primary);
  border-radius: 100px;
  color: #fff;
  font-weight: 500;
  margin: 22px 0;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;

  p:nth-child(1) {
  }
  p:nth-child(2) {
    cursor: pointer;
    font-weight: 600;
  }
`

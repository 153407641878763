import styled from 'styled-components'

export const Container = styled.div`
  width: calc(100% - 145px);
  height: calc(100% - 130px);
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f8fa;

  @media (max-width: 1030px) {
    width: 100%;
    height: calc(100% - 80px);
  }
`

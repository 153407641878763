import {
  Box,
  BoxTop,
  CloseButton,
  Container,
  OverlayBackdrop,
  OverlayBody,
  Title,
  Wrapper
} from './style'

import { MdClose } from 'react-icons/md'
import { useModal } from '../../contexts/ModalContext'
import { Notification } from '../Notification'
import { useEffect, useState } from 'react'
import IndividualApi from '../../services/IndividualApi'
import { useUser } from '../../contexts/UserContext'
import AdmApi from '../../services/AdmApi'

interface NotificationsProps {
  id: number
  criado: string
  titulo: string
  texto: string
}

export const ModalNotification: React.FC = () => {
  const { key, isAdministrative } = useUser()
  const { closeNotificationModal, updatingNotification, updateNotification2 } =
    useModal()

  const [notifications, setNotifications] = useState<NotificationsProps[]>([])

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeNotificationModal()
    }
  }

  useEffect(() => {
    if (!isAdministrative) {
      IndividualApi.list_notify(key)
        .then((res) => setNotifications(res.data))
        .finally(() => {
          IndividualApi.view_notify(key).then(() => updatingNotification())
        })
    } else {
      AdmApi.list_notify(key)
        .then((res) => setNotifications(res.data))
        .finally(() => {
          AdmApi.view_notify(key).then(() => {
            updatingNotification()
          })
        })
    }
  }, [updateNotification2])

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ right: -500 }} animate={{ right: 0 }} exit={{ right: -500 }}>
        <BoxTop>
          <Title>Notificações</Title>
          <CloseButton onClick={closeNotificationModal} type="button">
            <MdClose size={26} />
            Fechar
          </CloseButton>
        </BoxTop>
        <Wrapper>
          {notifications.map((notification) => {
            return (
              <Notification
                title={notification.titulo}
                text={notification.texto}
                id={String(notification.id)}
                criado={notification.criado}
                key={notification.id}
              />
            )
          })}
        </Wrapper>
      </Box>
    </Container>
  )
}

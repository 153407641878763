import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f6f8fa;

  padding-top: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 52px;
`

export const Route = styled.div`
  p {
    color: #7f7f7f;
    font-size: 14px;
  }

  span {
    color: var(--primary);
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 32px;
  padding: 22px 52px;
  width: 100%;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    padding: 22px;
  }

  @media (max-width: 500px) {
    padding: 22px 12px;
  }
`

export const LeftBox = styled.div`
  min-width: 70%;
  max-width: 70%;
  display: flex;
  gap: 22px;

  @media (max-width: 1200px) {
    max-width: 100%;
    min-width: auto;
    height: auto;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const EntryOutProjectionBox = styled.div`
  min-width: 70%;
  max-width: 70%;
  display: flex;
  gap: 22px;

  @media (max-width: 1200px) {
    max-width: 100%;
    min-width: auto;
    width: 100%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    height: 1000px;
  }
`

export const RightBox = styled.div`
  width: 30%;

  @media (max-width: 1200px) {
    width: 100%;
  }
`

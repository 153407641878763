/* eslint-disable */
/* prettier-ignore */
import moment from 'moment'
import React, { FormEvent, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { BsCalendar4Week } from 'react-icons/bs'
import Select from 'react-select'
import { useModal } from '../../../../contexts/ModalContext'
import { useUser } from '../../../../contexts/UserContext'
import AdmInUserApi from '../../../../services/AdmInUserApi'
import IndividualApi from '../../../../services/IndividualApi'
import { CalendarPicker } from '../../../CalendarPicker'
import {
  InputTitleArea,
  InputTitleAreaWithButton
} from '../../../ModalCategory/style'
import {
  Checkbox,
  CheckboxArea,
  CheckboxLabel,
  DateLine,
  Form,
  Grid,
  Input,
  InputItem,
  InputTitle,
  SubmitButton,
  SubmitButtonArea
} from './style'
import CurrencyFormat from 'react-currency-format'

interface ClientsProps {
  id: number
  nome: string
  tel: string
  email: string
  desc: string
}

interface ProviderrProps {
  id: number
  nome: string
  tel: string
  email: string
  desc: string
}

interface AccountProps {
  id: number
  nome: string
  banco: string
  agencia: string
  conta: string
  tipo: string
  desc: string
}

interface CategoryProps {
  id: number
  nome: string
  desc: string
}

interface FormInstallmentProps {
  type: 'Entrada' | 'Saida'
}

interface ProductProps {
  id: number
  nome: string
  preco: string
  categoria: string
  sku: string
  obs: string
}

interface ParcelProps {
  data_prev: any
  valor: string
}

export const FormInstallment: React.FC<FormInstallmentProps> = ({ type }) => {
  const initialData: any = type

  const alert = useAlert()
  const { key, reloadGraphics, admInUser, admInUserId } = useUser()
  const {
    closePaymentModal,
    openPersonModal,
    personModalIsOpen,
    openCategoryModal,
    openNewBankAccountModal,
    categoryModalIsOpen,
    newBankAccountModalIsOpen,
    openProductModal,
    productModalIsOpen
  } = useModal()

  const [tipo, setTipo] = useState('Parcelado')
  const [cadastro, setCadastro] = useState(initialData)
  const [tipoPag, setTipoPag] = useState('Dinheiro')
  const [respPag, setRespPag] = useState('cliente')
  const [respNome, setRespNome] = useState('')
  const [projeto, setProjeto] = useState('')
  const [conta, setConta] = useState('')
  const [historico, setHistorico] = useState('')
  const [categoria, setCategoria] = useState('')
  const [installmentsQuantity, setInstallmentsQuantity] = useState('')

  const [installmentIsActive, setIstallmentIsActive] = useState(false)
  const [firstInstallmentDate, setFirstInstallmentDate] = useState('')
  const [firstPrice, setFirstPrice] = useState('')

  const [isSame, setIsSame] = useState(true)

  const [clientList, setClientList] = useState<ClientsProps[]>([])
  const [providerList, setProviderList] = useState<ProviderrProps[]>([])
  const [categoryList, setCategoryList] = useState<CategoryProps[]>([])
  const [accountList, setAccountList] = useState<AccountProps[]>([])
  const [productList, setProductList] = useState<ProductProps[]>([])

  const [parcel, setParcel] = useState<any[]>([])

  const ActiveInstallments = () => {
    if (installmentsQuantity === '') {
      return alert.show('Defina a quantidade de parcelas antes de prosseguir')
    }
    if (Number(installmentsQuantity) < 1 || Number(installmentsQuantity) > 24) {
      return alert.show('Quantidade de parcelas dever ser de 1 até 24')
    }
    handleParcel()
    setIstallmentIsActive(true)
  }

  useEffect(() => {
    if (firstInstallmentDate && Number(installmentsQuantity) > 0) {
      const firstDate = moment(firstInstallmentDate, 'YYYY-MM-DD')
      const nextDates = []

      for (let i = 1; i <= Number(installmentsQuantity); i++) {
        const nextDate = firstDate.clone().add(i, 'months')
        nextDates.push(nextDate.format('YYYY-MM-DD'))
      }

      setParcel(nextDates.map((date) => ({ data_prev: date, valor: '' })))
    }
  }, [firstInstallmentDate, installmentsQuantity])

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.list_clients(key).then((res) => {
        setClientList(res.data.clientes)
      })
      IndividualApi.list_forn(key).then((res) => {
        setProviderList(res.data.fornecedores)
      })
      IndividualApi.list_accounts(key).then((res) => {
        setAccountList(res.data.contas)
      })
      IndividualApi.list_categories(key).then((res) => {
        setCategoryList(res.data.categorias)
      })
      IndividualApi.list_products(key).then((res) => {
        setProductList(res.data.produtos)
      })
    } else {
      AdmInUserApi.list_clients(key, admInUserId).then((res) => {
        setClientList(res.data.clientes)
      })
      AdmInUserApi.list_forn(key, admInUserId).then((res) => {
        setProviderList(res.data.fornecedores)
      })
      AdmInUserApi.list_accounts(key, admInUserId).then((res) => {
        setAccountList(res.data.contas)
      })
      AdmInUserApi.list_categories(key, admInUserId).then((res) => {
        setCategoryList(res.data.categorias)
      })
      AdmInUserApi.list_products(key, admInUserId).then((res) => {
        setProductList(res.data.produtos)
      })
    }
  }, [
    personModalIsOpen,
    categoryModalIsOpen,
    newBankAccountModalIsOpen,
    productModalIsOpen
  ])

  const clean = () => {
    setTipo('Parcelado')
    setCadastro('Entrada')
    setTipoPag('Dinheiro')
    setRespPag('Cliente')
    setRespNome('')
    setProjeto('')
    setHistorico('')
    setCategoria('Categoria 1')
  }

  const handleParcel = () => {
    setParcel(
      Array.from({ length: Number(installmentsQuantity) }).map((value, index) => {
        return { data_prev: '', valor: '' }
      })
    )
  }

  const formatString = (value: string) => {
    const hasDot = value.includes('.')

    if (!hasDot || value.split('.')[1].length > 2) {
      return parseFloat(value).toFixed(2)
    }

    if (value.split('.')[1].length === 1) {
      return value
    }

    return parseFloat(value).toFixed(2)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const data: App.Installments = {
      key: key,
      tipo,
      cadastro: cadastro.toLowerCase(),
      tipo_pag: tipoPag,
      resp_pag: type === 'Entrada' ? 'Cliente' : 'Fornecedor',
      resp_nome: respNome,
      projeto: projeto,
      historico,
      categoria,
      conta,
      data_registro: moment().format('YYYY-MM-DD'),
      parcelas: parcel
    }

    if (parcel.length === 0) {
      return alert.show('Insira as parcelas')
    }

    if (!admInUser) {
      IndividualApi.new_par(data)
        .then((res) => {
          alert.show(res.data.text)
          clean()
          closePaymentModal()
        })
        .catch((error) => alert.show(error.response?.data.error))
        .finally(() => reloadGraphics())
    } else {
      AdmInUserApi.new_par(data, admInUserId)
        .then((res) => {
          alert.show(res.data.text)
          clean()
          closePaymentModal()
        })
        .catch((error) => alert.show(error.response?.data.error))
        .finally(() => reloadGraphics())
    }
  }

  const paymentOptions = [
    { value: 'Débito', label: 'Débito' },
    { value: 'Crédito', label: 'Crédito' },
    { value: 'Dinheiro', label: 'Dinheiro' },
    { value: 'Pix', label: 'Pix' },
    { value: 'Boleto', label: 'Boleto' },
    { value: 'Transferência', label: 'Transferência' },
    { value: '', label: '' }
  ]

  const whoWillMakePaymentOptions = [
    { value: 'cliente', label: 'Cliente' },
    { value: 'fornecedor', label: 'Fornecedor' }
  ]

  const clientsOptions = clientList?.map((cliente) => {
    return { value: cliente.nome, label: cliente.nome }
  })

  const providerOptions = providerList?.map((provider) => {
    return { value: provider.nome, label: provider.nome }
  })

  const categoriesOptions = categoryList?.map((category) => {
    return { value: category.nome, label: category.nome }
  })

  const preCategoriesOptions = [
    { value: 'Livros', label: 'Livros' },
    { value: 'Material escolar', label: 'Material escolar' },
    { value: 'Aluguel', label: 'Aluguel' },
    { value: 'Conta de luz', label: 'Conta de luz' },
    { value: 'Consulta médica', label: 'Consulta médica' },
    { value: 'Medicamento', label: 'Medicamento' },
    { value: 'Plano de saúde', label: 'Plano de saúde' },
    { value: 'Academia', label: 'Academia' },
    { value: 'Auxiliar doméstica', label: 'Auxiliar doméstica' },
    { value: 'Supermercado', label: 'Supermercado' },
    { value: '', label: '' }
  ]

  const finalCategoriesOptions = [...categoriesOptions, ...preCategoriesOptions]

  const accountOptions = accountList?.map((account) => {
    return { value: account.nome, label: account.nome }
  })

  const productOptions = productList?.map((product) => {
    return { value: product.nome, label: product.nome }
  })

  const preProductOptions = [
    { value: 'Educação', label: 'Educação' },
    { value: 'Saúde', label: 'Saúde' },
    { value: 'Pessoal', label: 'Pessoal' },
    { value: 'Doméstico', label: 'Doméstico' },
    { value: 'Investimento', label: 'Investimento' },
    { value: 'Profissional', label: 'Profissional' },
    { value: '', label: '' }
  ]

  const finalProductOptions = [...productOptions, ...preProductOptions]

  const customSelectStyles = {
    container: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#ffffff'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    control: (provided: any) => ({
      ...provided,
      background: 'transparent',
      border: 'none',
      color: '#768882'
    })
  }

  const handleChange = (index: any, e: any) => {
    setParcel((parcel) =>
      parcel.map((parcel, i) => (i === index ? (parcel.valor = e.target.value) : ''))
    )
  }

  const handleIsSame = () => {
    parcel.map((p) => {
      p.valor = firstPrice
    })
    setIstallmentIsActive(false)
  }

  return (
    <Form onSubmit={handleSubmit}>
      {installmentIsActive && (
        <CheckboxArea>
          <Checkbox
            type="checkbox"
            checked={isSame}
            onChange={() => setIsSame(!isSame)}
            name="issame"
            id="issame"
          />
          <CheckboxLabel>
            Todas as parcelas serão iguais, com a data em recorrência mensal e o valor igual para todas
          </CheckboxLabel>
        </CheckboxArea>
      )}
      {installmentIsActive ? (
        <Grid>
          {isSame ? (
            <React.Fragment>
              <InputItem>
                <InputTitleArea>
                  <InputTitle>Dia previsto</InputTitle>
                  <span>*</span>
                </InputTitleArea>
                <input
                  type="date"
                  onChange={(e) => setFirstInstallmentDate(e.target.value)}
                // onChange={(e) => (parcel.data_prev = e.target.value)}
                />
              </InputItem>
              <InputItem>
                <InputTitleArea>
                  <InputTitle>Valor total</InputTitle>
                  <span>*</span>
                </InputTitleArea>
                <div>
                  <CurrencyFormat
                    fixedDecimalScal={true}
                    onChange={(e: any) =>
                    (setFirstPrice(e.target.value.replace('R$', '')
                      .replace(/\./g, '')
                      .replace(',', '.')))
                    }
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    inputMode="numeric"
                  />
                </div>
              </InputItem>
            </React.Fragment>
          ) : (
            <>
              {parcel.map((parcel, index) => {
                return (
                  <React.Fragment key={index}>
                    <InputItem>
                      <InputTitleArea>
                        <InputTitle>Dia previsto</InputTitle>
                        <span>*</span>
                      </InputTitleArea>
                      <input
                        type="date"
                        placeholder="Teste"
                        onChange={(e) => (parcel.data_prev = e.target.value)}
                      />
                    </InputItem>
                    <InputItem>
                      <InputTitleArea>
                        <InputTitle>Valor total</InputTitle>
                        <span>*</span>
                      </InputTitleArea>
                      <div>
                        <CurrencyFormat
                          fixedDecimalScal={true}
                          onChange={(e: any) =>
                          (parcel.valor = e.target.value
                            .replace('R$', '')
                            .replace(/\./g, '')
                            .replace(',', '.'))
                          }
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          inputMode="numeric"
                        />
                      </div>
                    </InputItem>
                  </React.Fragment>
                )
              })}
            </>
          )}

          {/* <SubmitButtonArea> */}
          {isSame ? (
            <SubmitButton onClick={handleIsSame} type="button">
              Concluir
            </SubmitButton>
          ) : (
            <SubmitButton onClick={() => setIstallmentIsActive(false)} type="button">
              Concluir
            </SubmitButton>
          )}

          <SubmitButton onClick={handleIsSame} type="button">
            Ver parcelas
          </SubmitButton>
          {/* </SubmitButtonArea> */}
        </Grid>
      ) : (
        <Grid>
          <InputItem
            style={{
              borderBottomColor:
                Number(installmentsQuantity) > 0 &&
                  Number(installmentsQuantity) <= 24
                  ? 'var(--primary)'
                  : '#DF3347'
            }}
          >
            <InputTitleAreaWithButton>
              <InputTitle>Parcelas</InputTitle>
              <button onClick={ActiveInstallments} type="button">
                Personalizar
              </button>
            </InputTitleAreaWithButton>
            <div>
              <Input
                onChange={(e) => setInstallmentsQuantity(e.target.value)}
                value={installmentsQuantity}
                type="number"
                placeholder="Quantidade de parcelas"
                min="1"
                max="24"
              />
            </div>
          </InputItem>
          <InputItem>
            <InputTitleAreaWithButton>
              <InputTitle>
                Forma de {type === 'Entrada' ? 'recebimento' : 'pagamento'}
              </InputTitle>
            </InputTitleAreaWithButton>
            <div>
              <Select
                placeholder="Dinheiro"
                styles={customSelectStyles}
                onChange={(e) => {
                  setTipoPag(e!.value)
                }}
                options={paymentOptions.concat({ value: '', label: '' })}
              />
            </div>
          </InputItem>
          <InputItem>
            <InputTitleAreaWithButton>
              {type === 'Entrada' ? (
                <>
                  <InputTitle>Receber de</InputTitle>
                  <button onClick={() => openPersonModal('client')} type="button">
                    criar
                  </button>
                </>
              ) : (
                <>
                  <InputTitle>Pagar a</InputTitle>
                  <button onClick={() => openPersonModal('provider')} type="button">
                    criar
                  </button>
                </>
              )}
            </InputTitleAreaWithButton>
            <div>
              {type === 'Entrada' ? (
                <>
                  <Select
                    placeholder="Escolha um cliente"
                    noOptionsMessage={() => 'Nenhum cliente cadastrado'}
                    styles={customSelectStyles}
                    value={{ label: respNome, value: respNome }}
                    onChange={(e) => {
                      setRespNome(e!.value)
                    }}
                    options={clientsOptions.concat({ value: '', label: '' })}
                  />
                </>
              ) : (
                <>
                  <Select
                    placeholder="Escolha um fornecedor"
                    styles={customSelectStyles}
                    noOptionsMessage={() => 'Nenhum fornecedor cadastrado'}
                    value={{ label: respNome, value: respNome }}
                    onChange={(e) => {
                      setRespNome(e!.value)
                    }}
                    options={providerOptions.concat({ value: '', label: '' })}
                  />
                </>
              )}
            </div>
          </InputItem>
          <InputItem>
            <InputTitleAreaWithButton>
              <InputTitle>Centro de custo</InputTitle>
              <button onClick={openProductModal} type="button">
                criar
              </button>
            </InputTitleAreaWithButton>
            <div>
              <Select
                placeholder="Escolha um centro de custo"
                noOptionsMessage={() => 'Nenhuma categoria cadastrada'}
                styles={customSelectStyles}
                value={{ label: projeto, value: projeto }}
                onChange={(e) => {
                  setProjeto(e!.value)
                }}
                options={finalProductOptions}
              />
            </div>
          </InputItem>
          <InputItem>
            <InputTitleAreaWithButton>
              <InputTitle>Conta bancária</InputTitle>
              <button onClick={openNewBankAccountModal} type="button">
                criar
              </button>
            </InputTitleAreaWithButton>
            <div>
              <Select
                placeholder="Escolha uma conta bancária"
                styles={customSelectStyles}
                noOptionsMessage={() => 'Nenhuma conta bancária cadastrada'}
                onChange={(e) => {
                  setConta(e!.value)
                }}
                options={accountOptions.concat({ value: '', label: '' })}
              />
            </div>
          </InputItem>
          <InputItem>
            <InputTitleAreaWithButton>
              <InputTitle>Categoria</InputTitle>
              <button onClick={openCategoryModal} type="button">
                criar
              </button>
            </InputTitleAreaWithButton>
            <div>
              <Select
                placeholder="Escolha uma categoria"
                noOptionsMessage={() => 'Nenhuma categoria cadastrada'}
                styles={customSelectStyles}
                onChange={(e) => {
                  setCategoria(e!.value)
                }}
                options={finalCategoriesOptions}
              />
            </div>
          </InputItem>
          <InputItem style={{ gridColumn: 'span 2' }}>
            <InputTitleArea>
              <InputTitle>Observação</InputTitle>
            </InputTitleArea>
            <div>
              <Input
                onChange={(e) => setHistorico(e.target.value)}
                value={historico}
                placeholder="Digitar..."
              />
            </div>
          </InputItem>
        </Grid>
      )}
      {!installmentIsActive && (
        <SubmitButtonArea>
          <SubmitButton type="submit">Finalizar Cadastro</SubmitButton>
        </SubmitButtonArea>
      )}
    </Form>
  )
}

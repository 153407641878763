import moment from 'moment'
import React, { FormEvent, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import Select from 'react-select'
import { useModal } from '../../../../contexts/ModalContext'
import { useUser } from '../../../../contexts/UserContext'
import AdmInUserApi from '../../../../services/AdmInUserApi'
import IndividualApi from '../../../../services/IndividualApi'
import {
  InputTitleArea,
  InputTitleAreaWithButton
} from '../../../ModalCategory/style'
import { InputTitle } from '../../../ModalEditClient/style'
import {
  Form,
  Grid,
  Input,
  InputItem,
  SubmitButton,
  SubmitButtonArea
} from './style'
import CurrencyFormat from 'react-currency-format'

interface ClientsProps {
  id: number
  nome: string
  tel: string
  email: string
  desc: string
}

interface ProviderrProps {
  id: number
  nome: string
  tel: string
  email: string
  desc: string
}

interface AccountProps {
  id: number
  nome: string
  banco: string
  agencia: string
  conta: string
  tipo: string
  desc: string
}

interface CategoryProps {
  id: number
  nome: string
  desc: string
}

interface FormRecurrenceProps {
  type: 'Entrada' | 'Saida'
  isEdit?: boolean
  editData?: App.Recurrence
  editId?: string
}

interface ProductProps {
  id: number
  nome: string
  preco: string
  categoria: string
  sku: string
  obs: string
}

export const FormRecurrence: React.FC<FormRecurrenceProps> = ({
  type,
  isEdit,
  editData,
  editId
}) => {
  const initialData: any = type

  const alert = useAlert()
  const { key, reloadGraphics, admInUser, admInUserId } = useUser()
  const {
    closePaymentModal,
    openPersonModal,
    personModalIsOpen,
    openCategoryModal,
    openNewBankAccountModal,
    categoryModalIsOpen,
    newBankAccountModalIsOpen,
    updatingList,
    closeEditRecurrenceModal,
    openProductModal,
    productModalIsOpen
  } = useModal()

  const [tipo, setTipo] = useState('Recorrente')
  const [diaPrev, setDiaPrev] = useState('')
  const [cadastro, setCadastro] = useState(initialData)
  const [valor, setValor] = useState('')
  const [tipoPag, setTipoPag] = useState('Dinheiro')
  const [respPag, setRespPag] = useState('Receber de')
  const [respNome, setRespNome] = useState('')
  const [projeto, setProjeto] = useState('')
  const [conta, setConta] = useState('')
  const [historico, setHistorico] = useState('')
  const [categoria, setCategoria] = useState('')

  const [clientList, setClientList] = useState<ClientsProps[]>([])
  const [providerList, setProviderList] = useState<ProviderrProps[]>([])
  const [categoryList, setCategoryList] = useState<CategoryProps[]>([])
  const [accountList, setAccountList] = useState<AccountProps[]>([])
  const [productList, setProductList] = useState<ProductProps[]>([])

  useEffect(() => {
    if (isEdit) {
      setTipo(editData!.tipo)
      setDiaPrev(editData!.dia_prev)
      setCadastro(editData!.cadastro)
      setValor(editData!.valor.replace('.', ','))
      setTipoPag(editData!.tipo_pag)
      setRespPag(editData!.resp_pag)
      setRespNome(editData!.resp_nome)
      setProjeto(editData!.projeto)
      setConta(editData!.conta)
      setHistorico(editData!.historico)
      setCategoria(editData!.categoria)
    }
  }, [])

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.list_clients(key).then((res) => {
        setClientList(res.data.clientes)
      })
      IndividualApi.list_forn(key).then((res) => {
        setProviderList(res.data.fornecedores)
      })
      IndividualApi.list_accounts(key).then((res) => {
        setAccountList(res.data.contas)
      })
      IndividualApi.list_categories(key).then((res) => {
        setCategoryList(res.data.categorias)
      })
      IndividualApi.list_products(key).then((res) => {
        setProductList(res.data.produtos)
      })
    } else {
      AdmInUserApi.list_clients(key, admInUserId).then((res) => {
        setClientList(res.data.clientes)
      })
      AdmInUserApi.list_forn(key, admInUserId).then((res) => {
        setProviderList(res.data.fornecedores)
      })
      AdmInUserApi.list_accounts(key, admInUserId).then((res) => {
        setAccountList(res.data.contas)
      })
      AdmInUserApi.list_categories(key, admInUserId).then((res) => {
        setCategoryList(res.data.categorias)
      })
      AdmInUserApi.list_products(key, admInUserId).then((res) => {
        setProductList(res.data.produtos)
      })
    }
  }, [
    personModalIsOpen,
    categoryModalIsOpen,
    newBankAccountModalIsOpen,
    productModalIsOpen
  ])

  const formatString = (value: string) => {
    const hasDot = value.includes('.')

    if (!hasDot || value.split('.')[1].length > 2) {
      return parseFloat(value).toFixed(2)
    }

    if (value.split('.')[1].length === 1) {
      return value
    }

    return parseFloat(value).toFixed(2)
  }

  const data: App.Recurrence = {
    tipo,
    key,
    id: editId,
    dia_prev: diaPrev,
    cadastro: cadastro.toLowerCase(),
    valor: valor.replace('R$', '').replace(/\./g, '').replace(',', '.'),
    tipo_pag: tipoPag,
    resp_pag: type === 'Entrada' ? 'Cliente' : 'Fornecedor',
    resp_nome: respNome,
    projeto: projeto,
    historico,
    categoria,
    conta,
    data_registro: moment().format('YYYY-MM-DD')
  }

  const clean = () => {
    setTipo('Recorrente')
    setDiaPrev('')
    setCadastro('Entrada')
    setValor('')
    setTipoPag('Dinheiro')
    setRespPag('Cliente')
    setRespNome('')
    setProjeto('')
    setHistorico('')
    setCategoria('Categoria 1')
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (diaPrev !== '' && valor !== '') {
      if (!isEdit) {
        if (!admInUser) {
          IndividualApi.new_rec(data)
            .then((res) => {
              alert.show(res.data.text)
              clean()
              closePaymentModal()
            })
            .catch((error) => alert.show(error.response?.data.error))
            .finally(() => reloadGraphics())
        } else {
          AdmInUserApi.new_rec(data, admInUserId)
            .then((res) => {
              alert.show(res.data.text)
              clean()
              closePaymentModal()
            })
            .catch((error) => alert.show(error.response?.data.error))
            .finally(() => reloadGraphics())
        }
      } else {
        if (!admInUser) {
          IndividualApi.update_recurrence(data)
            .then((res) => {
              alert.show(res.data.text)
              updatingList()
              closeEditRecurrenceModal()
            })
            .finally(() => reloadGraphics())
        } else {
          AdmInUserApi.update_recurrence(data, admInUserId)
            .then((res) => {
              alert.show(res.data.text)
              updatingList()
              closeEditRecurrenceModal()
            })
            .finally(() => reloadGraphics())
        }
      }
    } else {
      alert.show('Preencha todos os campos')
    }
  }

  const paymentOptions = [
    { value: 'Débito', label: 'Débito' },
    { value: 'Crédito', label: 'Crédito' },
    { value: 'Dinheiro', label: 'Dinheiro' },
    { value: 'Pix', label: 'Pix' },
    { value: 'Boleto', label: 'Boleto' },
    { value: 'Transferência', label: 'Transferência' },
    { value: '', label: '' }
  ]

  const registerOptions = [
    { value: 'entrada', label: 'Entrada' },
    { value: 'saida', label: 'Saída' }
  ]

  const whoWillMakePaymentOptions = [
    { value: 'Receber de', label: 'Receber de' },
    { value: 'Pagar a', label: 'Pagar a' }
  ]

  const daysOptions = [
    { value: '01', label: 1 },
    { value: '02', label: 2 },
    { value: '03', label: 3 },
    { value: '04', label: 4 },
    { value: '05', label: 5 },
    { value: '06', label: 6 },
    { value: '07', label: 7 },
    { value: '08', label: 8 },
    { value: '09', label: 9 },
    { value: '10', label: 10 },
    { value: '11', label: 11 },
    { value: '12', label: 12 },
    { value: '13', label: 13 },
    { value: '14', label: 14 },
    { value: '15', label: 15 },
    { value: '16', label: 16 },
    { value: '17', label: 17 },
    { value: '18', label: 18 },
    { value: '19', label: 19 },
    { value: '20', label: 20 },
    { value: '21', label: 21 },
    { value: '22', label: 22 },
    { value: '23', label: 23 },
    { value: '24', label: 24 },
    { value: '25', label: 25 },
    { value: '26', label: 26 },
    { value: '27', label: 27 },
    { value: '28', label: 28 },
    { value: '29', label: 29 },
    { value: '30', label: 30 },
    { value: '31', label: 31 }
  ]

  const clientsOptions = clientList?.map((cliente) => {
    return { value: cliente.nome, label: cliente.nome }
  })

  const providerOptions = providerList?.map((provider) => {
    return { value: provider.nome, label: provider.nome }
  })

  const categoriesOptions = categoryList?.map((category) => {
    return { value: category.nome, label: category.nome }
  })

  const preCategoriesOptions = [
    { value: 'Livros', label: 'Livros' },
    { value: 'Material escolar', label: 'Material escolar' },
    { value: 'Aluguel', label: 'Aluguel' },
    { value: 'Conta de luz', label: 'Conta de luz' },
    { value: 'Consulta médica', label: 'Consulta médica' },
    { value: 'Medicamento', label: 'Medicamento' },
    { value: 'Plano de saúde', label: 'Plano de saúde' },
    { value: 'Academia', label: 'Academia' },
    { value: 'Auxiliar doméstica', label: 'Auxiliar doméstica' },
    { value: 'Supermercado', label: 'Supermercado' },
    { value: '', label: '' }
  ]

  const finalCategoriesOptions = [...categoriesOptions, ...preCategoriesOptions]

  const accountOptions = accountList?.map((account) => {
    return { value: account.nome, label: account.nome }
  })

  const productOptions = productList?.map((product) => {
    return { value: product.nome, label: product.nome }
  })

  const preProductOptions = [
    { value: 'Educação', label: 'Educação' },
    { value: 'Saúde', label: 'Saúde' },
    { value: 'Pessoal', label: 'Pessoal' },
    { value: 'Doméstico', label: 'Doméstico' },
    { value: 'Investimento', label: 'Investimento' },
    { value: 'Profissional', label: 'Profissional' },
    { value: '', label: '' }
  ]

  const finalProductOptions = [...productOptions, ...preProductOptions]

  const customSelectStyles: any = {
    container: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#ffffff'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    control: (provided: any) => ({
      ...provided,
      background: 'transparent',
      border: 'none',
      color: '#768882'
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Grid>
        <InputItem
          style={{
            borderBottomColor: diaPrev.length > 0 ? 'var(--primary)' : '#DF3347'
          }}
        >
          <InputTitleArea>
            <InputTitle>Data inicial</InputTitle>
            <span>*</span>
          </InputTitleArea>
          {/* <Select
              placeholder="Escolha um dia"
              styles={customSelectStyles}
              value={{ label: Number(diaPrev), value: diaPrev }}
              onChange={(e) => {
                setDiaPrev(e!.value)
              }}
              options={daysOptions}
            /> */}
          <input
            type="date"
            value={diaPrev}
            onChange={(e) => setDiaPrev(e.target.value)}
          />
        </InputItem>
        <InputItem
          style={{
            borderBottomColor: valor.length > 0 ? 'var(--primary)' : '#DF3347'
          }}
        >
          <InputTitleArea>
            <InputTitle>Valor</InputTitle>
            <span>*</span>
          </InputTitleArea>
          <CurrencyFormat
            value={valor}
            fixedDecimalScal={true}
            onChange={(e: any) => setValor(e.target.value)}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale={true}
            inputMode="numeric"
          />
        </InputItem>
        <InputItem>
          <InputTitleAreaWithButton>
            <InputTitle>
              Forma de {type === 'Entrada' ? 'recebimento' : 'pagamento'}
            </InputTitle>
          </InputTitleAreaWithButton>
          <div>
            <Select
              placeholder="Dinheiro"
              styles={customSelectStyles}
              value={{ label: tipoPag, value: tipoPag }}
              onChange={(e) => {
                setTipoPag(e!.value)
              }}
              options={paymentOptions.concat({ value: '', label: '' })}
            />
          </div>
        </InputItem>
        <InputItem>
          <InputTitleAreaWithButton>
            {type === 'Entrada' ? (
              <>
                <InputTitle>Receber de</InputTitle>
                <button onClick={() => openPersonModal('client')} type="button">
                  criar
                </button>
              </>
            ) : (
              <>
                <InputTitle>Pagar a</InputTitle>
                <button onClick={() => openPersonModal('provider')} type="button">
                  criar
                </button>
              </>
            )}
          </InputTitleAreaWithButton>
          <div>
            {type === 'Entrada' ? (
              <>
                <Select
                  placeholder="Escolha um cliente"
                  noOptionsMessage={() => 'Nenhum cliente cadastrado'}
                  styles={customSelectStyles}
                  value={{ label: respNome, value: respNome }}
                  onChange={(e) => {
                    setRespNome(e!.value)
                  }}
                  options={clientsOptions.concat({ value: '', label: '' })}
                />
              </>
            ) : (
              <>
                <Select
                  placeholder="Escolha um fornecedor"
                  noOptionsMessage={() => 'Nenhum fornecedor cadastrado'}
                  styles={customSelectStyles}
                  value={{ label: respNome, value: respNome }}
                  onChange={(e) => {
                    setRespNome(e!.value)
                  }}
                  options={providerOptions.concat({ value: '', label: '' })}
                />
              </>
            )}
          </div>
        </InputItem>
        <InputItem>
          <InputTitleAreaWithButton>
            <InputTitle>Centro de custo</InputTitle>
            <button onClick={openProductModal} type="button">
              criar
            </button>
          </InputTitleAreaWithButton>
          <div>
            <Select
              placeholder="Escolha um centro de custo"
              noOptionsMessage={() => 'Nenhuma categoria cadastrada'}
              styles={customSelectStyles}
              value={{ label: projeto, value: projeto }}
              onChange={(e) => {
                setProjeto(e!.value)
              }}
              options={finalProductOptions}
            />
          </div>
        </InputItem>
        <InputItem>
          <InputTitleAreaWithButton>
            <InputTitle>Conta bancária</InputTitle>
            <button onClick={openNewBankAccountModal} type="button">
              criar
            </button>
          </InputTitleAreaWithButton>
          <div>
            <Select
              placeholder="Escolha uma conta bancária"
              noOptionsMessage={() => 'Nenhuma conta bancária cadastrada'}
              styles={customSelectStyles}
              value={{ label: conta, value: conta }}
              onChange={(e) => {
                setConta(e!.value)
              }}
              options={accountOptions.concat({ value: '', label: '' })}
            />
          </div>
        </InputItem>
        <InputItem>
          <InputTitleAreaWithButton>
            <InputTitle>Categoria</InputTitle>
            <button onClick={openCategoryModal} type="button">
              criar
            </button>
          </InputTitleAreaWithButton>
          <div>
            <Select
              placeholder="Escolha uma categoria"
              noOptionsMessage={() => 'Nenhuma categoria cadastrada'}
              styles={customSelectStyles}
              value={{ label: categoria, value: categoria }}
              onChange={(e) => {
                setCategoria(e!.value)
              }}
              options={finalCategoriesOptions}
            />
          </div>
        </InputItem>
        <InputItem>
          <InputTitleArea>
            <InputTitle>Observação</InputTitle>
          </InputTitleArea>
          <Input
            onChange={(e) => setHistorico(e.target.value)}
            value={historico}
            placeholder="Digitar..."
          />
        </InputItem>
      </Grid>
      <SubmitButtonArea>
        <SubmitButton type="submit">Finalizar Cadastro</SubmitButton>
      </SubmitButtonArea>
    </Form>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f6f8fa;

  padding-top: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 52px;

  @media (max-width: 600px) {
    padding: 0 12px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 22px 0;

  @media (max-width: 815px) {
    flex-direction: column;
  }
`

export const Title = styled.h1`
  color: #343333;
  font-size: 20px;

  @media (max-width: 400px) {
    width: 280px;
    text-align: center;
  }
`

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 42px;

  @media (max-width: 815px) {
    flex-direction: column;
    gap: 0;
  }
`

export const Search = styled.div`
  background-color: #fff;
  border-radius: 4px;

  display: flex;
  align-items: center;
  height: 40px;
  width: 280px;
  box-shadow: 1px 1px 3px 0px rgba(215, 215, 215, 0.33);

  input {
    padding: 0 12px;
    width: 90%;
    height: 100%;
  }

  @media (max-width: 815px) {
    margin: 12px 0;
  }

  @media (max-width: 400px) {
    width: 280px;
  }
`

export const ColumnsButton = styled.button`
  /* width: 157px; */
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: #aaaaaa;
  border-radius: 4px;

  transition: filter 0.2s;

  div {
    background: #def1eb;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    filter: brightness(0.8);
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 22px;
`

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;

  @media (max-width: 1292px) {
    flex-direction: column;
    gap: 12px;
  }
`

export const WrapperHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperHeaderInfoTitle = styled.p`
  color: #555555;
  font-size: 18px;
  font-weight: 600;

  span {
    color: var(--primary);
    font-weight: 700;
  }

  @media (max-width: 570px) {
    text-align: center;
  }
`

export const WrapperHeaderInfoSubTitle = styled.p`
  color: #7f7f7f;
  font-weight: 500;
  font-size: 14px;

  @media (max-width: 570px) {
    text-align: center;
  }
`

export const WrapperHeaderFilterArea = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`

export const CheckboxItem = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    color: #555555;
    font-size: 14px;
  }
`

export const Checkbox = styled.input`
  width: 18px;
  height: 18px;
  accent-color: var(--primary);
`

export const TableArea = styled.div`
  overflow-x: auto;
  margin-top: 22px;
`

export const Table = styled.table`
  width: 100%;
  padding-top: 12px;
  border-collapse: collapse;
`

export const TableHead = styled.thead``
export const TableHeadRow = styled.tr`
  font-size: 12px;
  color: #555;
  font-weight: 300;
`
export const TableHeadItem = styled.th`
  text-align: center;
  padding-bottom: 8px;
`

export const TableBody = styled.tbody``
export const TableBodyRow = styled.tr`
  font-size: 14px;
  color: #333;
  margin-top: 12px;
  padding: 12px 0;

  .entrada {
    color: var(--primary);
  }

  .saida {
    color: #d9001b;
  }

  .pago {
    color: var(--primary);
  }

  .atrasado {
    color: #910012;
  }

  transition: background 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`
interface TableBodyItemProps {
  isVisible?: boolean
}

export const TableBodyItem = styled.th<TableBodyItemProps>`
  padding: 22px 4px;
  border: 1px solid rgba(21, 21, 21, 0.1);
  text-align: center;
  font-weight: 500;
  display: ${(props) => (props.isVisible ? '' : 'none')};
  overflow-x: auto;
  min-width: 150px;
  max-width: 250px;
`

import React, { useEffect, useState } from 'react'
import { BsPlus, BsSearch } from 'react-icons/bs'
import { IndividualContainer } from '../../components/IndividualContainer'
import { ListCard } from '../../components/ListCard'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'
import {
  Container,
  Header,
  NewButton,
  RightContent,
  Search,
  Title,
  Wrapper
} from './style'

interface CenterProps {
  id: number
  nome: string
  desc: string
}

export const ListCostCenters: React.FC = () => {
  const { openCostCenterModal, updateList } = useModal()

  const { key } = useUser()

  const [center, setCenters] = useState<CenterProps[]>()

  useEffect(() => {
    IndividualApi.list_centers(key).then((res) => setCenters(res.data.centros))
  }, [updateList])

  return (
    <IndividualContainer>
      <Container>
        <Header>
          <Title>Centros de Custo cadastrados ({center?.length})</Title>
          <RightContent>
            <Search>
              <input type="text" placeholder="Pesquisar" />
              <BsSearch size={22} fill="var(--primary)" />
            </Search>
            <NewButton onClick={() => openCostCenterModal()}>
              <BsPlus size={22} fill="#fff" />
              Novo centro de custo
            </NewButton>
          </RightContent>
        </Header>
        <Wrapper>
          {center?.map((center) => {
            return (
              <ListCard
                type="center"
                id={String(center.id)}
                key={center.id}
                otherData={{ nome: center.nome, descricao: center.desc }}
              />
            )
          })}
        </Wrapper>
      </Container>
    </IndividualContainer>
  )
}

import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { DropdownCard } from '../DropdownCard'
import {
  ButtonArea,
  Container,
  Content,
  Header,
  Info,
  Name,
  OptionButton,
  UserPhoto,
  Wrapper
} from './style'

interface ClientCardProps {
  id: number
  email: string
  tel: string
  nome: string
  img: string
  tipo_img: string
}

export const ClientCard: React.FC<ClientCardProps> = ({
  id,
  email,
  tel,
  nome,
  img,
  tipo_img
}) => {
  const [cardDropdownIsOpen, setCardDropdownIsOpen] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const cardDropdownRef = useRef<HTMLDivElement>(null)

  const toggleCardDropdown = () => {
    setCardDropdownIsOpen(!cardDropdownIsOpen)
  }
  const closeCardDropdown = () => {
    setCardDropdownIsOpen(false)
  }
  useEffect(() => {
    const checkIfClickedOutsideOne = (e: any) => {
      if (
        cardDropdownIsOpen &&
        cardDropdownRef.current &&
        !cardDropdownRef!.current!.contains(e.target)
      ) {
        closeCardDropdown()
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutsideOne)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutsideOne)
    }
  }, [cardDropdownIsOpen])

  return (
    <Container>
      <Header>
        <Info>
          <UserPhoto src={`data:image/${tipo_img};base64,${img}`} alt="user photo" />
          <Name>{nome}</Name>
        </Info>
        <ButtonArea ref={cardDropdownRef}>
          <OptionButton onClick={toggleCardDropdown}>
            <BsThreeDotsVertical size={26} />
          </OptionButton>
          <AnimatePresence key="card-dropdown">
            {cardDropdownIsOpen && (
              <DropdownCard
                hasDetails
                id={String(id)}
                type="client"
                data={{ nome, tel, email, img, tipo_img }}
              />
            )}
          </AnimatePresence>
        </ButtonArea>
      </Header>
      <Wrapper>
        <Content>{email}</Content>
        <Content>{tel}</Content>
      </Wrapper>
    </Container>
  )
}

import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmApi from '../../services/AdmApi'
import IndividualApi from '../../services/IndividualApi'
import {
  Box,
  Container,
  Input,
  InputArea,
  InputItem,
  InputTitle,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  Title
} from './style'

export const ModalUpgrade: React.FC = () => {
  const { closeUpgradeModal } = useModal()
  const { key, plan, isAdministrative } = useUser()
  const alert = useAlert()

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeUpgradeModal()
    }
  }

  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [texto, setTexto] = useState('')

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (nome === '') return alert.show('Preencha o nome')
    if (email === '') return alert.show('Preencha o email')

    if (isAdministrative) {
      AdmApi.upgrade_plan({ key, nome, email, tel, texto })
        .then((res) => {
          alert.show(res.data.text)
          closeUpgradeModal()
        })
        .catch(() => alert.show('Houve algum erro'))
    } else {
      IndividualApi.upgrade_plan({ key, nome, email, tel, texto })
        .then((res) => {
          alert.show(res.data.text)
          closeUpgradeModal()
        })
        .catch(() => alert.show('Houve algum erro'))
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
        <Title>Upgrade plano</Title>
        <InputArea onSubmit={handleSubmit}>
          <InputItem>
            <InputTitle>Nome</InputTitle>
            <Input value={nome} onChange={(e) => setNome(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Email</InputTitle>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Telefone</InputTitle>
            <Input value={tel} onChange={(e) => setTel(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Texto</InputTitle>
            <Input value={texto} onChange={(e) => setTexto(e.target.value)} />
          </InputItem>
          <SubmitButton type="submit">Criar</SubmitButton>
        </InputArea>
      </Box>
    </Container>
  )
}

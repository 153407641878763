import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 18px;
  padding: 12px 0;

  @media (max-width: 550px) {
    max-width: 70%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

interface ButtonProps {
  isActive: boolean
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  gap: 12px;

  div {
    background: ${(props) => (props.isActive ? '#C9EBDA' : '#dcdee6')};
    width: 18px;
    height: 18px;
    border-radius: 4px;

    color: ${(props) => (props.isActive ? 'var(--primary)' : '#a3a6b6')};

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background 0.2s;
  }

  transition: color 0.2s;

  font-size: 20px;
  margin-bottom: 12px;
  color: #5f6278;
  text-align: start;
  font-weight: bold;
`

export const Description = styled.p`
  color: #7f8299;
  font-size: 18px;
  max-width: 500px;
  margin-left: 30px;
`

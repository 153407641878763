import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 80px);
  margin-top: 80px;
`

export const Wrapper = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 0;
`

export const Grid = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 42px;
  margin-top: 42px;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

export const Title = styled.p`
  color: #403a3a;
  font-size: 22px;

  span {
    font-weight: bold;
  }

  @media (max-width: 445px) {
    width: 300px;
    text-align: center;
  }
`

export const Description = styled.span`
  color: #403a3a;
  font-size: 15px;
  width: 500px;
  text-align: center;
  margin-top: 12px;

  @media (max-width: 520px) {
    width: 300px;
  }
`

export const InputItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputTitle = styled.p`
  color: #333333;
  span {
    color: red;
  }

  @media (max-width: 640px) {
    text-align: center;
  }
`

export const Input = styled.input`
  width: 282px;
  height: 48px;
  padding: 0 12px;
  margin-top: 4px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0px rgba(215, 215, 215, 1);
  color: #333;

  &::placeholder {
    color: #a9b1b9;
  }
`

export const SubmitButton = styled.button`
  border: 1px solid var(--primary);
  color: var(--primary);
  width: 228px;
  height: 45px;
  border-radius: 100px;
  margin-left: auto;
  font-size: 16px;

  transition: background 0.2s, color 0.2s;

  &:hover {
    background: var(--primary);
    color: #fff;
  }

  @media (max-width: 640px) {
    margin: 0 auto;
  }
`
export const ConfigButton = styled.button`
  background: var(--primary);
  color: #fff;
  width: 228px;
  height: 45px;
  border-radius: 100px;
  margin-right: auto;
  font-size: 16px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 640px) {
    margin: 0 auto;
  }
`

export const InputTitleArea = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  span {
    color: red;
  }
`

export const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  .icon {
    color: #c4d3ce;
  }

  &:hover {
    .icon {
      color: #48bdd7;
    }
  }
`

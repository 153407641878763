import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }

  html {
     /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #ababab #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 14px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ababab;
    border-radius: 7px;
    border: 4px solid #ffffff;
  }
  }
  
  button, input {
    background: 0;
    border: 0;
  }
  
  button {
    cursor: pointer;
  }

  body {
    overflow-x: hidden;
  }

  :root {
    --primary: #00AB6A;
    --error: #D7001A;
  }
`

import styled from 'styled-components'

import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OverlayBackdrop = styled(Overlay)`
  background: rgba(132, 142, 142, 0.7);
  z-index: 15;
`

export const OverlayBody = styled(Overlay)`
  overflow-y: auto;
  z-index: 20;
`

export const Box = styled(motion.div)`
  width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background: #fff;
  padding: 24px;
  z-index: 25;
  overflow-y: auto;
`

export const Title = styled.p`
  font-weight: 500;

  span {
    color: var(--primary);
  }
`

export const Button = styled.a`
  background: var(--primary);
  color: #fff;
  font-weight: 600;
  border-radius: 6px;
  padding: 12px 22px;
  text-decoration: none;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

import React, { useEffect, useState } from 'react'
import { AlertContainer, AlertOverlay, Button, ButtonNo, ButtonYes } from './style'
import { useModal } from '../../contexts/ModalContext'

interface DeleteCustomAlertProps {
  message: string
  onConfirm: () => void
  onCancel: () => void
}

const DeleteCustomAlert: React.FC<DeleteCustomAlertProps> = ({
  message,
  onConfirm,
  onCancel
}) => {
  return (
    <AlertOverlay>
      <AlertContainer>
        <p>{message}</p>
        <ButtonYes onClick={onConfirm}>Sim</ButtonYes>
        <ButtonNo onClick={onCancel}>Cancelar</ButtonNo>
      </AlertContainer>
    </AlertOverlay>
  )
}

export default DeleteCustomAlert

import React, { useEffect, useState } from 'react'
import { IndividualContainer } from '../../components/IndividualContainer'
import Switch from 'react-switch'
import { FiBookmark } from 'react-icons/fi'
import { BsPlayFill, BsCalendar3 } from 'react-icons/bs'
import Picker from 'react-month-picker'
import {
  ButtonArea,
  ButtonGenereteReport,
  ButtonSaveAsModel,
  CalendarArea,
  CalendarWrapper,
  CalendarWrapperHeader,
  Container,
  Header,
  InputMonth,
  InputSaveAsModel,
  Left,
  LeftTitle,
  Option,
  Options,
  ReportModelItem,
  ReportModelList,
  Right,
  SaveAsModelArea,
  Title,
  Video,
  Wrapper
} from './style'
import { Calendar } from '@amir04lm26/react-modern-calendar-date-picker'
import { AiFillEye } from 'react-icons/ai'
import { BiTrash } from 'react-icons/bi'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'
import { useAlert } from 'react-alert'
import AdmInUserApi from '../../services/AdmInUserApi'

interface ModelProps {
  id: number
  nome: string
  saidas_categoria: boolean
  saidas_total: boolean
  faturamento: boolean
  lucro: boolean
  fat_rel_media: boolean
  lucro_rel_media: boolean
  media_fat: boolean
  media_lucro: boolean
}

export const Report: React.FC = () => {
  const alert = useAlert()
  const { key, admInUser, admInUserId } = useUser()
  const [start, setStart] = useState<any>('')
  const [end, setEnd] = useState<any>('')
  const [outputsByCategories, setOutputsByCategories] = useState(false)
  const [totalOutputs, setTotalOutputs] = useState(false)
  const [invoicing, setInvoicing] = useState(false)
  const [profit, setProfit] = useState(false)
  const [billingInRelationToAverage, setBillingInRelationToAverage] = useState(false)
  const [profitInRelationToAverage, setProfitInRelationToAverage] = useState(false)
  const [averageOfMonthlyBilling, setAverageOfMonthlyBilling] = useState(false)
  const [averageMonthlyProfit, setAverageMonthlyProfit] = useState(false)
  const [modelName, setModelName] = useState('')

  const [updateList, setUpdateList] = useState(false)

  const [models, setModels] = useState<ModelProps[]>()

  const [modelIsActive, setModelIsActive] = useState(false)

  const handleSubmit = () => {
    if (start === '' && end === '') {
      return alert.show('Selecione uma data inicial e final antes de prosseguir')
    }

    if (
      !outputsByCategories &&
      !totalOutputs &&
      !invoicing &&
      !profit &&
      !billingInRelationToAverage &&
      !profitInRelationToAverage &&
      !averageOfMonthlyBilling &&
      !averageMonthlyProfit
    ) {
      return alert.show('Selecione pelo menos um campo antes de prosseguir')
    }

    if (modelIsActive) {
      const data: App.GenerateReport = {
        key,
        start,
        end,
        out_category: outputsByCategories,
        out_total: totalOutputs,
        invoicing,
        profit,
        average_invoicing: billingInRelationToAverage,
        average_profit: profitInRelationToAverage,
        average_month: averageOfMonthlyBilling,
        average: averageMonthlyProfit,
        name_save: modelName
      }

      if (!admInUser) {
        IndividualApi.generate_report(data)
          .then((res) => {
            window.open(res.data.link)
            setUpdateList(!updateList)
          })
          .catch((e) => console.log(e))
      } else {
        AdmInUserApi.generate_report(data, admInUserId)
          .then((res) => {
            window.open(res.data.link)
            setUpdateList(!updateList)
          })
          .catch((e) => console.log(e))
      }
    } else {
      const data: App.GenerateReport = {
        key,
        start,
        end,
        out_category: outputsByCategories,
        out_total: totalOutputs,
        invoicing,
        profit,
        average_invoicing: billingInRelationToAverage,
        average_profit: profitInRelationToAverage,
        average_month: averageOfMonthlyBilling,
        average: averageMonthlyProfit
      }
      if (!admInUser) {
        IndividualApi.generate_report(data)
          .then((res) => window.open(res.data.link))
          .catch((e) => console.log(e))
      } else {
        AdmInUserApi.generate_report(data, admInUserId)
          .then((res) => window.open(res.data.link))
          .catch((e) => console.log(e))
      }
    }
  }

  const deleteModel = (id: number) => {
    if (!admInUser) {
      IndividualApi.delete_model(key, id).then((res) => {
        setUpdateList(!updateList)
        alert.show(res.data.text)
      })
    } else {
      AdmInUserApi.delete_model(key, id, admInUserId).then((res) => {
        setUpdateList(!updateList)
        alert.show(res.data.text)
      })
    }
  }

  const setModelConfig = (model: ModelProps) => {
    setOutputsByCategories(model.saidas_categoria)
    setTotalOutputs(model.saidas_total)
    setInvoicing(model.faturamento)
    setProfit(model.lucro)
    setBillingInRelationToAverage(model.fat_rel_media)
    setProfitInRelationToAverage(model.lucro_rel_media)
    setAverageOfMonthlyBilling(model.media_fat)
    setAverageMonthlyProfit(model.media_lucro)
  }

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.list_models(key).then((res) => setModels(res.data.models))
    } else {
      AdmInUserApi.list_models(key, admInUserId).then((res) =>
        setModels(res.data.models)
      )
    }
  }, [updateList])

  return (
    <IndividualContainer>
      <Container>
        <Header>
          <Title>Gere relatórios personalizados e entenda seu negócio</Title>
        </Header>
        <Wrapper>
          <Left>
            <LeftTitle>
              Escolha o intervale de datas que deseja gerar o relatório{' '}
              <span>(Obrigatório)</span>
            </LeftTitle>
            <CalendarArea>
              <CalendarWrapper>
                <CalendarWrapperHeader>
                  <BsCalendar3 fill="#999" size={20} />
                  <span>Mês de início</span>
                </CalendarWrapperHeader>
                <InputMonth
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                  type="month"
                  name="start"
                  id="start"
                />
              </CalendarWrapper>
              <CalendarWrapper>
                <CalendarWrapperHeader>
                  <BsCalendar3 fill="#999" size={20} />
                  <span>Mês final</span>
                </CalendarWrapperHeader>
                <InputMonth
                  value={end}
                  onChange={(e) => setEnd(e.target.value)}
                  type="month"
                  name="end"
                  id="end"
                />
              </CalendarWrapper>
            </CalendarArea>
            <LeftTitle>
              Personalize as informações que deseja exibir no relatório
            </LeftTitle>
            <Options>
              <Option>
                <Switch
                  className="switch"
                  onChange={() => setOutputsByCategories(!outputsByCategories)}
                  checked={outputsByCategories}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#34BFA3"
                  height={20}
                  width={40}
                  offColor="#999"
                  offHandleColor="#fff"
                />
                <p>Saídas por categorias</p>
              </Option>
              <Option>
                <Switch
                  className="switch"
                  onChange={() => setTotalOutputs(!totalOutputs)}
                  checked={totalOutputs}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#34BFA3"
                  height={20}
                  width={40}
                  offColor="#999"
                  offHandleColor="#fff"
                />
                <p>Saídas totais</p>
              </Option>
              <Option>
                <Switch
                  className="switch"
                  onChange={() => setInvoicing(!invoicing)}
                  checked={invoicing}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#34BFA3"
                  height={20}
                  width={40}
                  offColor="#999"
                  offHandleColor="#fff"
                />
                <p>Faturamento</p>
              </Option>
              <Option>
                <Switch
                  className="switch"
                  onChange={() => setProfit(!profit)}
                  checked={profit}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#34BFA3"
                  height={20}
                  width={40}
                  offColor="#999"
                  offHandleColor="#fff"
                />
                <p>Lucro</p>
              </Option>
              <Option>
                <Switch
                  className="switch"
                  onChange={() =>
                    setBillingInRelationToAverage(!billingInRelationToAverage)
                  }
                  checked={billingInRelationToAverage}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#34BFA3"
                  height={20}
                  width={40}
                  offColor="#999"
                  offHandleColor="#fff"
                />
                <p>Faturamento em relação a média</p>
              </Option>
              <Option>
                <Switch
                  className="switch"
                  onChange={() =>
                    setProfitInRelationToAverage(!profitInRelationToAverage)
                  }
                  checked={profitInRelationToAverage}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#34BFA3"
                  height={20}
                  width={40}
                  offColor="#999"
                  offHandleColor="#fff"
                />
                <p>Lucro em relação a média</p>
              </Option>
              <Option>
                <Switch
                  className="switch"
                  onChange={() =>
                    setAverageOfMonthlyBilling(!averageOfMonthlyBilling)
                  }
                  checked={averageOfMonthlyBilling}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#34BFA3"
                  height={20}
                  width={40}
                  offColor="#999"
                  offHandleColor="#fff"
                />
                <p>Média de faturamento mensal</p>
              </Option>
              <Option>
                <Switch
                  className="switch"
                  onChange={() => setAverageMonthlyProfit(!averageMonthlyProfit)}
                  checked={averageMonthlyProfit}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#34BFA3"
                  height={20}
                  width={40}
                  offColor="#999"
                  offHandleColor="#fff"
                />
                <p>Média de Lucro mensal</p>
              </Option>
            </Options>
            <ButtonArea>
              <SaveAsModelArea>
                <ButtonSaveAsModel
                  isActive={modelIsActive}
                  onClick={() => setModelIsActive(!modelIsActive)}
                >
                  <FiBookmark size={22} />
                  Salvar como Modelo
                </ButtonSaveAsModel>
                {modelIsActive && (
                  <InputSaveAsModel
                    value={modelName}
                    onChange={(e) => setModelName(e.target.value)}
                    placeholder="Digite o nome do modelo"
                  />
                )}
              </SaveAsModelArea>
              <ButtonGenereteReport onClick={handleSubmit}>
                Gerar relatório
              </ButtonGenereteReport>
            </ButtonArea>
          </Left>
          <Right>
            <Video>
              <img src="./assets/report/video.png" alt="video" />
              <a href="#">
                <BsPlayFill style={{ marginLeft: '4px' }} size={32} fill="#fff" />
              </a>
              <div>
                <p>Aprenda a gerar</p>
                <p>relatórios personalizados</p>
              </div>
            </Video>
            <Title style={{ textAlign: 'center' }}>Meus modelos</Title>
            <ReportModelList>
              {models?.map((model, index) => {
                return (
                  <ReportModelItem key={index}>
                    <span>{model.nome}</span>
                    <div>
                      <button onClick={() => setModelConfig(model)}>
                        <AiFillEye fill="rgba(121, 142, 136, 0.67)" size={20} />
                      </button>
                      <button onClick={() => deleteModel(model.id)}>
                        <BiTrash fill="rgba(121, 142, 136, 0.67)" size={20} />
                      </button>
                    </div>
                  </ReportModelItem>
                )
              })}
            </ReportModelList>
          </Right>
        </Wrapper>
      </Container>
    </IndividualContainer>
  )
}

import {
  Box,
  BoxTop,
  CloseButton,
  Container,
  Form,
  Grid,
  Input,
  InputItem,
  InputSeparator,
  InputTitle,
  InputTitleArea,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  SubmitButtonArea,
  Title,
  Tooltip,
  Wrapper
} from './style'

import { FormEvent, useState } from 'react'
import { useAlert } from 'react-alert'
import { MdClose } from 'react-icons/md'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'
import AdmInUserApi from '../../services/AdmInUserApi'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'

import { AiOutlineQuestionCircle } from 'react-icons/ai'

export const ModalNewBankAccount: React.FC = () => {
  const { closeNewBankAccountModal, updatingList } = useModal()
  const { key, admInUser, admInUserId } = useUser()
  const alert = useAlert()

  const [accountName, setAccountName] = useState('')
  const [bankName, setBankName] = useState('')
  const [agency, setAgency] = useState('')
  const [account, setAccount] = useState('')
  const [type, setType] = useState('')
  const [description, setDescription] = useState('')

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeNewBankAccountModal()
    }
  }

  const customSelectStyles = {
    container: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#ffffff'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    control: (provided: any) => ({
      ...provided,
      background: 'transparent',
      border: 'none',
      color: '#768882'
    })
  }

  const typeOptions = [
    { value: 'Empresarial', label: 'Empresarial' },
    { value: 'Pessoal', label: 'Pessoal' },
    { value: 'Conta corrente', label: 'Conta corrente' },
    { value: 'Conta poupança', label: 'Conta poupança' }
  ]

  const clean = () => {
    setAccountName('')
    setBankName('')
    setAgency('')
    setAccount('')
    setType('')
    setDescription('')
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const data: App.NewBankAccount = {
      key,
      agencia: agency,
      nome: accountName,
      banco: bankName,
      conta: account,
      tipo: type,
      descricao: description
    }

    if (accountName !== '') {
      if (!admInUser) {
        IndividualApi.new_bank_account(data)
          .then((res) => {
            alert.show(res.data.text)
            clean()
            updatingList()
            closeNewBankAccountModal()
          })
          .catch((e) => console.log(e))
      } else {
        AdmInUserApi.new_bank_account(data, admInUserId)
          .then((res) => {
            alert.show(res.data.text)
            clean()
            updatingList()
            closeNewBankAccountModal()
          })
          .catch((e) => console.log(e))
      }
    } else {
      alert.show('Os campos marcados com * são obrigatórios')
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ left: -530 }} animate={{ left: 0 }} exit={{ left: -530 }}>
        <BoxTop>
          <Title>
            Cadastre uma
            <span> nova conta bancária</span>
          </Title>
          <CloseButton onClick={closeNewBankAccountModal} type="button">
            <MdClose size={26} />
            Fechar
          </CloseButton>
        </BoxTop>
        <Wrapper>
          <Form onSubmit={handleSubmit}>
            <Grid>
              <InputItem
                style={{
                  borderBottomColor:
                    accountName.length > 0 ? 'var(--primary)' : '#DF3347',
                  gridColumn: 'span 2'
                }}
              >
                <InputTitleArea>
                  <InputTitle>Nome da conta para identificação</InputTitle>
                  <span>*</span>
                </InputTitleArea>
                <Input
                  onChange={(e) => setAccountName(e.target.value)}
                  value={accountName}
                  placeholder="Digitar..."
                />
              </InputItem>
              <InputItem>
                <InputTitleArea>
                  <InputTitle>Nome do Banco</InputTitle>
                </InputTitleArea>
                <div>
                  <Input
                    onChange={(e) => setBankName(e.target.value)}
                    value={bankName}
                    placeholder="Digitar..."
                  />
                </div>
              </InputItem>
              <InputSeparator>
                <InputItem>
                  <InputTitleArea>
                    <InputTitle>Agência</InputTitle>
                  </InputTitleArea>
                  {/* <div> */}
                  <Input
                    onChange={(e) => setAgency(e.target.value)}
                    value={agency}
                    placeholder="Digitar..."
                  />
                  {/* </div> */}
                </InputItem>
                <InputItem>
                  <InputTitleArea>
                    <InputTitle>Conta</InputTitle>
                  </InputTitleArea>
                  {/* <div> */}
                  <Input
                    onChange={(e) => setAccount(e.target.value)}
                    value={account}
                    placeholder="Digitar..."
                  />
                  {/* </div> */}
                </InputItem>
              </InputSeparator>
              <div />
              <InputItem>
                <InputTitleArea>
                  <InputTitle>Tipo</InputTitle>
                </InputTitleArea>
                <div>
                  <Select
                    placeholder="Tipo"
                    styles={customSelectStyles}
                    onChange={(e) => {
                      setType(e!.value)
                    }}
                    options={typeOptions}
                  />
                </div>
              </InputItem>
              <InputItem style={{ gridColumn: 'span 3' }}>
                <InputTitleArea>
                  <InputTitle>Descrição da conta</InputTitle>
                </InputTitleArea>
                <div>
                  <Input
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    placeholder="Digitar..."
                  />
                </div>
              </InputItem>
            </Grid>
            <SubmitButtonArea>
              <SubmitButton type="submit">Finalizar Cadastro</SubmitButton>
            </SubmitButtonArea>
          </Form>
        </Wrapper>
      </Box>
    </Container>
  )
}

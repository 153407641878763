import {
  Box,
  BoxTop,
  CloseButton,
  Container,
  Form,
  Grid,
  Input,
  InputItem,
  InputTitle,
  InputTitleArea,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  SubmitButtonArea,
  Title,
  Wrapper
} from './style'

import { FormEvent, useState } from 'react'
import { useAlert } from 'react-alert'
import { MdClose } from 'react-icons/md'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'
import AdmInUserApi from '../../services/AdmInUserApi'

interface ModalPersonProps {
  type: 'client' | 'provider'
}

export const ModalPerson: React.FC<ModalPersonProps> = ({ type }) => {
  const { closePersonModal, updatingList } = useModal()
  const { key, admInUser, admInUserId } = useUser()
  const alert = useAlert()

  const [name, setName] = useState('')
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')
  const [description, setDescription] = useState('')

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closePersonModal()
    }
  }

  const clean = () => {
    setName('')
    setTel('')
    setEmail('')
    setDescription('')
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const data: App.NewPerson = {
      key,
      name,
      tel,
      email,
      desc: description
    }

    if (type === 'client') {
      if (!admInUser) {
        IndividualApi.new_client(data)
          .then((res) => {
            alert.show(res.data.text)
            clean()
            closePersonModal()
            updatingList()
          })
          .catch((error) => alert.show(error.response?.data.error))
      } else {
        AdmInUserApi.new_client(data, admInUserId)
          .then((res) => {
            alert.show(res.data.text)
            clean()
            closePersonModal()
            updatingList()
          })
          .catch((error) => alert.show(error.response?.data.error))
      }
    } else {
      if (!admInUser) {
        IndividualApi.new_provider(data)
          .then((res) => {
            alert.show(res.data.text)
            clean()
            closePersonModal()
            updatingList()
          })
          .catch((error) => alert.show(error.response?.data.error))
      } else {
        AdmInUserApi.new_provider(data, admInUserId)
          .then((res) => {
            alert.show(res.data.text)
            clean()
            closePersonModal()
            updatingList()
          })
          .catch((error) => alert.show(error.response?.data.error))
      }
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ left: -530 }} animate={{ left: 0 }} exit={{ left: -530 }}>
        <BoxTop>
          {type === 'client' && <Title>Cadastre quem vai te pagar</Title>}
          {type === 'provider' && <Title>Cadastre para quem você vai pagar</Title>}
          <CloseButton onClick={closePersonModal} type="button">
            <MdClose size={26} />
            Fechar
          </CloseButton>
        </BoxTop>
        <Wrapper>
          <Form onSubmit={handleSubmit}>
            <Grid>
              <InputItem
                style={{
                  borderBottomColor: name.length > 0 ? 'var(--primary)' : '#DF3347',
                  gridColumn: 'span 2'
                }}
              >
                <InputTitleArea>
                  <InputTitle>
                    Nome do {type === 'client' ? 'pagador' : 'fornecedor'}
                  </InputTitle>
                  <span>*</span>
                </InputTitleArea>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Digitar..."
                />
              </InputItem>
              <InputItem>
                <InputTitleArea>
                  <InputTitle>
                    Telefone do {type === 'client' ? 'pagador' : 'fornecedor'}
                  </InputTitle>
                </InputTitleArea>
                <div>
                  <Input
                    onChange={(e) => setTel(e.target.value)}
                    value={tel}
                    placeholder="Digitar..."
                  />
                </div>
              </InputItem>
              <InputItem>
                <InputTitleArea>
                  <InputTitle>
                    E-mail do {type === 'client' ? 'pagador' : 'fornecedor'}
                  </InputTitle>
                </InputTitleArea>
                <div>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Digitar..."
                  />
                </div>
              </InputItem>
              <InputItem style={{ gridColumn: 'span 2' }}>
                <InputTitleArea>
                  <InputTitle>
                    Descrição do {type === 'client' ? 'pagador' : 'fornecedor'}
                  </InputTitle>
                </InputTitleArea>
                <div>
                  <Input
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    placeholder="Digitar..."
                  />
                </div>
              </InputItem>
            </Grid>
            <SubmitButtonArea>
              <SubmitButton type="submit">Finalizar Cadastro</SubmitButton>
            </SubmitButtonArea>
          </Form>
        </Wrapper>
      </Box>
    </Container>
  )
}

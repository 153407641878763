import {
  Box,
  BoxTop,
  ChooseArea,
  ChooseItem,
  CloseButton,
  Container,
  InputTitle,
  InputTitleArea,
  OverlayBackdrop,
  OverlayBody,
  Title,
  Tooltip,
  Wrapper
} from './style'

import 'moment-timezone'
import ReactTooltip from 'react-tooltip'

import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { BsCalendar4Week, BsClockHistory, BsHourglassBottom } from 'react-icons/bs'

import { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useModal } from '../../contexts/ModalContext'
import { FormInstallment } from '../layout/ModalPayment/FormInstallment'
import { FormRecurrence } from '../layout/ModalPayment/FormRecurrence'
import { FormSingle } from '../layout/ModalPayment/FormSingle'

interface ModalPaymentProps {
  type: 'Entrada' | 'Saida'
}

export const ModalPayment: React.FC<ModalPaymentProps> = ({ type }) => {
  const { closePaymentModal } = useModal()

  const [firstChooseIsActive, setFirstChooseIsActive] = useState(true)
  const [secondChooseIsActive, setSecondChooseIsActive] = useState(false)
  const [thirdChooseIsActive, setThirdChooseIsActive] = useState(false)

  const activeFirstChoose = () => {
    setFirstChooseIsActive(true)
    setSecondChooseIsActive(false)
    setThirdChooseIsActive(false)
  }
  const activeSecondChoose = () => {
    setFirstChooseIsActive(false)
    setSecondChooseIsActive(true)
    setThirdChooseIsActive(false)
  }
  const activeThirdChoose = () => {
    setFirstChooseIsActive(false)
    setSecondChooseIsActive(false)
    setThirdChooseIsActive(true)
  }

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closePaymentModal()
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ left: -530 }} animate={{ left: 0 }} exit={{ left: -530 }}>
        <BoxTop>
          {type === 'Entrada' ? (
            <>
              <Title>Cadastre uma nova entrada</Title>
            </>
          ) : (
            <>
              <Title>Cadastre uma nova saída</Title>
            </>
          )}

          <CloseButton onClick={closePaymentModal} type="button">
            <MdClose size={26} />
            Fechar
          </CloseButton>
        </BoxTop>
        <Wrapper>
          <InputTitleArea>
            <InputTitle>Tipo do lançamento</InputTitle>
            <Tooltip
              data-for="paymenttype"
              data-tip="Selecione um tipo de lançamento, sendo único a entrada <br> que vai acontecer uma só vez, recorrente um lançamento <br> que se repete mensalmente e parcelado um lançamento <br> que vai acontecer durante certo período."
            >
              <AiOutlineQuestionCircle className="icon" size={16} />
              <ReactTooltip
                id="paymenttype"
                place="bottom"
                backgroundColor="#2D3B36"
                effect="solid"
                html={true}
              />
            </Tooltip>
          </InputTitleArea>
          <ChooseArea>
            <ChooseItem onClick={activeFirstChoose} isActive={firstChooseIsActive}>
              <BsHourglassBottom size={20} fill="rgba(170, 170, 170, 0.54)" />
              <span>Único</span>
            </ChooseItem>
            <ChooseItem onClick={activeSecondChoose} isActive={secondChooseIsActive}>
              <BsClockHistory size={20} fill="rgba(170, 170, 170, 0.54)" />
              <span>Recorrente</span>
            </ChooseItem>
            <ChooseItem onClick={activeThirdChoose} isActive={thirdChooseIsActive}>
              <BsCalendar4Week size={20} fill="rgba(170, 170, 170, 0.54)" />
              <span>Parcelado</span>
            </ChooseItem>
          </ChooseArea>
          {firstChooseIsActive && <FormSingle type={type} />}
          {secondChooseIsActive && <FormRecurrence type={type} />}
          {thirdChooseIsActive && <FormInstallment type={type} />}
        </Wrapper>
      </Box>
    </Container>
  )
}

import {
  Box,
  Button,
  Container,
  Image,
  Info,
  OverlayBackdrop,
  OverlayBody,
  SubTitle,
  Title
} from './style'

import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import { useHistory } from 'react-router-dom'
export const ModalWelcome: React.FC = () => {
  const { closeWelcomeModal } = useModal()
  const { user } = useUser()
  const history = useHistory()

  const navigateToSupport = () => {
    history.push('/support')
  }

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeWelcomeModal()
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
        <Image src="./assets/welcome-banner.svg" />
        <Title>Seja bem vindo ao 7 Finance {user?.nome} </Title>
        <SubTitle>
          Para poder utilizar o sistema você precisa cadastrar seu primeiro cliente
        </SubTitle>
        <Button onClick={closeWelcomeModal}>Vamos lá</Button>
        <Info>
          <p>Está com dúvidas?</p>
          <p onClick={navigateToSupport}>Acesse nosso suporte</p>
        </Info>
      </Box>
    </Container>
  )
}

import React, { useEffect, useState } from 'react'
import { BsPlus, BsSearch } from 'react-icons/bs'
import { IndividualContainer } from '../../components/IndividualContainer'
import { ListCard } from '../../components/ListCard'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmInUserApi from '../../services/AdmInUserApi'
import IndividualApi from '../../services/IndividualApi'
import {
  Container,
  Header,
  NewButton,
  RightContent,
  Search,
  Title,
  Wrapper
} from './style'

interface CategoryProps {
  id: number
  nome: string
  desc: string
}

export const ListCategories: React.FC = () => {
  const { openCategoryModal, updateList } = useModal()
  const { key, admInUser, admInUserId } = useUser()

  const [categories, setCategories] = useState<CategoryProps[]>()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.list_categories(key).then((res) =>
        setCategories(res.data.categorias)
      )
    } else {
      AdmInUserApi.list_categories(key, admInUserId).then((res) =>
        setCategories(res.data.categorias)
      )
    }
  }, [updateList])

  return (
    <IndividualContainer>
      <Container>
        <Header>
          <Title>Categorias cadastrados ({categories?.length})</Title>
          <RightContent>
            <Search>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Pesquisar"
              />
              <BsSearch size={22} fill="var(--primary)" />
            </Search>
            <NewButton onClick={openCategoryModal}>
              <BsPlus size={22} fill="#fff" />
              Novo categoria
            </NewButton>
          </RightContent>
        </Header>
        <Wrapper>
          {categories
            // eslint-disable-next-line array-callback-return
            ?.filter((item) => {
              if (search === '') {
                return item
              } else if (item.nome.toLowerCase().includes(search.toLowerCase())) {
                return item
              }
            })
            .map((category) => {
              return (
                <ListCard
                  type="category"
                  id={String(category.id)}
                  key={category.id}
                  otherData={{ nome: category.nome, descricao: category.desc }}
                />
              )
            })}
        </Wrapper>
      </Container>
    </IndividualContainer>
  )
}

import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useUser } from '../../../../contexts/UserContext'
import AdmInUserApi from '../../../../services/AdmInUserApi'
import IndividualApi from '../../../../services/IndividualApi'
import {
  Bar,
  Container,
  Header,
  HeaderInfo,
  Item,
  ItemArea,
  ItemDesc,
  ItemValue,
  Profit,
  Title,
  Tooltip
} from './style'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { formatBRL } from '../../../../utils/formatBRL'

interface MonthProps {
  mes: string
}

export const AverageProfit: React.FC = () => {
  const { key, updateGraphics, admInUser, admInUserId } = useUser()

  const [selectMonths, setSelectMonths] = useState(1)
  const [profits, setProfits] = useState<App.Profit>()

  const options = [
    { value: 1, label: '1 mês' },
    { value: 2, label: '2 meses' },
    { value: 3, label: '3 meses' },
    { value: 4, label: '4 meses' },
    { value: 5, label: '5 meses' },
    { value: 6, label: '6 meses' },
    { value: 7, label: '7 meses' },
    { value: 8, label: '8 meses' },
    { value: 9, label: '9 meses' },
    { value: 10, label: '10 meses' },
    { value: 11, label: '11 meses' },
    { value: 12, label: '12 meses' }
  ]

  const customSelectStyles = {
    container: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#e4eeec',
      color: '#768882'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    control: (provided: any) => ({
      ...provided,
      background: '#e4eeec',
      border: 'none',
      borderRadius: '20px',
      width: '130px',
      color: '#768882'
    })
  }

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.lucro(key, selectMonths).then((res) => {
        setProfits(res.data)
      })
    } else {
      AdmInUserApi.lucro(key, selectMonths, admInUserId).then((res) => {
        setProfits(res.data)
      })
    }
  }, [selectMonths, updateGraphics])

  return (
    <Container>
      <Header>
        <HeaderInfo>
          <Title>
            Média de lucro{' '}
            <Tooltip
              data-for="lucro"
              data-tip="Veja os valores de lucros mensais e, com base neles, a média dos últimos meses."
            >
              <AiOutlineQuestionCircle className="icon" size={20} />
            </Tooltip>
            <ReactTooltip
              id="lucro"
              place="bottom"
              backgroundColor="#2D3B36"
              effect="solid"
            />
          </Title>
          {profits && <Profit>{formatBRL(Number(profits.media_lucro))}</Profit>}
        </HeaderInfo>
        <Select
          placeholder="1 mês"
          onChange={(e) => {
            setSelectMonths(e!.value)
          }}
          options={options}
          styles={customSelectStyles}
        />
      </Header>
      <ItemArea>
        {profits?.meses.map((profit, index) => {
          return (
            <Item key={index}>
              <ItemDesc>{profit.mes}</ItemDesc>
              <ItemValue>{formatBRL(Number(profit.lucro))}</ItemValue>
            </Item>
          )
        })}
        {/* <Item>
          <ItemDesc>12/2022</ItemDesc>
          <Bar />
          <ItemValue>R$ 7.112</ItemValue>
        </Item> */}
      </ItemArea>
    </Container>
  )
}

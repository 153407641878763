import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  position: absolute;
  min-width: 200px;
  top: 30px;
  right: 0;
  background: #fff;
  border-radius: 12px;
  padding: 22px;
  display: flex;
  flex-direction: column;
  z-index: 2;
`

export const Item = styled.button`
  display: flex;
  align-items: center;
  gap: 22px;
  padding: 8px;
  border-radius: 4px;
  width: 100%;

  position: relative;

  div {
    background: #eaf2f0;
    width: 33px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  span {
    transition: color 0.2s;
    font-size: 14px;
    position: absolute;
    left: 45px;
    color: #aaa;
  }

  .icon {
    transition: fill 0.2s;
    fill: #aaa;
  }

  &:hover {
    .icon {
      fill: var(--primary);
    }
    span {
      color: var(--primary);
    }
  }
`

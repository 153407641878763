import styled from 'styled-components'

import { motion } from 'framer-motion'

export const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: flex-end;
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OverlayBackdrop = styled(Overlay)`
  background: rgba(132, 142, 142, 0.7);
  z-index: 15;
`

export const OverlayBody = styled(Overlay)`
  overflow-y: auto;
  z-index: 20;
`

export const Box = styled(motion.div)`
  width: 530px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 24px;
  position: relative;
  right: 0;
  z-index: 25;
  overflow-y: auto;
`

export const BoxTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: rgba(153, 153, 153, 0.6);

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const Title = styled.p`
  font-weight: 500;

  span {
    color: var(--primary);
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`

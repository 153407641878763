import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 22px;

  @media (max-width: 460px) {
    width: 90%;
  }
`

export const Left = styled.div``

export const Number = styled.div`
  background: #e6f7f0;
  color: var(--primary);
  width: 54px;
  height: 54px;

  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 6px;
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1100px) {
    align-items: center;
    text-align: center;
  }

  @media (max-width: 460px) {
    width: 200px;
  }
`

export const Thumbnail = styled.div`
  background: url('./assets/thumbnail.png');
  background-position: center;
  background-size: contain;
  width: 391px;
  height: 191px;
  opacity: 0.6;
  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 520px) {
    width: 140%;
  }
`

export const Button = styled.button`
  background: #d6d6d6;
  width: 50px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`

export const Name = styled.p`
  font-weight: bold;
  color: #343333;
  @media (max-width: 460px) {
    width: 100%;
  }
`

export const Description = styled.div`
  width: 350px;
  margin: 12px 0;
  color: rgba(52, 51, 51, 0.7);

  @media (max-width: 460px) {
    width: 100%;
  }
`

import React, { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useUser } from '../../contexts/UserContext'
import { ADMClients } from '../../pages/ADMClients'
import { ADMDashboard } from '../../pages/ADMDashboard'
import { ADMMyData } from '../../pages/ADMMyData'
import { ADMReport } from '../../pages/ADMReport'
import { ADMSupport } from '../../pages/ADMSupport'
import { ADMTutorial } from '../../pages/ADMTutorial'
import { ADMUpgradePlan } from '../../pages/ADMUpgradePlan'
import { RegisterNewUser } from '../../pages/RegisterNewUser'
import { ADMNoClientsSupport } from '../../pages/ADMNoClientsSupport'

export const AdministrativeRoutes: React.FC = () => {
  const { user, hasClient, admInUserId } = useUser()
  const history = useHistory()

  if (user && !hasClient) {
    return (
      <Switch>
        <Route exact path="/support" component={ADMNoClientsSupport} />
        <Route path="/" component={RegisterNewUser} />
      </Switch>
    )
  }

  useEffect(() => {
    if (admInUserId === '') {
      history.push('/adm-dashboard')
    }
  }, [])

  return (
    <Switch>
      <Route exact path="/" component={ADMDashboard} />
      <Route path="/adm-dashboard" component={ADMDashboard} />
      <Route path="/adm-clients" component={ADMClients} />
      <Route path="/adm-report" component={ADMReport} />
      <Route path="/adm-tutorial" component={ADMTutorial} />
      <Route path="/adm-support" component={ADMSupport} />
      <Route path="/adm-my-data" component={ADMMyData} />
      <Route path="/adm-upgrade" component={ADMUpgradePlan} />
    </Switch>
  )
}

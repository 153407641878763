import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import GlobalStyle from './styles/global'
import { UserProvider } from './contexts/UserContext'
import { BrowserRouter } from 'react-router-dom'
import { ModalProvider } from './contexts/ModalContext'

const options = {
  position: positions.TOP_CENTER,
  timeout: 4000,
  offset: '30px',
  transition: transitions.SCALE
}

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...options}>
        <UserProvider>
          <ModalProvider>
            <GlobalStyle />
            <App />
          </ModalProvider>
        </UserProvider>
      </AlertProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
)

import React from 'react'
import { BsSearch } from 'react-icons/bs'
import { IndividualContainer } from '../../components/IndividualContainer'
import { Question } from '../../components/Question'
import { TutorialCard } from '../../components/TutorialCard'
import {
  CommonQuestions,
  CommonQuestionsSide,
  Container,
  Grid,
  Header,
  Info,
  QuestionTitle,
  Search,
  SessionTitle,
  SubTitle,
  Title,
  Wrapper
} from './style'

export const Tutorial: React.FC = () => {
  return (
    <IndividualContainer>
      <Container>
        <Wrapper>
          <Header>
            <div>
              <Title>Tutoriais</Title>
              <SubTitle>
                Confira as trilhas de aprendizado disponível sobre a 7Finance
              </SubTitle>
            </div>
            <Search>
              <input type="text" placeholder="Pesquisar" />
              <BsSearch size={22} fill="var(--primary)" />
            </Search>
          </Header>
          <SessionTitle>
            <span>1 - Pagadores: </span>
            como funciona o 7Finance
          </SessionTitle>
          <Grid style={{ borderBottom: '1px solid rgba(55, 55, 57, 0.1)' }}>
            <TutorialCard />
            <TutorialCard />
            <TutorialCard />
            <TutorialCard />
            <TutorialCard />
            <TutorialCard />
          </Grid>
          <SessionTitle>
            <span>2 - Sua conta: </span>
            entenda os nossos planos
          </SessionTitle>
          <Grid>
            <TutorialCard />
            <TutorialCard />
          </Grid>
        </Wrapper>
        <Title style={{ paddingLeft: '52px' }}>Perguntas frequentes</Title>
        <CommonQuestions>
          <CommonQuestionsSide>
            <QuestionTitle>Título da esquerda</QuestionTitle>
            <Question />
            <Question />
            <Question />
          </CommonQuestionsSide>
          <CommonQuestionsSide>
            <QuestionTitle>Título da direita</QuestionTitle>
            <Question />
            <Question />
            <Question />
          </CommonQuestionsSide>
        </CommonQuestions>
        <Info>
          <p>Você tem alguma outra dúvida que não foi sanada?</p>
          <a href="/support">FALE CONOSCO</a>
        </Info>
      </Container>
    </IndividualContainer>
  )
}

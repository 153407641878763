import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 22px;
  padding-bottom: 12px;

  @media (max-width: 760px) {
    width: 653px;
  }
`

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`

export const Photo = styled.img`
  width: 61px;
  height: 61px;
  border-radius: 100px;
  object-fit: contain;
  padding: 4px;
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.35);
`

export const Info = styled.div``

export const Title = styled.p`
  font-weight: bold;
  color: #343333;
`

export const SubTitle = styled.p`
  color: #343333;
  opacity: 0.7;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const IconArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: #f2f2f2;
  width: 40px;
  height: 40px;
  border-radius: 3px;

  transition: background 0.2s;

  .entry-icon {
    fill: #b5cbc6;
  }

  .out-icon {
    fill: #d7a0a6;
  }

  div:nth-child(1) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  div:nth-child(2) {
    position: absolute;
    bottom: 0;
    right: 10%;
  }
`

interface IconTitleProps {
  color: string
}

export const IconTitle = styled.div<IconTitleProps>`
  color: ${(props) => props.color};
`

export const SubmitButton = styled.button`
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;

  transition: background 0.2s;

  &:hover {
    background: transparent;
  }
`

import {
  Box,
  BoxTop,
  CloseButton,
  Container,
  Form,
  Grid,
  Input,
  InputItem,
  InputTitle,
  InputTitleArea,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  SubmitButtonArea,
  Title,
  Wrapper
} from './style'

import { AxiosError } from 'axios'
import { FormEvent, useState } from 'react'
import { useAlert } from 'react-alert'
import { MdClose } from 'react-icons/md'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'

export const ModalCostCenter: React.FC = () => {
  const { closeCostCenterModal, updatingList } = useModal()
  const { key } = useUser()
  const alert = useAlert()

  const [name, setName] = useState('')
  const [observations, setObservations] = useState('')

  const clean = () => {
    setName('')
    setObservations('')
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const data: App.NewCostCenter = {
      key,
      nome: name,
      desc: observations
    }

    if (name !== '') {
      IndividualApi.new_center(data)
        .then((res) => {
          alert.show(res.data.text)
          clean()
          updatingList()
        })
        .catch((e: AxiosError) => alert.show(e.response))
    } else {
      alert.show('Os campos marcados com * são obrigatórios')
    }
  }

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeCostCenterModal()
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ left: -530 }} animate={{ left: 0 }} exit={{ left: -530 }}>
        <BoxTop>
          <Title>
            Cadastre um <span>novo centro de custo</span>
          </Title>
          <CloseButton onClick={closeCostCenterModal} type="button">
            <MdClose size={26} />
            Fechar
          </CloseButton>
        </BoxTop>
        <Wrapper>
          <Form onSubmit={handleSubmit}>
            <Grid>
              <InputItem
                style={{
                  borderBottomColor: name.length > 0 ? 'var(--primary)' : '#DF3347'
                }}
              >
                <InputTitleArea>
                  <InputTitle>Nome do centro de custo</InputTitle>
                  <span>*</span>
                </InputTitleArea>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Digitar..."
                />
              </InputItem>
              <InputItem>
                <InputTitleArea>
                  <InputTitle>Observações</InputTitle>
                </InputTitleArea>
                <Input
                  onChange={(e) => setObservations(e.target.value)}
                  value={observations}
                  placeholder="Digitar..."
                />
              </InputItem>
            </Grid>
            <SubmitButtonArea>
              <SubmitButton type="submit">Finalizar Cadastro</SubmitButton>
            </SubmitButtonArea>
          </Form>
        </Wrapper>
      </Box>
    </Container>
  )
}

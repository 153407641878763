import React, { useEffect, useState } from 'react'
import { BiCategory } from 'react-icons/bi'
import { BsSearch } from 'react-icons/bs'
import Select from 'react-select'
import { IndividualContainer } from '../../components/IndividualContainer'
import { RangeCalendarPicker } from '../../components/RangeCalendarPicker'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmInUserApi from '../../services/AdmInUserApi'
import IndividualApi from '../../services/IndividualApi'
import {
  Checkbox,
  CheckboxItem,
  ColumnsButton,
  Container,
  Header,
  RightContent,
  Search,
  Table,
  TableArea,
  TableBody,
  TableBodyItem,
  TableBodyRow,
  TableHead,
  TableHeadItem,
  TableHeadRow,
  Title,
  Wrapper,
  WrapperHeader,
  WrapperHeaderFilterArea,
  WrapperHeaderInfo,
  WrapperHeaderInfoSubTitle,
  WrapperHeaderInfoTitle
} from './style'
import { formatBRL } from '../../utils/formatBRL'

interface ColumnProps {
  key: string
  tipo: number
  data_prev: number
  data_pag: number
  data_registro: number
  valor: number
  tipo_pag: number
  resp_pag: number
  resp_nome: number
  projeto: number
  historico: number
  categoria: number
  status: number
  conta: number
}

export const ReleaseBook: React.FC = () => {
  const { key, admInUser, admInUserId, user } = useUser()
  const { openColumnsModal, closeColumnsModal, openEditPaymentModal } = useModal()

  const [status, setStatus] = useState('todos')
  const [initialDate, setInitialDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [entryChecked, setEntryChecked] = useState(true)
  const [outChecked, setOutChecked] = useState(true)
  const [search, setSearch] = useState('')

  const [lancamentos, setLancamentos] = useState<App.Lancamento[]>([])
  const [columns, setColumns] = useState<ColumnProps>()

  const options = [
    { value: 'todos', label: 'Todos' },
    { value: 'pago', label: 'Pago' },
    { value: 'atrasado', label: 'Atrasado' },
    { value: 'emdia', label: 'Em dia' }
  ]

  const customSelectStyles = {
    container: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#e4eeec',
      color: '#768882',
      fontSize: '14px'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#768882',
      fontSize: '14px'
    }),
    control: (provided: any) => ({
      ...provided,
      background: '#e4eeec',
      border: 'none',
      borderRadius: '20px',
      width: '120px',
      color: '#768882'
    })
  }

  useEffect(() => {
    if (initialDate !== '' && endDate !== '') {
      if (!admInUser) {
        IndividualApi.list_lancamentos(key, initialDate, endDate).then((res) =>
          setLancamentos(res.data.lancamentos)
        )
      } else {
        AdmInUserApi.list_lancamentos(admInUserId, key, initialDate, endDate).then(
          (res) => setLancamentos(res.data.lancamentos)
        )
      }
    } else {
      if (!admInUser) {
        IndividualApi.list_lancamentos(key).then((res) =>
          setLancamentos(res.data.lancamentos)
        )
      } else {
        AdmInUserApi.list_lancamentos(admInUserId, key).then((res) =>
          setLancamentos(res.data.lancamentos)
        )
      }
    }
    if (!admInUser) {
      IndividualApi.list_columns(key).then((res) => {
        setColumns(res.data.cols)
      })
    } else {
      AdmInUserApi.list_columns(key, admInUserId).then((res) => {
        setColumns(res.data.cols)
      })
    }
  }, [closeColumnsModal, initialDate, endDate])

  return (
    <IndividualContainer>
      <Container>
        <Header>
          <Title>Lançamentos ({lancamentos.length})</Title>
          <RightContent>
            <ColumnsButton onClick={openColumnsModal}>
              Editar colunas
              <div>
                <BiCategory size={22} fill="var(--primary)" />
              </div>
            </ColumnsButton>
            <Search>
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Pesquisar cliente"
              />
              <BsSearch size={22} fill="var(--primary)" />
            </Search>
          </RightContent>
        </Header>
        <Wrapper>
          <WrapperHeader>
            <WrapperHeaderInfo>
              <WrapperHeaderInfoTitle>
                Listagem de lançamentos do cliente <span>{user?.nome}</span>
              </WrapperHeaderInfoTitle>
              <WrapperHeaderInfoSubTitle>
                Confira os lançamentos desse cliente abaixo
              </WrapperHeaderInfoSubTitle>
            </WrapperHeaderInfo>
            <WrapperHeaderFilterArea>
              <CheckboxItem>
                <Checkbox
                  defaultChecked={true}
                  onChange={(e) => setEntryChecked(e.target.checked)}
                  type="checkbox"
                />
                <p>Entradas</p>
              </CheckboxItem>
              <CheckboxItem>
                <Checkbox
                  defaultChecked={true}
                  onChange={(e) => setOutChecked(e.target.checked)}
                  type="checkbox"
                />
                <p>Saídas</p>
              </CheckboxItem>
              <Select
                placeholder="Status"
                onChange={(e) => {
                  setStatus(e!.value)
                }}
                options={options}
                styles={customSelectStyles}
              />
              <RangeCalendarPicker
                initialDate={setInitialDate}
                endDate={setEndDate}
              />
            </WrapperHeaderFilterArea>
          </WrapperHeader>
          <TableArea>
            <Table>
              <TableHead>
                <TableHeadRow>
                  {columns?.data_prev !== 0 && (
                    <TableHeadItem>Data prevista</TableHeadItem>
                  )}
                  {columns?.data_pag !== 0 && (
                    <TableHeadItem>Data do pagamento</TableHeadItem>
                  )}
                  <TableHeadItem>Entrada / Saída</TableHeadItem>
                  {columns?.tipo !== 0 && <TableHeadItem>Tipo</TableHeadItem>}
                  {columns?.tipo_pag !== 0 && (
                    <TableHeadItem>Forma pagamento</TableHeadItem>
                  )}
                  {columns?.valor !== 0 && <TableHeadItem>Valor</TableHeadItem>}
                  {columns?.status !== 0 && (
                    <TableHeadItem>Pago / Recebido</TableHeadItem>
                  )}
                  {columns?.data_registro !== 0 && (
                    <TableHeadItem>Data registro</TableHeadItem>
                  )}
                  {columns?.resp_pag !== 0 && (
                    <TableHeadItem>Receber de / Pagar a</TableHeadItem>
                  )}
                  {columns?.resp_nome !== 0 && (
                    <TableHeadItem>Resp Nome</TableHeadItem>
                  )}
                  {columns?.projeto !== 0 && (
                    <TableHeadItem>Centro de custo</TableHeadItem>
                  )}
                  {columns?.historico !== 0 && (
                    <TableHeadItem>Histórico</TableHeadItem>
                  )}
                  {columns?.categoria !== 0 && (
                    <TableHeadItem>Categoria</TableHeadItem>
                  )}
                  {columns?.conta !== 0 && <TableHeadItem>Conta</TableHeadItem>}
                </TableHeadRow>
              </TableHead>
              <TableBody>
                {lancamentos
                  // eslint-disable-next-line array-callback-return
                  ?.filter((item) => {
                    // verificando status de saída
                    if (!entryChecked && outChecked) {
                      if (status === 'todos') {
                        if (search === '') {
                          return item.cadastro.toLowerCase() === 'saida'
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return item && item.cadastro.toLowerCase() === 'saida'
                        }
                      } else if (status.toLowerCase() === 'atrasado') {
                        if (search === '') {
                          return (
                            item.cadastro.toLowerCase() === 'saida' &&
                            item.status.toLowerCase() === 'atrasado'
                          )
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return (
                            item &&
                            item.cadastro.toLowerCase() === 'saida' &&
                            item.status.toLowerCase() === 'atrasado'
                          )
                        }
                      } else if (status.toLowerCase() === 'pago') {
                        if (search === '') {
                          return (
                            item.cadastro.toLowerCase() === 'saida' &&
                            item.status.toLowerCase() === 'pago'
                          )
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return (
                            item &&
                            item.cadastro.toLowerCase() === 'saida' &&
                            item.status.toLowerCase() === 'pago'
                          )
                        }
                      } else if (status.toLowerCase() === 'emdia') {
                        if (search === '') {
                          return (
                            item.cadastro.toLowerCase() === 'saida' &&
                            item.status.toLowerCase() === 'em dia'
                          )
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return (
                            item &&
                            item.cadastro.toLowerCase() === 'saida' &&
                            item.status.toLowerCase() === 'em dia'
                          )
                        }
                      }
                      // verificando status de entrada
                    } else if (entryChecked && !outChecked) {
                      if (status.toLowerCase() === 'todos') {
                        if (search === '') {
                          return item.cadastro.toLowerCase() === 'entrada'
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return item && item.cadastro.toLowerCase() === 'entrada'
                        }
                      } else if (status.toLowerCase() === 'atrasado') {
                        if (search === '') {
                          return (
                            item.cadastro.toLowerCase() === 'entrada' &&
                            item.status.toLowerCase() === 'atrasado'
                          )
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return (
                            item &&
                            item.cadastro.toLowerCase() === 'entrada' &&
                            item.status.toLowerCase() === 'atrasado'
                          )
                        }
                      } else if (status.toLowerCase() === 'pago') {
                        if (search === '') {
                          return (
                            item.cadastro.toLowerCase() === 'entrada' &&
                            item.status.toLowerCase() === 'pago'
                          )
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return (
                            item &&
                            item.cadastro.toLowerCase() === 'entrada' &&
                            item.status.toLowerCase() === 'pago'
                          )
                        }
                      } else if (status.toLowerCase() === 'emdia') {
                        if (search === '') {
                          return (
                            item.cadastro.toLowerCase() === 'entrada' &&
                            item.status.toLowerCase() === 'em dia'
                          )
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return (
                            item &&
                            item.cadastro.toLowerCase() === 'entrada' &&
                            item.status.toLowerCase() === 'em dia'
                          )
                        }
                      }
                      // verificando status.toLowerCase() de todos
                    } else if (entryChecked && outChecked) {
                      if (status.toLowerCase() === 'todos') {
                        if (search === '') {
                          return item
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return item
                        }
                      } else if (status.toLowerCase() === 'atrasado') {
                        if (search === '') {
                          return item && item.status.toLowerCase() === 'atrasado'
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return item && item.status.toLowerCase() === 'atrasado'
                        }
                      } else if (status.toLowerCase() === 'pago') {
                        if (search === '') {
                          return item && item.status.toLowerCase() === 'pago'
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return item && item.status.toLowerCase() === 'pago'
                        }
                      } else if (status.toLowerCase() === 'emdia') {
                        if (search === '') {
                          return item && item.status.toLowerCase() === 'em dia'
                        } else if (
                          item.resp_nome.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return item && item.status.toLowerCase() === 'em dia'
                        }
                      }
                    }
                  })
                  .map((lancamento, index) => {
                    return (
                      <TableBodyRow
                        key={index}
                        onClick={() => {
                          if (lancamento.cadastro === 'entrada') {
                            openEditPaymentModal(
                              'Entrada',
                              lancamento.id,
                              lancamento.tipo
                            )
                          }
                          if (lancamento.cadastro === 'saida') {
                            openEditPaymentModal(
                              'Saida',
                              lancamento.id,
                              lancamento.tipo
                            )
                          }
                        }}
                      >
                        <TableBodyItem isVisible={columns?.data_prev !== 0}>
                          {lancamento.data_prev}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.data_pag !== 0}>
                          {lancamento.data_pag}
                        </TableBodyItem>
                        <TableBodyItem
                          className={lancamento.cadastro.toLocaleLowerCase()}
                          isVisible={true}
                        >
                          {`${lancamento.cadastro[0].toUpperCase()}${lancamento.cadastro.substring(
                            1
                          )}`}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.tipo !== 0}>
                          {lancamento.tipo}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.tipo_pag !== 0}>
                          {lancamento.tipo_pag}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.valor !== 0}>
                          {formatBRL(Number(lancamento.valor))}
                        </TableBodyItem>
                        <TableBodyItem
                          className={lancamento.status.toLowerCase()}
                          isVisible={columns?.status !== 0}
                        >
                          {lancamento.status}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.data_registro !== 0}>
                          {lancamento.data_registro}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.resp_pag !== 0}>
                          {lancamento.resp_pag}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.resp_nome !== 0}>
                          {lancamento.resp_nome}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.projeto !== 0}>
                          {lancamento.projeto}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.historico !== 0}>
                          {lancamento.historico}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.categoria !== 0}>
                          {lancamento.categoria}
                        </TableBodyItem>
                        <TableBodyItem isVisible={columns?.conta !== 0}>
                          {lancamento.conta}
                        </TableBodyItem>
                      </TableBodyRow>
                    )
                  })}
                {/* <TableBodyRow>
                  <TableBodyItem>15/04/2022</TableBodyItem>
                  <TableBodyItem className="Entrada">Entrada</TableBodyItem>
                  <TableBodyItem>AS Desenvolvimento de Soluções</TableBodyItem>
                  <TableBodyItem>R$ 224,32</TableBodyItem>
                  <TableBodyItem className="Pago">Pago</TableBodyItem>
                </TableBodyRow> */}
              </TableBody>
              {/* <TableBody>
                <TableBodyRow>
                  <TableBodyItem>15/04/2022</TableBodyItem>
                  <TableBodyItem className="Saída">Saída</TableBodyItem>
                  <TableBodyItem>Lima consultoria LTDA</TableBodyItem>
                  <TableBodyItem>R$ 1022,91</TableBodyItem>
                  <TableBodyItem className="Atrasado">Atrasado</TableBodyItem>
                </TableBodyRow>
              </TableBody> */}
            </Table>
          </TableArea>
        </Wrapper>
      </Container>
    </IndividualContainer>
  )
}

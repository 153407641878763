import { ArcElement, Chart } from 'chart.js'
import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useUser } from '../../../../contexts/UserContext'
import IndividualApi from '../../../../services/IndividualApi'
import Select from 'react-select'
import {
  Ball,
  Container,
  GraphicBoxHeader,
  GraphicBoxSubTitle,
  GraphicBoxTitle,
  GraphicData,
  GraphicLine,
  Tooltip
} from './style'
import AdmInUserApi from '../../../../services/AdmInUserApi'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { formatBRL } from '../../../../utils/formatBRL'

export const EntryOutGraphic: React.FC = () => {
  const { key, updateGraphics, admInUser, admInUserId } = useUser()

  const [days, setDays] = useState(7)
  const [entries, setEntries] = useState(0)
  const [out, setOut] = useState(0)
  const [entryCount, setEntryCount] = useState(0)
  const [outCount, setOutCount] = useState(0)

  Chart.register(ArcElement)

  const options = [
    { value: 7, label: '7 dias' },
    { value: 15, label: '15 dias' },
    { value: 30, label: '30 dias' },
    { value: 60, label: '60 dias' },
    { value: 90, label: '90 dias' },
    { value: 120, label: '120 dias' }
  ]

  const customSelectStyles = {
    container: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#e4eeec',
      color: '#768882'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#768882'
    }),
    control: (provided: any) => ({
      ...provided,
      background: '#e4eeec',
      border: 'none',
      borderRadius: '20px',
      width: '120px',
      color: '#768882'
    })
  }

  const data = {
    datasets: [
      {
        data: [entries, out],
        backgroundColor: ['#00AB6A', '#D7001A'],
        hoverOffset: 4,
        weight: 0.5
      }
    ]
  }

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.dash1(key, days).then((res) => {
        setEntries(res.data.porcentage_entrada)
        setOut(res.data.porcentagem_saida)
        setEntryCount(res.data.entradas)
        setOutCount(res.data.saidas)
      })
    } else {
      AdmInUserApi.dash1(key, days, admInUserId).then((res) => {
        setEntries(res.data.porcentage_entrada)
        setOut(res.data.porcentagem_saida)
        setEntryCount(res.data.entradas)
        setOutCount(res.data.saidas)
      })
    }
  }, [days, updateGraphics])

  return (
    <Container>
      <GraphicBoxHeader>
        <div>
          <GraphicBoxTitle>
            Entradas x Saídas{' '}
            <Tooltip
              data-for="entradasxsaidas"
              data-tip="Comparação entre os lançamentos <br>
confirmados do que entrou e o que <br> saiu, no
período selecionado pelo filtro."
            >
              <AiOutlineQuestionCircle className="icon" size={20} />
            </Tooltip>
            <ReactTooltip
              id="entradasxsaidas"
              place="bottom"
              backgroundColor="#2D3B36"
              effect="solid"
              html={true}
            />
          </GraphicBoxTitle>
        </div>
        <Select
          placeholder="7 dias"
          onChange={(e) => {
            setDays(e!.value)
          }}
          options={options}
          styles={customSelectStyles}
        />
      </GraphicBoxHeader>
      {/* GRÁFICO */}
      <GraphicData>
        <Doughnut className="graphic" data={data} />
      </GraphicData>
      <div>
        <GraphicLine>
          <div>
            <Ball background="#00AB6A" />
            Entradas
          </div>
          <p>{`R$ ${formatBRL(entryCount)} - ${entries} %`}</p>
        </GraphicLine>
        <GraphicLine>
          <div>
            <Ball background="#D7001A" />
            Saídas
          </div>
          <p>{`R$ ${formatBRL(outCount)} - ${out} %`}</p>
        </GraphicLine>
      </div>
    </Container>
  )
}

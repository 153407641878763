import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { BsTrash } from 'react-icons/bs'
import { MdKeyboardArrowRight, MdModeEditOutline } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import AdmApi from '../../services/AdmApi'
import IndividualApi from '../../services/IndividualApi'
import { Container, Item } from './style'
import AdmInUserApi from '../../services/AdmInUserApi'
import DeleteCustomAlert from '../DeleteCustomAlert'

type DropdownCardType =
  | 'client'
  | 'provider'
  | 'product'
  | 'bank'
  | 'category'
  | 'center'
  | 'recurrence'

interface DropdownCardProps {
  hasDetails?: boolean
  type: DropdownCardType
  id?: string
  data?: any
}

export const DropdownCard: React.FC<DropdownCardProps> = ({
  hasDetails,
  id,
  type,
  data
}) => {
  const {
    key,
    plan,
    setAdmInUser,
    setAdmInUserId,
    setAdmInUserImg,
    setAdmInUserImgType,
    setAdmInUserName,
    admInUser,
    admInUserId
  } = useUser()
  const alert = useAlert()
  const history = useHistory()
  const { deleteAlertIsOpen, setDeleteAlertIsOpen } = useModal()

  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  const handleDeleteItem = () => {
    setDeleteAlertIsOpen(true)
  }

  const confirmDelete = () => {
    deleteData()
    setDeleteAlertIsOpen(false)
  }

  const cancelDelete = () => {
    setDeleteAlertIsOpen(false)
  }

  const {
    openEditBankModal,
    openEditCategoryModal,
    openEditClientModal,
    openEditProviderModal,
    openEditCenterModal,
    openEditProductModal,
    openEditRecurrenceModal,
    updatingList,
    setEditData
  } = useModal()

  const deleteData = () => {
    if (type === 'client') {
      if (plan === 'individual') {
        IndividualApi.delete_client(key, id!)
          .then((res) => {
            alert.success(res.data.text)
            updatingList()
          })
          .catch((e) => console.log(e))
      } else {
        if (!admInUser) {
          AdmApi.delete_client(key, id!)
            .then((res) => {
              alert.success(res.data.text)
              updatingList()
            })
            .catch((e) => console.log(e))
        } else {
          AdmInUserApi.delete_client(key, id!, admInUserId)
            .then((res) => {
              alert.success(res.data.text)
              updatingList()
            })
            .catch((e) => console.log(e))
        }
      }
    } else if (type === 'provider') {
      if (!admInUser) {
        IndividualApi.delete_forn(key, id!).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      } else {
        AdmInUserApi.delete_forn(key, id!, admInUserId).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      }
    } else if (type === 'category') {
      if (!admInUser) {
        IndividualApi.delete_category(key, id!).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      } else {
        AdmInUserApi.delete_category(key, id!, admInUserId).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      }
    } else if (type === 'bank') {
      if (!admInUser) {
        IndividualApi.delete_account(key, id!).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      } else {
        AdmInUserApi.delete_account(key, id!, admInUserId).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      }
    } else if (type === 'center') {
      if (!admInUser) {
        IndividualApi.delete_center(key, id!).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      } else {
        AdmInUserApi.delete_center(key, id!, admInUserId).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      }
    } else if (type === 'product') {
      if (!admInUser) {
        IndividualApi.delete_product(key, id!).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      } else {
        AdmInUserApi.delete_product(key, id!, admInUserId).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      }
    } else if (type === 'recurrence') {
      if (!admInUser) {
        IndividualApi.delete_rec(key, id!).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      } else {
        AdmInUserApi.delete_rec(key, id!, admInUserId).then((res) => {
          alert.success(res.data.text)
          updatingList()
        })
      }
    }
  }

  return (
    <Container initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
      {hasDetails && (
        <Item
          onClick={() => {
            setAdmInUser(true)
            setAdmInUserId(id!)
            setAdmInUserImg(data.img)
            setAdmInUserImgType(data.tipo_img)
            setAdmInUserName(data.nome)
            history.push('/adm-in-user-dashboard')
          }}
        >
          <div>
            <MdKeyboardArrowRight className="icon" size={22} />
          </div>
          <span>Ver detalhes</span>
        </Item>
      )}
      <Item
        onClick={() => {
          if (type === 'client') {
            openEditClientModal(Number(id))
            setEditData(data)
          } else if (type === 'provider') {
            openEditProviderModal(Number(id))
            setEditData(data)
          } else if (type === 'product') {
            openEditProductModal(Number(id))
            setEditData(data)
          } else if (type === 'bank') {
            openEditBankModal(Number(id))
            setEditData(data)
          } else if (type === 'category') {
            openEditCategoryModal(Number(id))
            setEditData(data)
          } else if (type === 'center') {
            openEditCenterModal(Number(id))
            setEditData(data)
          } else if (type === 'recurrence') {
            openEditRecurrenceModal(Number(id))
            setEditData(data)
          }
        }}
      >
        <div>
          <MdModeEditOutline className="icon" size={22} />
        </div>
        <span>Editar</span>
      </Item>
      <Item onClick={handleDeleteItem}>
        <div>
          <BsTrash className="icon" size={22} />
        </div>
        <span>Excluir</span>
      </Item>
      {deleteAlertIsOpen && (
        <DeleteCustomAlert
          message="Tem certeza que deseja excluir este item?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
    </Container>
  )
}

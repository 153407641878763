import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 50px;
  height: 50px;

  div:nth-child(1) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  div:nth-child(2) {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
`

import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import InputMask from 'react-input-mask'
import AdmApi from '../../services/AdmApi'
import {
  Box,
  Container,
  Input,
  InputArea,
  InputItem,
  InputTitle,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  Title
} from './style'

export const ModalCreateClient: React.FC = () => {
  const { closeCreateClientModal, updatingList } = useModal()
  const { key } = useUser()
  const alert = useAlert()

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeCreateClientModal()
    }
  }

  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [seg, setSeg] = useState('')
  const [doc, setDoc] = useState('')
  const [razao, setRazao] = useState('')
  const [tel, setTel] = useState('')

  const validEmail = /\S+@\S+\.\S+/

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (nome === '') return alert.show('Preencha o nome')
    if (email === '') return alert.show('Preencha o email')

    if (!validEmail.test(email)) {
      return alert.show('Email inválido')
    }

    AdmApi.create_client({ doc, email, key, nome, razao, tel, seg })
      .then((res) => {
        alert.show(res.data.text)
        updatingList()
        closeCreateClientModal()
      })
      .catch(() => alert.show('Erro ao criar o cliente'))
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
        <Title>Criar cliente</Title>
        <InputArea onSubmit={handleSubmit}>
          <InputItem>
            <InputTitle>Nome*</InputTitle>
            <Input value={nome} onChange={(e) => setNome(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Email*</InputTitle>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Telefone</InputTitle>
            <InputMask
              mask="(99)99999-9999"
              onChange={(e) => setTel(e.target.value)}
              placeholder="(xx)xxxxx-xxxx"
              value={tel}
            />
          </InputItem>
          <InputItem>
            <InputTitle>Segmento</InputTitle>
            <Input value={seg} onChange={(e) => setSeg(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>CPF</InputTitle>
            <InputMask
              mask="999.999.999-99"
              onChange={(e) => setDoc(e.target.value)}
              placeholder="xxx.xxx.xxx-xx"
              value={doc}
            />
          </InputItem>
          <InputItem>
            <InputTitle>Razão social</InputTitle>
            <Input value={razao} onChange={(e) => setRazao(e.target.value)} />
          </InputItem>
          <SubmitButton type="submit">Criar</SubmitButton>
        </InputArea>
      </Box>
    </Container>
  )
}

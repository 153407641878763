import styled from 'styled-components'

interface ContainerProps {
  isActive: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 356px;
  height: 605px;
  background-color: #f6f8fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px;

  border: ${(props) => (props.isActive === true ? '1px solid var(--primary)' : '')};
`

export const Name = styled.p`
  color: #000;
  font-size: 24px;
  font-weight: bold;
`

export const Description = styled.p`
  color: #bdbdc9;
  width: 210px;
  text-align: center;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 22px;
`

export const Price = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 20px;

  color: var(--primary);

  span:first-child {
    color: var(--primary);
    margin-top: 12px;
    font-size: 14px;
  }

  span {
    color: #898b97;
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
  }
`

export const ItemArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  gap: 22px;
  width: 100%;
`

interface ItemProps {
  isActive?: boolean
}

export const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: #333;
    font-weight: 600;
    color: ${(props) => (props.isActive ? '' : '#B9B9C6')};
  }

  div {
    background: ${(props) => (props.isActive ? '#CAEDDA' : '#DDE1E6')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
`

export const SelectButton = styled.button`
  background: var(--primary);
  border-radius: 6px;
  color: #fff;
  padding: 10px 20px;
  margin-top: 52px;
  font-weight: 500;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const ActualPlanText = styled.p`
  font-weight: bold;
  color: var(--primary);
  margin-top: 52px;
`

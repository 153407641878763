import React, { useEffect, useState } from 'react'
import {
  Circle,
  Container,
  GraphicData,
  Item,
  ItemLeft,
  ItemList,
  ItemRight,
  Tooltip
} from './style'

import { ArcElement, Chart } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import IndividualApi from '../../../../services/IndividualApi'
import { useUser } from '../../../../contexts/UserContext'
import AdmInUserApi from '../../../../services/AdmInUserApi'
import ReactTooltip from 'react-tooltip'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { formatBRL } from '../../../../utils/formatBRL'

interface CategoriaProps {
  nome: string
  valor: string
  porcentagem: string
}

export const OutByCategory: React.FC = () => {
  const { key, reloadGraphics, admInUser, admInUserId } = useUser()
  const [saidas, setSaidas] = useState<CategoriaProps[]>([])
  const [porcentagens, setPorcentagens] = useState<String[]>([])
  const [total, setTotal] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  Chart.register(ArcElement)

  const colors = ['#C95151', '#E63D3D', '#C25C24', '#923030', '#901010']

  useEffect(() => {
    if (!admInUser) {
      IndividualApi.categorias(key, '90')
        .then((res) => {
          setSaidas(res.data.saidas)
          setPorcentagens([])
          res.data.saidas.forEach((saida: CategoriaProps) =>
            setPorcentagens((current) => [...current, saida.porcentagem])
          )
          setTotal(res.data.total_saidas)
        })
        .finally(() => setIsLoading(false))
    } else {
      AdmInUserApi.categorias(key, '90', admInUserId)
        .then((res) => {
          setSaidas(res.data.saidas)
          setPorcentagens([])
          res.data.saidas.forEach((saida: CategoriaProps) =>
            setPorcentagens((current) => [...current, saida.porcentagem])
          )
          setTotal(res.data.total_saidas)
        })
        .finally(() => setIsLoading(false))
    }
  }, [reloadGraphics])

  if (isLoading) {
    return <></>
  }

  if (!isLoading) {
    const data = {
      datasets: [
        {
          data: porcentagens,
          backgroundColor: colors,
          hoverOffset: 4,
          weight: 0.5
        }
      ]
    }

    return (
      <Container>
        <p>
          Saídas{' '}
          <Tooltip
            data-for="saidas"
            data-tip="Entenda qual categoria te traz mais gastos"
          >
            <AiOutlineQuestionCircle className="icon" size={20} />
          </Tooltip>
          <ReactTooltip
            id="saidas"
            place="bottom"
            backgroundColor="#2D3B36"
            effect="solid"
          />
        </p>
        <GraphicData>
          <Doughnut className="graphic" data={data} />
        </GraphicData>
        <ItemList>
          {saidas.map((saida, index) => {
            return (
              <Item key={index}>
                <ItemLeft>
                  <Circle color={colors[index]} />
                  <span>{saida.nome}</span>
                </ItemLeft>
                <ItemRight>
                  <p>
                    R$ {formatBRL(Number(saida.valor))} - {saida.porcentagem}%
                  </p>
                </ItemRight>
              </Item>
            )
          })}
        </ItemList>
      </Container>
    )
  }

  return <>error</>
}

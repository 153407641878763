import styled from 'styled-components'

export const Container = styled.div`
  height: 520px;
  flex: 1;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(213, 213, 213, 0.35);
  padding: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;

  p {
    display: flex;
    align-items: center;
    gap: 2px;
  }
`

export const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  .icon {
    color: #c4d3ce;
  }

  &:hover {
    .icon {
      color: #48bdd7;
    }
  }
`

export const GraphicData = styled.div`
  padding: 12px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .graphic {
    height: 280px !important;
    width: 300px !important;
  }
`

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-top: 12px;
  height: 200px;
  overflow-y: auto;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ItemLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

interface CircleProps {
  color: string
}

export const Circle = styled.div<CircleProps>`
  background: ${(props) => props.color};
  width: 14px;
  height: 14px;
  border-radius: 50%;
`

export const ItemRight = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: bold;
`

import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useModal } from '../../contexts/ModalContext'
import { useUser } from '../../contexts/UserContext'
import IndividualApi from '../../services/IndividualApi'
import {
  Box,
  Container,
  Input,
  InputArea,
  InputItem,
  InputTitle,
  OverlayBackdrop,
  OverlayBody,
  SubmitButton,
  Title
} from './style'
import AdmInUserApi from '../../services/AdmInUserApi'

export const ModalEditCategory: React.FC = () => {
  const alert = useAlert()
  const { closeEditCategoryModal, editId, editData, updatingList } = useModal()
  const { key, admInUser, admInUserId } = useUser()

  const [nome, setNome] = useState(editData.nome)
  const [desc, setDesc] = useState(editData.desc)

  function closeModalClickingInOverlay(e: any) {
    if (e.currentTarget === e.target) {
      closeEditCategoryModal()
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (!admInUser) {
      IndividualApi.update_category(key, String(editId), nome, desc).then((res) => {
        alert.show(res.data.text)
        updatingList()
        closeEditCategoryModal()
      })
    } else {
      AdmInUserApi.update_category(
        key,
        String(editId),
        nome,
        desc,
        admInUserId
      ).then((res) => {
        alert.show(res.data.text)
        updatingList()
        closeEditCategoryModal()
      })
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OverlayBackdrop />
      <OverlayBody onClick={closeModalClickingInOverlay} />
      <Box initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
        <Title>Editar</Title>
        <InputArea onSubmit={handleSubmit}>
          <InputItem>
            <InputTitle>Nome</InputTitle>
            <Input value={nome} onChange={(e) => setNome(e.target.value)} />
          </InputItem>
          <InputItem>
            <InputTitle>Descrição</InputTitle>
            <Input value={desc} onChange={(e) => setDesc(e.target.value)} />
          </InputItem>
          <SubmitButton type="submit">Editar</SubmitButton>
        </InputArea>
      </Box>
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
`

export const Banner = styled.div`
  width: 50%;
  height: 100%;
  background-image: url('/assets/banner.png');
  background-size: cover;
  clip-path: ellipse(68% 78% at 32% 51%);

  @media (max-width: 1160px) {
    display: none;
  }
`

export const InputTitle = styled.p`
  color: #333;
  font-size: 15px;
`

export const Content = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px;

  @media (max-width: 1360px) {
    padding: 42px 0px;
  }

  @media (max-width: 1160px) {
    width: 100%;
    padding: 42px 0px;
  }
`

export const Logo = styled.img`
  width: 186px;
  height: 63px;
  margin-bottom: 62px;
`

export const Title = styled.p`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 38px;
  color: #10232e;
`

export const Form = styled.form`
  width: 80%;

  @media (max-width: 620px) {
    padding-bottom: 42px;
  }
`

interface ItemProps {
  isValid: boolean
  isOptional?: boolean
}

export const Item = styled.div<ItemProps>`
  border-bottom: 1px solid
    ${(props) =>
      props.isValid ? 'var(--primary)' : props.isOptional ? '#aaa' : 'var(--error)'};
  height: 74px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;

  input {
    font-size: 17px;
    padding-bottom: 6px;
    width: 90%;
    color: #000;

    &::placeholder {
      color: #aaaaaa;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
`

export const IconArea = styled.div`
  border-right: 1px dotted #7d91a4;
  padding-right: 6px;
  width: 30px;
`

export const Input = styled.input`
  font-size: 18px;
  color: #7d91a4;
  width: 100%;
`

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ForgotPasswordButton = styled.button`
  color: #333333;

  transition: color 0.2s;

  &:hover {
    color: var(--primary);
  }
`

export const SubmitButton = styled.button`
  background: var(--primary);
  color: #fff;
  padding: 12px 48px;
  border-radius: 26px;
  font-weight: bold;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`

export const LinkButton = styled.div`
  margin-top: 52px;

  a {
    color: #333333;
    text-decoration: none;
  }

  span {
    font-weight: bold;
  }

  @media (max-width: 805px) {
    text-align: center;
  }

  @media (max-width: 400px) {
    padding: 0 12px;
  }
`

export const Line = styled.div`
  height: 2px;
  width: 80%;
  padding: 0 100px;
  background: rgba(30, 30, 30, 0.1);
  margin-top: 32px;
`

export const PasswordButton = styled.button``

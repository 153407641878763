import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f6f8fa;

  padding-top: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 52px;
`

export const Title = styled.p`
  color: #343333;
  font-size: 20px;
  font-weight: bold;
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`

export const CheckboxItem = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    color: #555555;
    font-size: 14px;
  }
`

export const Checkbox = styled.input`
  background: var(--primary);
  width: 24px;
  height: 24px;
`

export const Search = styled.div`
  background-color: #fff;
  border-radius: 4px;

  display: flex;
  align-items: center;
  height: 40px;
  width: 360px;

  input {
    padding: 0 12px;
    color: #333;
    width: 90%;
    height: 100%;

    &::placeholder {
      color: #555;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 52px;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d7d7d7;
  padding: 22px;
  background: #fff;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`

export const TopInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const TopTitle = styled.p`
  color: #333333;
  font-size: 18px;
  font-weight: 600;

  span {
    color: var(--primary);
  }
`

export const TopSubTitle = styled.p`
  font-weight: 500;
  color: #7f7f7f;
`

export const DisplayMoreColumnsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 166px;
  height: 32px;

  p {
    color: #aaaaaa;
    font-size: 12px;
  }

  div {
    background: rgba(0, 171, 106, 0.1);
    width: 32px;
    height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
  }
`

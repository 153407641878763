/* eslint-disable indent */
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useModal } from '../../contexts/ModalContext'
import { DropdownCard } from '../DropdownCard'
import { ModalEditBank } from '../ModalEditBank'
import { ModalEditCategory } from '../ModalEditCategory'
import { ModalEditCenter } from '../ModalEditCenter'
import { ModalEditClient } from '../ModalEditClient'
import { ModalEditProduct } from '../ModalEditProduct'
import { ModalEditProvider } from '../ModalEditProvider'
import { ModalEditRecurrence } from '../ModalEditRecurrence'
import {
  ButtonArea,
  Container,
  Content,
  Header,
  Info,
  Name,
  OptionButton,
  Wrapper
} from './style'

interface ListCardProps {
  type:
    | 'client'
    | 'provider'
    | 'product'
    | 'bank'
    | 'category'
    | 'center'
    | 'recurrence'
  id: string
  personData?: {
    email: string
    tel: string
    nome: string
    desc: string
  }
  bankData?: {
    apelido: string
    banco: string
    ag: string
    conta: string
    tipo: string
    desc: string
  }
  otherData?: {
    nome: string
    descricao: string
  }
  productData?: {
    nome: string
    preco: string
    sku: string
    obs: string
  }
  recurrenceData?: {
    cadastro: string
    tipo: string
    dia_prev: string
    data_registro: string
    valor: string
    tipo_pag: string
    resp_pag: string
    resp_nome: string
    projeto: string
    categoria: string
    historico: string
    conta: string
  }
}

export const ListCard: React.FC<ListCardProps> = ({
  type,
  id,
  personData,
  bankData,
  otherData,
  productData,
  recurrenceData
}) => {
  const {
    editBankModalIsOpen,
    editCategoryModalIsOpen,
    editCenterModalIsOpen,
    editClientModalIsOpen,
    editProviderModalIsOpen,
    editProductModalIsOpen,
    editRecurrenceModalIsOpen
  } = useModal()

  const [cardDropdownIsOpen, setCardDropdownIsOpen] = useState(false)

  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const cardDropdownRef = useRef<HTMLDivElement>(null)

  const toggleCardDropdown = () => {
    setCardDropdownIsOpen(!cardDropdownIsOpen)
  }
  const closeCardDropdown = () => {
    setCardDropdownIsOpen(false)
  }
  useEffect(() => {
    const checkIfClickedOutsideOne = (e: any) => {
      if (
        cardDropdownIsOpen &&
        cardDropdownRef.current &&
        !cardDropdownRef!.current!.contains(e.target)
      ) {
        closeCardDropdown()
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutsideOne)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutsideOne)
    }
  }, [cardDropdownIsOpen])

  return (
    <Container>
      <AnimatePresence>
        {editCategoryModalIsOpen && <ModalEditCategory />}
      </AnimatePresence>
      <AnimatePresence>
        {editCenterModalIsOpen && <ModalEditCenter />}
      </AnimatePresence>
      <AnimatePresence>
        {editClientModalIsOpen && <ModalEditClient />}
      </AnimatePresence>
      <AnimatePresence>
        {editProviderModalIsOpen && <ModalEditProvider />}
      </AnimatePresence>
      <AnimatePresence>
        {editProductModalIsOpen && <ModalEditProduct />}
      </AnimatePresence>
      <AnimatePresence>
        {editRecurrenceModalIsOpen && <ModalEditRecurrence />}
      </AnimatePresence>
      <AnimatePresence>{editBankModalIsOpen && <ModalEditBank />}</AnimatePresence>
      <Header>
        <Info>
          <Name>{type === 'client' && personData?.nome}</Name>
          <Name>{type === 'provider' && personData?.nome}</Name>
          <Name>{type === 'bank' && bankData?.apelido}</Name>
          <Name>{type === 'category' && otherData?.nome}</Name>
          <Name>{type === 'center' && otherData?.nome}</Name>
          <Name>{type === 'product' && productData?.nome}</Name>
          <Name>{type === 'recurrence' && recurrenceData?.resp_nome}</Name>
        </Info>
        <ButtonArea ref={cardDropdownRef}>
          <OptionButton onClick={toggleCardDropdown}>
            <BsThreeDotsVertical size={26} />
          </OptionButton>
          <AnimatePresence key="card-dropdown">
            {type === 'client' && cardDropdownIsOpen && (
              <DropdownCard id={id} type={type} data={personData} />
            )}
            {type === 'provider' && cardDropdownIsOpen && (
              <DropdownCard id={id} type={type} data={personData} />
            )}
            {type === 'bank' && cardDropdownIsOpen && (
              <DropdownCard id={id} type={type} data={bankData} />
            )}
            {type === 'category' && cardDropdownIsOpen && (
              <DropdownCard id={id} type={type} data={otherData} />
            )}
            {type === 'center' && cardDropdownIsOpen && (
              <DropdownCard id={id} type={type} data={otherData} />
            )}
            {type === 'product' && cardDropdownIsOpen && (
              <DropdownCard id={id} type={type} data={productData} />
            )}
            {type === 'recurrence' && cardDropdownIsOpen && (
              <DropdownCard id={id} type={type} data={recurrenceData} />
            )}
          </AnimatePresence>
        </ButtonArea>
      </Header>
      <Wrapper>
        {type === 'client' && (
          <>
            <Content>
              <strong>E-mail: </strong>
              {personData?.email}
            </Content>
            <Content>
              <strong>Telefone: </strong>
              {personData?.tel}
            </Content>
            <Content>
              <strong>Descrição: </strong>
              {personData?.desc}
            </Content>
          </>
        )}
        {type === 'provider' && (
          <>
            <Content>
              <strong>E-mail: </strong>
              {personData?.email}
            </Content>
            <Content>
              <strong>Telefone: </strong>
              {personData?.tel}
            </Content>
            <Content>
              <strong>Descrição: </strong>
              {personData?.desc}
            </Content>
          </>
        )}
        {type === 'bank' && (
          <>
            <Content>
              <strong>Banco: </strong>
              {bankData?.banco}
            </Content>
            <Content>
              <strong>Agência: </strong>
              {bankData?.ag}
            </Content>
            <Content>
              <strong>Conta: </strong>
              {bankData?.conta}
            </Content>
            <Content>
              <strong>Tipo: </strong>
              {bankData?.tipo}
            </Content>
            <Content>
              <strong>Descrição: </strong>
              {bankData?.desc}
            </Content>
          </>
        )}
        {type === 'category' && (
          <>
            <Content>
              <strong>Descrição: </strong>
              {otherData?.descricao}
            </Content>
          </>
        )}
        {type === 'center' && (
          <>
            <Content>
              <strong>Descrição: </strong>
              {otherData?.descricao}
            </Content>
          </>
        )}
        {type === 'product' && (
          <>
            <Content>
              <strong>Preço: </strong>
              {productData?.preco}
            </Content>
            {/* <Content>
              <strong>Sku: </strong>
              {productData?.sku}
            </Content> */}
            <Content>
              <strong>Observação: </strong>
              {productData?.obs}
            </Content>
          </>
        )}
        {type === 'recurrence' && (
          <>
            <Content>
              <strong>Cadastro: </strong>
              {recurrenceData?.cadastro}
            </Content>
            <Content>
              <strong>Tipo: </strong>
              {recurrenceData?.tipo}
            </Content>
            <Content>
              <strong>Dia previsto: </strong>
              {recurrenceData?.dia_prev}
            </Content>
            <Content>
              <strong>Data de registro: </strong>
              {recurrenceData?.data_registro}
            </Content>
            <Content>
              <strong>Valor: </strong>
              {recurrenceData?.valor}
            </Content>
            <Content>
              <strong>Tipo de pagamento: </strong>
              {recurrenceData?.tipo_pag}
            </Content>
            <Content>
              <strong>Responsável pelo pagamento: </strong>
              {recurrenceData?.resp_pag}
            </Content>
            <Content>
              <strong>Projeto: </strong>
              {recurrenceData?.projeto}
            </Content>
            <Content>
              <strong>Categoria: </strong>
              {recurrenceData?.categoria}
            </Content>
            <Content>
              <strong>Histórico: </strong>
              {recurrenceData?.historico}
            </Content>
            <Content>
              <strong>Conta bancária: </strong>
              {recurrenceData?.conta}
            </Content>
          </>
        )}
      </Wrapper>
    </Container>
  )
}
